import { Link } from "react-router-dom";
import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import './HomeAbout.css';

export default function HomeAbout() {
    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);

    return (
        <section id="about" className="about-us-area pt-155 pb-30 pt-md-60 pb-md-30 pt-xs-60 pb-xs-30">
            <div className="container">
                <div className="row">
                    {/* Stats Section */}
                    <div className="col-lg-5">
                        <div className="stats-wrapper mb-30">
                            <div className="stats-grid">
                                <div className="stat-item">
                                    <h3 className="stat-number">10M+</h3>
                                    <p className="stat-label">Messages Sent</p>
                                </div>
                                <div className="stat-item">
                                    <h3 className="stat-number">1K+</h3>
                                    <p className="stat-label">Users Registered</p>
                                </div>
                                <div className="stat-item">
                                    <h3 className="stat-number">1.5k+</h3>
                                    <p className="stat-label">Devices Connected</p>
                                </div>
                                <div className="stat-item">
                                    <h3 className="stat-number">$3M+</h3>
                                    <p className="stat-label">Revenue Generated for Customers</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Benefits Section */}
                    <div className="col-lg-7">
                        <div className="about-text-wrapper mb-30">
                            <h2 className="sect-title">Why Migrate To TextLink?</h2>
                            
                            {/* Benefit Cards */}
                            <div className="benefit-card">
                                <div className="benefit-icon">
                                    <i className="fas fa-check-circle"></i> {/* Example icon, replace with your preferred icon library */}
                                </div>
                                <div className="benefit-content">
                                    <h3>Simplicity and Price</h3>
                                    <p className="paragraph-title" style={{ color: 'black' }}>
                                        We offer reliability, lower rates per message, and a setup process that takes less than an hour. Whether you're sending SMS to one person or thousands, our tools meet industry standards.
                                    </p>
                                </div>
                            </div>

                            <div className="benefit-card">
                                <div className="benefit-icon">
                                    <i className="fas fa-headset"></i> {/* Example icon */}
                                </div>
                                <div className="benefit-content">
                                    <h3>Setup Assistance</h3>
                                    <p className="paragraph-title" style={{ color: 'black' }}>
                                        During this promotional period, we provide free setup assistance. Our team will work with your engineers or handle everything for you to ensure a seamless transition.
                                    </p>
                                </div>
                            </div>

                            <div className="benefit-card">
                                <div className="benefit-icon">
                                    <i className="fas fa-rocket"></i> {/* Example icon */}
                                </div>
                                <div className="benefit-content">
                                    <h3>Scalability</h3>
                                    <p className="paragraph-title" style={{ color: 'black' }}>
                                        Our platform grows with your business. Just add more devices. From small campaigns to enterprise-level messaging, TextLink scales effortlessly to meet your needs.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}