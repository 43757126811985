import React from 'react';
import { Card, CardBody, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import { FiCalendar, FiClock, FiList, FiMail, FiSmartphone, FiGlobe } from 'react-icons/fi';
import { Icon } from '../../../components/Component';

// Helper: format a date string.
const formatDate = (dateStr) =>
  new Date(dateStr).toLocaleString(undefined, { month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' });

export function CampaignConfig({ campaign, bulkJob, nextMessage, token }) {
  // Campaign start.
  const campaignStart = formatDate(bulkJob.start);

  // Format start/end times.
  const pad = (num) => num.toString().padStart(2, '0');
  const randomizedInterval = bulkJob.randomized_interval || false;
  const startTime = `${bulkJob.start_time_hours}:${pad(bulkJob.start_time_minutes)}`;
  const endTime = `${bulkJob.end_time_hours}:${pad(bulkJob.end_time_minutes)}`;
  const timeRange =
    bulkJob.start_time_hours === bulkJob.end_time_hours &&
      bulkJob.start_time_minutes === bulkJob.end_time_minutes
      ? 'All Day'
      : `${startTime} - ${endTime}`;

  // Calculate messages per day using provided logic.
  const dailySMS = (() => {
    try {
      let [start_time_hours, start_time_minutes] = startTime.split(":").map(e => parseInt(e));
      let [end_time_hours, end_time_minutes] = endTime.split(":").map(e => parseInt(e));
      if (start_time_hours > end_time_hours) throw new Error("Error");
      if (start_time_hours === end_time_hours && start_time_minutes > end_time_minutes) throw new Error("Error");
      let total_seconds = (end_time_hours - start_time_hours) * 3600 + (end_time_minutes - start_time_minutes) * 60;
      if (start_time_hours === end_time_hours && start_time_minutes === end_time_minutes) {
        total_seconds = 86400;
      }
      return Math.ceil(total_seconds / (bulkJob.wait / 1000));
    } catch (e) {
      console.error(e);
      return null;
    }
  })();
  const messagesPerDay = dailySMS !== null ? dailySMS : 'Unlimited';

  // Parse selected weekdays.
  let weekdays = 'Every day';
  try {
    const selectedDays = JSON.parse(bulkJob.selected_days);
    const allDays = Object.keys(selectedDays).length === 7 && Object.values(selectedDays).every(val => val === true);
    if (!allDays) {
      const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      const days = Object.keys(selectedDays)
        .filter(day => selectedDays[day])
        .map(day => dayNames[parseInt(day, 10)]);
      weekdays = days.join(', ');
    }
  } catch (e) {
    weekdays = 'N/A';
  }

  // Interval between messages in seconds.
  const intervalSeconds = bulkJob.wait / 1000;

  // Sim cards info.
  let simCardsInfo = 'N/A';
  if (bulkJob.use_all_devices) {
    simCardsInfo = 'All';
  } else {
    try {
      const simCards = JSON.parse(bulkJob.sim_cards);
      simCardsInfo = simCards.length;
    } catch (e) {
      simCardsInfo = 'N/A';
    }
  }

  const pauseCampaign = async () => {
    try {
      const url = `${global.SERVIP}/outreach/campaigns/pause`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ id: campaign.id })
      });
      if (!response.ok) return false;
      const data = await response.json();
      if (!data.ok) return false;
      window.location.reload();
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  const resumeCampaign = async () => {
    try {
      const url = `${global.SERVIP}/outreach/campaigns/resume`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ id: campaign.id })
      });
      if (!response.ok) return false;
      const data = await response.json();
      if (!data.ok) return false;
      window.location.reload();
      return true;
    } catch (e) {
      return false;
    }
  };

  const deleteCampaign = async () => {
    try {
      const url = `${global.SERVIP}/outreach/campaigns/delete`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ id: campaign.id })
      });
      if (!response.ok) return false;
      const data = await response.json();
      if (!data.ok) return false;
      window.location.assign('/dashboard/bulksms-dashboard');
      return true;
    } catch (e) {
      return false;
    }
  };

  return (
    <Card className="mb-3 shadow-sm h-100">
      <CardBody>
        {/* Two-column layout using inline-block elements */}

        <Row>

          <div style={{ width: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginBottom: 10 }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                marginTop: '10px',
                alignItems: 'flex-start',
                maxWidth: '50%',
                marginLeft: 5
              }}
            >
              {/* Row 1 */}
              <div style={{ display: 'flex', alignItems: 'flex-start', gap: '10px', width: '100%' }}>
                <div style={{ minWidth: '20px' }}>
                  <FiCalendar className="text-muted" size={16} />
                </div>
                <div style={{ flex: 1 }}>
                  <span
                    className="text-muted small"
                    style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                  >
                    Started {campaignStart}
                  </span>
                </div>
              </div>
              {/* Row 2 */}
              <div style={{ display: 'flex', alignItems: 'flex-start', gap: '10px', width: '100%' }}>
                <div style={{ minWidth: '20px' }}>
                  <FiList className="text-muted" size={16} />
                </div>
                <div style={{ flex: 1 }}>
                  <span
                    className="text-muted small"
                    style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                  >
                    {weekdays}
                  </span>
                </div>
              </div>
              {/* Row 3 */}
              <div style={{ display: 'flex', alignItems: 'flex-start', gap: '10px', width: '100%' }}>
                <div style={{ minWidth: '20px' }}>
                  <FiClock className="text-muted" size={16} />
                </div>
                <div style={{ flex: 1 }}>
                  <span
                    className="text-muted small"
                    style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                  >
                    {timeRange} {bulkJob.timezone}
                  </span>
                </div>
              </div>
              {/* Row 4 */}
              <div style={{ display: 'flex', alignItems: 'flex-start', gap: '10px', width: '100%' }}>
                <div style={{ minWidth: '20px' }}>
                  <FiMail className="text-muted" size={16} />
                </div>
                <div style={{ flex: 1 }}>
                  <span
                    className="text-muted small"
                    style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                  >
                    1 SMS / {randomizedInterval ? (Number((intervalSeconds / 120).toFixed(2)) + "-" + Number((intervalSeconds / 40).toFixed(2))) : Number((intervalSeconds / 60).toFixed(2))} min ({messagesPerDay}/day)
                  </span>
                </div>
              </div>
              {/* Row 5 */}
              <div style={{ display: 'flex', alignItems: 'flex-start', gap: '10px', width: '100%' }}>
                <div style={{ minWidth: '20px' }}>
                  <FiSmartphone className="text-muted" size={16} />
                </div>
                <div style={{ flex: 1 }}>
                  <span
                    className="text-muted small"
                    style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                  >
                    {simCardsInfo} SIM cards
                  </span>
                </div>
              </div>
            </div>




            <div></div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around' }}>
              {(bulkJob.paused) ? <button

                className="text-soft btn btn-icon btn-trigger"
                onClick={(ev) => {
                  ev.preventDefault();
                  resumeCampaign();
                  //window.location.assign('/dashboard/bulksms-campaign/' + item.id);
                }}
                style={{ background: "none", border: "none", cursor: "pointer" }}>
                <Icon name="play-fill"></Icon>
                <div>Resume</div>
              </button>
                : <button

                  className="text-soft btn btn-icon btn-trigger"
                  onClick={(ev) => {
                    ev.preventDefault();
                    pauseCampaign();
                    //window.location.assign('/dashboard/bulksms-campaign/' + item.id);
                  }}
                  style={{ background: "none", border: "none", cursor: "pointer" }}>
                  <Icon name="pause-fill"></Icon>
                  <div>Pause</div>
                </button>}
              {<button
                className="text-soft btn btn-icon btn-trigger"
                onClick={(ev) => {
                  ev.preventDefault();
                  Swal.fire({
                    title: "Warning",
                    text: "Are you sure that you want to delete the campaign? It will stop the campaign, and the campaign cannot be created again.",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes, delete it!",
                    cancelButtonText: "Cancel"
                  }).then((result) => {
                    if (result.isConfirmed) {
                      deleteCampaign();
                    }
                  });
                }}
                style={{ background: "none", border: "none", cursor: "pointer" }}>
                <Icon name="trash-fill"></Icon>
                <div>Delete</div>
              </button>}
            </div>
            <div></div>
          </div>
        </Row>



        <div style={{ marginTop: 10 }}>
          <strong>Message Template:</strong>
          <pre
            style={{
              whiteSpace: 'pre-wrap',
              backgroundColor: '#f8f9fa',
              padding: '1rem',
              borderRadius: '4px',
              margin: '0'
            }}
          >
            {campaign.message_text}
          </pre>
        </div>
      </CardBody>
    </Card>
  );
}
