import React, { useContext } from "react";
import { Icon, UserAvatar } from "../../../components/Component";
import SimpleBar from "simplebar-react";
import { Input, Button, Modal } from "reactstrap";
import { ChatItem, ContactItem } from "./ChatPartials";
import { findUpper } from "../../../utils/Utils";
import { chatData } from "./ChatData";
import { ChatContext } from "./ChatContext";
import { Select } from 'antd';
import TabOverlay from "./TabOverlay";
import { useEffect } from "react";
import { useState } from "react";
import Cookies from "js-cookie";
import { calculateChatData, MAX_PAGE_OVERRIDE, PAGE_SIZE } from "./BulkSMSChat";
import SaveGroupModal from "../components/SaveGroupModal";
const { Option } = Select;

function formatString(str) {
  const totalMaxLen = 16;
  const padLen = 6;
  const ellipsis = '...';

  if (str.length > totalMaxLen) {
    const start = str.substring(0, padLen);
    const end = str.substring(str.length - padLen);
    str = start + ellipsis + end;
  }

  return str;
}

export const ChatAsideBody = ({
  selectedSimCard,
  setSelectedSimCard,
  simCards,
  replyFilterText,
  onInputChangeReply,
  filterText,
  allChatData,
  allChatDataContacts,
  pageNumber,
  setPageNumber,
  onInputChange,
  setChatData,
  setSelectedId,
  selectedId,
  chatItemClick,
  filteredChatList,
  apiKeys,
  selectedApiKey,
  canLoadMore
}) => {
  const { initialIndex, fav, favAction, filterState, contactsState, favContactsState, activeTabState, chatStatuses, selectedStatuses, setSelectedStatuses, selectedIndexState, setGroupModalMode, setPhoneNumber } = useContext(ChatContext);
  const [filter, setFilter] = filterState
  const [activeTab, setActiveTab] = activeTabState
  const [contacts, setContacts] = contactsState
  const [favContacts, setFavContacts] = favContactsState
  const [favData] = fav;
  const [tabs, setTabs] = useState([{ name: "All", value: () => { return true } }])
  const defaultChat = filteredChatList.filter((item) => item.group !== true);
  const isSubUser = Cookies.get("subuser") == "true";

  const [saveModalShown, setSaveModalShown] = useState(false);

  const [selectedIndex, setSelectedIndex] = selectedIndexState;

  useEffect(() => {
    const newTabs = [
      { name: "All Chats", value: () => { return true } }
    ]

    setTabs(newTabs)
    if (JSON.stringify(tabs) != JSON.stringify(newTabs)) {
      setActiveTab(newTabs[0]);
    }
    else {
      const activeTabName = activeTab.name;
      for (const tab of newTabs) {
        if (activeTabName === tab.name) {
          activeTab.value = tab.value;
        }
      }
    }
  }, [contacts, favContacts]);

  useEffect(() => {
    if (activeTab)
      if (activeTab.name == 'All Chats') {
        setChatData(calculateChatData(allChatData, allChatDataContacts, false, pageNumber, filterText, contacts, replyFilterText))
      }
      else {
        setChatData(calculateChatData(allChatData, allChatDataContacts, true, 1, filterText, contacts, replyFilterText))
      }
  }, [activeTab, allChatData, allChatDataContacts, pageNumber, filterText, contacts, replyFilterText]);

  const maxPageNumber = Math.ceil(allChatData.length / PAGE_SIZE);
  return (
    <SimpleBar className="nk-chat-aside-body">
      <Modal size={'lg'} isOpen={saveModalShown} toggle={() => { }}>
        <SaveGroupModal close={_ => setSaveModalShown(false)}></SaveGroupModal>
      </Modal>

      <div className="nk-chat-list">
        <div style={{ width: "100%" }}>
          <h6 style={{ marginBottom: 10 }} className="title overline-title-alt">Choose SIM card for sending SMS</h6>

          <Select value={simCards[selectedSimCard] ? selectedSimCard : null} defaultValue={null} placeholder={<span style={{ color: "#8094AE" }}>Select a SIM card</span>} style={{ flexBasis: 'calc(25% - 10px)', flexGrow: 1, width: "100%", textAlign: "center", marginBottom: 20, color: 'black' }} onChange={setSelectedSimCard}
          >
            {Object.keys(simCards).map(id => {
              return <Option style={{ textAlign: 'center' }} value={id}>{simCards[id]}</Option>;
            })}
          </Select>
        </div>

        <h6 style={{ marginBottom: 5 }} className="title overline-title-alt">Choose leads</h6>

        <Select value={selectedIndex} placeholder="Select chat filter" style={{ flexBasis: 'calc(25% - 10px)', flexGrow: 1, width: "100%", textAlign: "center", marginBottom: 8 }} onChange={(e) => {
          setSelectedIndex(e);
        }}
        >

          {chatStatuses.map((chatStatus) => <Option style={{ textAlign: 'center' }} value={chatStatus.text}>{chatStatus.text}</Option>)}
        </Select>

        {selectedIndex.startsWith('Leads that r') &&
          <div className="nk-chat-aside-search" style={{ padding: 0 }}>
            <div className="form-group">
              <div className="form-control-wrap">
                <div className="form-icon form-icon-left">
                  <Icon name="search"></Icon>
                </div>
                <Input
                  type="text"
                  className="form-round"
                  placeholder="Search reply words (e.g. STOP)"
                  onChange={(e) => onInputChangeReply(e)}
                />
              </div>
            </div>
          </div>}

        <Button className="btn-dim" outline color="primary" onClick={() => setSaveModalShown(true)} style={{ marginBottom: 10, marginTop: 5, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", height: '35px', width: '100%' }}>
          <Icon name="user-group-fill" style={{ marginRight: 10 }} />
          <a>Save to group</a>
        </Button>

        {activeTab?.name == 'All Chats' && <div style={{
          display: 'flex', flexDirection: "row", justifyContent: "space-between",
          width: "100%", marginTop: 20, alignItems: 'center', marginBottom: 10
        }}>

          <Button className="btn-dim" outline color="primary" onClick={() => {
            if (pageNumber > 1) {
              setPageNumber(e => e - 1);
            }
          }} style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: 5, paddingTop: 0, paddingBottom: 0 }}>
            <Icon name="chevron-left" />
          </Button>
          <span>Page {pageNumber}/{MAX_PAGE_OVERRIDE.current || maxPageNumber}</span>
          <Button className="btn-dim" outline color="primary" onClick={() => {
            if (pageNumber < maxPageNumber) {
              setPageNumber(e => e + 1);
            }
          }} style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: 5, paddingTop: 0, paddingBottom: 0 }}>
            <Icon name="chevron-right" />
          </Button>
        </div>}
        <ul className="chat-list" style={{ paddingLeft: "0px" }}>
          {defaultChat.length !== 0 ? (
            filteredChatList.map((item, idx) => {
              return (
                item.convo.length > 0 &&
                !item.group &&
                !item.archive && (
                  <ChatItem
                    key={idx}
                    item={item}
                    selectedId={selectedId}
                    setSelectedId={setSelectedId}
                    chatItemClick={chatItemClick}
                    openGroupModal={(contact, mode) => {
                      setGroupModalMode(mode);
                      setPhoneNumber(contact);
                    }}
                  ></ChatItem>
                )
              );
            })
          ) : (
            <p className="m-3">No messages found.</p>
          )}
        </ul>
      </div>
    </SimpleBar>
  );
};

export const ChannelAsideBody = ({
  filteredChatList,
  onInputChange,
  setSelectedId,
  setMobileView,
  selectedId,
  chatItemClick,
}) => {
  const defaultChat = filteredChatList.filter((item) => item.group === true);
  return (
    <SimpleBar className="nk-chat-aside-body">
      <div className="nk-chat-aside-search">
        <div className="form-group">
          <div className="form-control-wrap">
            <div className="form-icon form-icon-left">
              <Icon name="search"></Icon>
            </div>
            <Input
              type="text"
              className="form-round"
              id="default-03"
              placeholder="Search by name or phone"
              onChange={(e) => onInputChange(e)}
            />
          </div>
        </div>
      </div>
      <div className="nk-chat-aside-panel nk-chat-channel">
        <h6 className="title overline-title-alt">All Channels</h6>
        <ul className="channel-list">
          {chatData.map((item, idx) => {
            return (
              item.channel && (
                <li
                  key={idx}
                  onClick={() => {
                    setSelectedId(item.id);
                    if (window.innerWidth < 860) setMobileView(true);
                  }}
                >
                  <a
                    href="#name"
                    onClick={(ev) => ev.preventDefault()}
                    className={selectedId === item.id ? "active" : ""}
                  >
                    # {item.name}
                  </a>
                </li>
              )
            );
          })}
        </ul>
      </div>
      <div className="nk-chat-list">
        <h6 className="title overline-title-alt">Teams / Groups</h6>
        <ul className="chat-list">
          {defaultChat.length !== 0 ? (
            filteredChatList.map((item, idx) => {
              return (
                item.convo.length > 0 &&
                item.group &&
                !item.archive &&
                !item.channel && (
                  <ChatItem
                    key={idx}
                    item={item}
                    selectedId={selectedId}
                    setSelectedId={setSelectedId}
                    chatItemClick={chatItemClick}
                  ></ChatItem>
                )
              );
            })
          ) : (
            <p className="m-3">No group found</p>
          )}
        </ul>
      </div>
    </SimpleBar>
  );
};

export const ContactAsideBody = (onInputChange, filterData, filterText, contactData, setSelectedId) => {
  return (
    <SimpleBar className="nk-chat-aside-body">
      <div className="nk-chat-aside-search">
        <div className="form-group">
          <div className="form-control-wrap">
            <div className="form-icon form-icon-left">
              <Icon name="search"></Icon>
            </div>
            <input
              type="text"
              className="form-round form-control"
              id="default-03"
              placeholder="Search by name or phone"
              onChange={(e) => onInputChange(e)}
            />
          </div>
        </div>
      </div>
      <div className="nk-chat-aside-panel nk-chat-contact">
        {filterData.length === 0 ? (
          filterText ? (
            <div className="ms-5">No user</div>
          ) : (
            contactData.map((item, idx) => {
              return <ContactItem key={idx} item={item}></ContactItem>;
            })
          )
        ) : (
          <ul className="contacts-list">
            {filterData.map((contact, idx) => {
              return (
                <React.Fragment>
                  <li key={idx} setSelectedId={setSelectedId(contact.id)}>
                    <div className="user-card">
                      <a href="#name" onClick={(ev) => ev.preventDefault()}>
                        <UserAvatar
                          text={findUpper(contact.name)}
                          theme={contact.theme}
                          image={contact.image}
                        ></UserAvatar>
                        <div className="user-name">{contact.name}</div>
                      </a>
                      <div className="user-actions">
                        <a href="#start-chat" onClick={(ev) => ev.preventDefault()}>
                          Start Chat
                        </a>
                      </div>
                    </div>
                  </li>
                </React.Fragment>
              );
            })}
          </ul>
        )}
      </div>
    </SimpleBar>
  );
};
