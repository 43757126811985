import { Link } from "react-router-dom";
import Cookies from "js-cookie";

const MainMenu = () => {
    const signedIn = Cookies.get('token') ? Cookies.get('token').length > 5 : false;

    return (
        <nav id="topheader" className="d-none d-lg-block navbar navbar-expand-lg justify-content-start py-0 pl-60 pl-lg-0">
            <ul className="navbar-nav list-style-none">
                {/* Get Started */}
                <li className="nav-item">
                    <Link className="nav-link" to="/how-textlink-works">How It Works</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/ghl">GoHighLevel</Link>
                </li>
                {/* Products Dropdown */}
                <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdown4" role="button"
                        data-bs-toggle="dropdown" aria-expanded="false">Products <i className="fal fa-chevron-down"></i></Link>
                    <ul className="dropdown-menu" style={{ overflow: 'visible' }}>
                        <li>
                            <Link className="dropdown-item" to="/smstoolsoverview">Overview</Link>
                            <Link className="dropdown-item" to="/web-sms-app">Web Texting App</Link>
                        </li>
                        <li className="dropdown-submenu">
                            <a className="dropdown-item dropdown-toggle" to="services"
                                id="navbarDropdow2a" role="button" data-bs-toggle="dropdown"
                                aria-expanded="false">SMS API'S <i className="fal fa-chevron-down"></i></a>
                            <ul className="dropdown-menu dropdown-menu2"
                                aria-labelledby="navbarDropdown2a">
                                <li>
                                    <Link className="dropdown-item" to="/smsapi">Send message API</Link>
                                </li>
                                <li>
                                    <Link className="dropdown-item" to="/verificationapi">SMS Verification API</Link>
                                </li>
                                <li>
                                    <Link className="dropdown-item" to="/bulksmsapi">Bulk SMS API</Link>
                                </li>
                            </ul>
                        </li>
                        <li className="dropdown-submenu">
                            <Link className="dropdown-item" to="/bulksms">No-code/Bulk SMS tools</Link>
                        </li>
                    </ul>
                </li>

              
                <li className="nav-item">
                    <Link className="nav-link" to="/affiliate-program">Affiliate</Link>
                </li>
                {/* Pricing */}
                <li className="nav-item">
                    <Link className="nav-link" to="/pricing">Pricing</Link>
                </li>

               

                {/* Blog */}
                <li className="nav-item">
                    <Link className="nav-link" to="/blogs">Blog</Link>
                </li>

                 {/* Contact */}
                 <li className="nav-item">
                    <Link className="nav-link" to="/contact">Contact</Link>
                </li>
            </ul>

            {/* Styles */}
            <style jsx>{`
                #topheader {
                    background: transparent;
                }

                .navbar-nav {
                    display: flex;
                    gap: 20px;
                }

                .nav-item {
                    position: relative;
                }

                .nav-link {
                    color: #333;
                    font-weight: 500;
                    padding: 10px 15px;
                    transition: all 0.3s ease;
                    font-size: 1rem; /* Base font size */
                    margin: 0px
                }

                .nav-link:hover {
                    color: #007bff;
                    transform: translateY(-2px);
                }

                .dropdown-menu {
                    display: none;
                    position: absolute;
                    top: 100%;
                    left: 0;
                    background: #ffffff;
                    border: 1px solid #e0e0e0;
                    border-radius: 8px;
                    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
                    padding: 10px 0;
                    min-width: 200px;
                    z-index: 1000;
                    transition: opacity 0.3s ease, transform 0.3s ease;
                }

                .dropdown-menu.show {
                    display: block;
                    opacity: 1;
                    transform: translateY(0);
                }

                .dropdown-item {
                    color: #333;
                    padding: 8px 20px;
                    transition: all 0.3s ease;
                    font-size: 0.9rem; /* Slightly smaller font size for dropdown items */
                }

                .dropdown-item:hover {
                    background: #f5f5f5;
                    color: #007bff;
                    transform: translateX(5px);
                }

                .dropdown-submenu .dropdown-menu {
                    top: 0;
                    left: 100%;
                    margin-left: 5px;
                }

                .dropdown-submenu:hover .dropdown-menu {
                    display: block;
                }

                .dropdown-toggle::after {
                    display: none;
                }

                .fa-chevron-down {
                    margin-left: 5px;
                    font-size: 0.8em;
                }

                /* Responsive Font Sizes */
                @media (max-width: 1200px) {
                    .nav-link {
                        font-size: 0.9rem; /* Slightly smaller font size for smaller screens */
                    }

                    .dropdown-item {
                        font-size: 0.85rem;
                    }
                }

                @media (max-width: 992px) {
                    .nav-link {
                        font-size: 0.85rem; /* Even smaller font size for tablets */
                    }

                    .dropdown-item {
                        font-size: 0.8rem;
                    }
                }

                @media (max-width: 768px) {
                    .nav-link {
                        font-size: 0.8rem; /* Smaller font size for mobile */
                    }

                    .dropdown-item {
                        font-size: 0.75rem;
                    }
                }
            `}</style>
        </nav>
    );
};

export default MainMenu;

/*
 <li className="nav-item">
                    <Link className="nav-link" to="/textlink-ghl-integration">GHL</Link>
                </li>
*/