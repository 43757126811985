import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, FormGroup, Input, Label } from "reactstrap";
import { Icon } from "../../../components/Component";
import Cookies from "js-cookie"
import './UserGroup.css'
import { ChatContext } from "../chat/ChatContext";
import { Checkbox } from "antd";
import Swal from "sweetalert2";
const SaveGroupModal = ({ close }) => {
  const [name, setName] = useState('')
  const { chatStatuses, campaignId } = useContext(ChatContext);
  const [selectedIndexes, setSelectedIndexes] = useState({});
  const [filter, setFilter] = useState("");
  const [exclude, setExclude] = useState(false);

  useEffect(() => {
    if (selectedIndexes[2] && (!selectedIndexes[0] || !selectedIndexes[1])) {
      setSelectedIndexes(e => {
        const e2 = structuredClone(e);
        e2[0] = true;
        e2[1] = true;
        return e2;
      })
    }
  }, [selectedIndexes]);

  const token = Cookies.get('token');

  const filteredGroup = chatStatuses.filter((chatStatus, index) => index != chatStatuses.length - 1 && index != 2 && !chatStatus.text.endsWith('(0)'));

  return (
    <React.Fragment>
      <a
        href="#close"
        onClick={(ev) => {
          ev.preventDefault();
          close();
        }}
        className="close"
      >
        <Icon name="cross-sm"></Icon>
      </a>
      <div className="modal-body modal-body-lg">

        <h5 className="title mb-3">Create group from campaign data</h5>
        <p>In this window, you can create a copy of the original campaign group based on the campaign results (e.g. you can create the group for the next campaign, which contains only the users who replied SUBSCRIBE, or you can remove the users who replied STOP)</p>
        <input className="form-control form-control-md" placeholder="Group name" style={{ display: "flex", flexGrow: 1, marginBottom: 10 }} value={name} onChange={(e) => setName(e.target.value)} />
        <p>When you click the save button, a new group will be created. You can choose if you would like to keep the selected options in the group or if you would like to remove them, by using the <Label htmlFor="switcheroo" check><p>toggle below</p></Label> </p>
        <FormGroup switch>
          <Input
            id="switcheroo"
            type="switch"
            role="switch"
            checked={exclude}
            onChange={() => setExclude(!exclude)}
          />
          <Label htmlFor="switcheroo" check>{exclude ? <p>Remove the following contacts from the new group, and keep the others: </p> : <p>Only include the following leads in the new group: </p>}</Label>
        </FormGroup>

        {filteredGroup.map((e, index) => <div>
          <Checkbox id={"cbsg" + index} checked={selectedIndexes[index]} onChange={(e) => {
            setSelectedIndexes(s => {
              const s2 = structuredClone(s);
              if (e.target.checked)
                s2[index] = true;
              else
                delete s2[index];
              return s2;
            })
          }}></Checkbox>
          <Label style={{ marginLeft: 10 }} for={"cbsg" + index}>{exclude?`Remove ${e.text}`:e.text}</Label>
          {selectedIndexes[0] && filteredGroup[0].text.startsWith('Leads that r') && index == 0 && <div>
            {exclude ? <p>Remove the leads whose replies contain the following, and keep the other leads who had replied anything else in the new group: </p> : <p>Only include leads whose replies contain the following: </p>}
            <input className="form-control form-control-md" placeholder={exclude ? "E.g. \"STOP\" - Leave empty to remove all leads who replied" : "E.g. \"SUBSCRIBE\" - Leave empty to include all leads who replied"} style={{ display: "flex", flexGrow: 1, marginBottom: 10 }} value={filter} onChange={e => setFilter(e.target.value)} />
          </div>}
        </div>)}

        <div className="nk-modal-action justify-end" >
          <ul className="btn-toolbar g-4 align-center">
            <li>
              <a
                href="#toggle"
                onClick={(ev) => {
                  ev.preventDefault();
                  close();
                }}
                className="link link-primary"
              >
                Cancel
              </a>
            </li>
            <li>
              <Button onClick={async () => {
                const statuses = [];
                const cs = filteredGroup;

                for (const id in selectedIndexes) {
                  if (selectedIndexes[id]) {
                    const text = cs[id].text;
                    if (text.startsWith('Leads that replied')) {
                      statuses.push('replied')
                    }
                    else if (text.startsWith('Leads that did')) (
                      statuses.push('sent')
                    )
                    else if (text.startsWith('Failed')) {
                      statuses.push('failed')
                    }
                    else if (text.startsWith('Messages sending')) {
                      statuses.push('sending')
                    }
                  }
                }

                if (!exclude && !statuses.length)
                  return Swal.fire("Can't create a new group", "Please select at least one option", "info");
                console.log({ statuses, filter, exclude });
                const url = `${global.SERVIP}/outreach/groups/subgroup`;
                const response = await fetch(url, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                  },
                  body: JSON.stringify({ campaign_id: campaignId, statuses, filter, name, exclude })
                });
                if (!response.ok)
                  return false;
                const data = await response.json();
                if (!data.ok)
                  Swal.fire("Error", data?.message || "Unknown error happened", 'error');
                else
                  Swal.fire("Success", "Successfuly created the new group", "success");

                close()
              }} color="primary">
                Save
              </Button>
            </li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SaveGroupModal;
