import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const AffiliateChart = ({ data }) => {
  const chartData = {
    labels: data.map((item) => item.month),
    datasets: [
      {
        label: "Revenue ($)",
        data: data.map((item) => item.revenue),
        borderColor: "#6576ff",
        backgroundColor: "rgba(101, 118, 255, 0.2)",
      },
      {
        label: "Signups",
        data: data.map((item) => item.signups),
        borderColor: "gray",
        backgroundColor: "rgba(0, 19, 125, 0.2)",
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Monthly Revenue and Signups",
      },
    },
  };

  return (data ? <Line data={chartData} options={options} /> : <h2>Create your affiliate code to start</h2>);
};

export default AffiliateChart;