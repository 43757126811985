import React, { useState, useEffect } from "react";
import { Icon } from "../../../components/Component";
import { Button } from "reactstrap";
import { loginData, transactionData } from "../../../components/table/TableData";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Badge } from "reactstrap";
import './campaign.css'
import Cookies from "js-cookie"
import moment from 'moment-timezone';
import Swal from "sweetalert2";

function get_next_message_time(previous_message_time, wait, timezone, selected_days, start_time_hours, start_time_minutes, end_time_hours, end_time_minutes) {
  // Define the minimum allowed gap.
  const minGap = wait;
  // Our ideal candidate is previous_message_time + wait.
  let candidate = previous_message_time + wait;
  const fullDay = (start_time_hours === end_time_hours && start_time_minutes === end_time_minutes);
  let mCandidate = moment.tz(candidate, timezone);
  let mPrev = moment.tz(previous_message_time, timezone);

  // --- Adjust for allowed days ---
  // If candidate falls on a disallowed day, move to the next allowed day.
  if (!selected_days[mCandidate.day()]) {
    // Jump day‐by‐day until an allowed day is found.
    do {
      mCandidate.add(1, 'day').startOf('day');
    } while (!selected_days[mCandidate.day()]);
    // When allowed window is full day, we may want to “snap” to midnight.
    if (fullDay) {
      // Make sure we are not violating the min gap.
      if (mCandidate.valueOf() - previous_message_time < minGap) {
        mCandidate = moment.tz(previous_message_time + wait, timezone);
      }
    }
  }

  // --- Now adjust for time-of-day restrictions if needed ---
  if (!fullDay) {
    // For a restricted window, define today’s allowed window.
    let windowStart = mCandidate.clone().set({
      hour: start_time_hours,
      minute: start_time_minutes,
      second: 0,
      millisecond: 0
    });
    let windowEnd = mCandidate.clone().set({
      hour: end_time_hours,
      minute: end_time_minutes,
      second: 0,
      millisecond: 0
    });
    // If candidate falls before windowStart, snap forward.
    if (mCandidate.isBefore(windowStart)) {
      mCandidate = windowStart;
    }
    // If candidate is at or after the window end, then go to the next allowed day’s window start.
    if (!mCandidate.isBefore(windowEnd)) {
      do {
        mCandidate.add(1, 'day').set({
          hour: start_time_hours,
          minute: start_time_minutes,
          second: 0,
          millisecond: 0
        });
      } while (!selected_days[mCandidate.day()]);
    }
  } else {
    // When allowed window is full day, we want to send ASAP.
    // But if the candidate day is different from the previous message’s day,
    // we “snap” to that day’s 00:00 provided that waiting until midnight does not violate the wait gap.
    if (mCandidate.format('YYYY-MM-DD') !== mPrev.format('YYYY-MM-DD')) {
      let dayStart = mCandidate.clone().startOf('day');
      if (dayStart.valueOf() >= previous_message_time + wait) {
        mCandidate = dayStart;
      }
    }
  }

  // --- Final check: ensure that the gap is at least minGap.
  if (mCandidate.valueOf() - previous_message_time < minGap) {
    // If not, force candidate to previous_message_time + wait and reapply adjustments.
    mCandidate = moment.tz(previous_message_time + wait, timezone);

    // For disallowed day (or if it falls outside window), adjust again.
    while (!selected_days[mCandidate.day()]) {
      mCandidate.add(1, 'day').startOf('day');
    }
    if (!fullDay) {
      let windowStart = mCandidate.clone().set({
        hour: start_time_hours,
        minute: start_time_minutes,
        second: 0,
        millisecond: 0
      });
      let windowEnd = mCandidate.clone().set({
        hour: end_time_hours,
        minute: end_time_minutes,
        second: 0,
        millisecond: 0
      });
      if (mCandidate.isBefore(windowStart)) {
        mCandidate = windowStart;
      } else if (!mCandidate.isBefore(windowEnd)) {
        do {
          mCandidate.add(1, 'day').set({
            hour: start_time_hours,
            minute: start_time_minutes,
            second: 0,
            millisecond: 0
          });
        } while (!selected_days[mCandidate.day()]);
      }
    } else {
      // For full-day, if we are on a new day, try snapping to 00:00.
      if (mCandidate.format('YYYY-MM-DD') !== mPrev.format('YYYY-MM-DD')) {
        let dayStart = mCandidate.clone().startOf('day');
        if (dayStart.valueOf() >= previous_message_time + wait) {
          mCandidate = dayStart;
        }
      }
    }
  }

  return mCandidate.valueOf();
}

export const CampaignsTable = ({ action, isCompact, data }) => {
  const token = Cookies.get('token');
  const [campaigns, setCampaigns] = useState([])

  const fetchCampaigns = async () => {
    try {
      const url = `${global.SERVIP}/outreach/campaigns/get-all`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Set the authorization header
        }
      });
      if (!response.ok)
        return;
      const data = await response.json();
      if (!data.ok)
        return;

      setCampaigns(data.campaigns.map(e => {
        const now = Date.now();
        // Heuristika, uglavnom tacno
        const next_message_time = get_next_message_time(now, e.bulk_job.wait, e.bulk_job.timezone, JSON.parse(e.bulk_job.selected_days), e.bulk_job.start_time_hours, e.bulk_job.start_time_minutes, e.bulk_job.end_time_hours, e.bulk_job.end_time_minutes);
        const onHold = (next_message_time - now > e.bulk_job.wait * (e.bulk_job.randomized_interval ? 1.5 : 1))/* || Date.now() < new Date(e.bulk_job.start).getTime()*/;
        console.log(e.bulk_job);
        // TODO Check if on hold
        if (e.bulk_job.sent_messages + e.bulk_job.failed_messages + e.bulk_job.sending_messages >= e.bulk_job.total_messages)
          e.status = 'Done';
        else if (e.bulk_job.paused)
          e.status = 'Paused';
        else if (onHold)
          e.status = 'On hold';
        else
          e.status = 'Ongoing (' + Math.floor((e.bulk_job.sent_messages + e.bulk_job.failed_messages + e.bulk_job.sending_messages) / e.bulk_job.total_messages * 100) + '%)';
        return e;
      }));
    }
    catch (e) {
      console.log(e);
    }
  }
  const pauseCampaign = async (id) => {
    try {
      const url = `${global.SERVIP}/outreach/campaigns/pause`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Set the authorization header
        },
        body: JSON.stringify({ id })
      });
      if (!response.ok)
        return false;
      const data = await response.json();
      if (!data.ok)
        return false;

      return true;
    }
    catch (e) {
      console.log(e);
      return false;
    }
  }
  const resumeCampaign = async (id) => {
    try {
      const url = `${global.SERVIP}/outreach/campaigns/resume`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Set the authorization header
        },
        body: JSON.stringify({ id })
      });
      if (!response.ok)
        return false;
      const data = await response.json();
      if (!data.ok)
        return false;

      return true;
    }
    catch (e) {
      return false;
    }
  }

  const deleteCampaign = async (id) => {
    try {
      const url = `${global.SERVIP}/outreach/campaigns/delete`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Set the authorization header
        },
        body: JSON.stringify({ id })
      });
      if (!response.ok)
        return false;
      const data = await response.json();
      if (!data.ok)
        return false;

      return true;
    }
    catch (e) {
      return false;
    }
  }

  useEffect(() => {
    fetchCampaigns();
  }, [])

  const DropdownTrans2 = ({ item }) => {
    return (<button

      className="text-soft btn btn-icon btn-trigger"
      onClick={(ev) => {
        ev.preventDefault();
        window.location.assign('/dashboard/bulksms-campaign/' + item.id);
      }}
      style={{ background: "none", border: "none", cursor: "pointer" }}>
      <Icon name="setting-fill"></Icon></button>
    );
  };

  const DropdownTrans = ({ item }) => {
    return (
      <UncontrolledDropdown>
        <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
          <Icon name="more-h"></Icon>
        </DropdownToggle>
        <DropdownMenu end>
          <ul className="link-list-plain">
            {(item.status == 'Paused') ? <li>
              <DropdownItem
                tag="a"
                href="#dropdownitem"
                onClick={async (ev) => {
                  ev.preventDefault();
                  setCampaigns(old => old.map(e => ((e.id == item.id) ? ({ ...e, status: "Please wait" }) : (e))))
                  if (await resumeCampaign(item.id))
                    setCampaigns(old => old.map(e => ((e.id == item.id) ? ({ ...e, status: "Continued" }) : (e))))
                  else
                    setCampaigns(old => old.map(e => ((e.id == item.id) ? ({ ...e, status: "Error" }) : (e))))
                }}
              >
                Resume
              </DropdownItem>
            </li> : <li>
              <DropdownItem
                tag="a"
                href="#dropdownitem"
                onClick={async (ev) => {
                  ev.preventDefault();
                  setCampaigns(old => old.map(e => ((e.id == item.id) ? ({ ...e, status: "Please wait" }) : (e))))
                  if (await pauseCampaign(item.id))
                    setCampaigns(old => old.map(e => ((e.id == item.id) ? ({ ...e, status: "Paused" }) : (e))))
                  else
                    setCampaigns(old => old.map(e => ((e.id == item.id) ? ({ ...e, status: "Error" }) : (e))))
                }}
              >
                Pause
              </DropdownItem>
            </li>}

            <li>
              <DropdownItem
                tag="a"
                href="#dropdownitem"
                onClick={async (ev) => {
                  ev.preventDefault();
                  Swal.fire({
                    title: "Warning",
                    text: "Are you sure that you want to delete the campaign? It will stop the campaign, and the campaign cannot be created again.",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes, delete it!",
                    cancelButtonText: "Cancel"
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      setCampaigns(old => old.map(e => ((e.id == item.id) ? ({ ...e, status: "Please wait" }) : (e))))
                      if (await deleteCampaign(item.id))
                        setCampaigns(old => old.filter(e => (e.id != item.id)))
                      else
                        setCampaigns(old => old.map(e => ((e.id == item.id) ? ({ ...e, status: "Error" }) : (e))))
                    }
                  });
                }}
              >
                Delete
              </DropdownItem>
            </li>
          </ul>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };
  return (
    <div className="campaign-table-container" style={{ maxHeight: 500, paddingBottom: 150 }}>
      <table className="table table-tranx1">
        <thead>
          <tr>
            <th>Name</th>
            <th>Sent</th>
            <th>Start</th>
            <th>Progress</th>
            <th style={{ textAlign: 'center' }}>Manage</th>
            <th style={{ textAlign: 'center' }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {campaigns.map((item, index) => (
            <tr key={index} b={(() => { console.log() })()}>
              <td>{item.name || "Unnamed"}</td>
              <td>{item.bulk_job.sent_messages}</td>
              <td>{new Date(item.createdAt).toLocaleDateString()}</td>
              <td>
                <Badge color={item.status === "Done" ? "success" : item.status?.startsWith("Ongoing") ? "warning" : item.status === "Please wait" ? "primary" : (item.status == "On hold" ? "secondary" : (item.status == "Continued" ? "warning" : "danger"))}>
                  {item.status}
                </Badge>
              </td>
              <td style={{ textAlign: 'center', }}>
                <DropdownTrans2 item={item}></DropdownTrans2>
              </td>
              <td style={{ textAlign: 'center' }}> <DropdownTrans item={item} /></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div >
  );
};
