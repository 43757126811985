import Head from "../../layout/head/Head"
import Content from "../../layout/content/Content"
import { Row, Col, Button, Alert, FormGroup, Label, Input } from "reactstrap"
import {
  Card, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Modal,
  ModalHeader,
  ModalBody,
  ModalFooter, CardBody, CardSubtitle, CardTitle, CardLink, CardText
} from "reactstrap";
import { useNavigate } from "react-router";
import { Link, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { parsePhoneNumber } from "libphonenumber-js";
import { Icon, UserAvatar } from "../../components/Component";
import { Checkbox } from "antd";
import { CSSTransition, SwitchTransition, TransitionGroup } from 'react-transition-group';
import Swal from "sweetalert2";

const PAGE_SIZE = 20;

const getImage = (phone_number) => {
  let p = null;
  try {
    p = parsePhoneNumber(phone_number)?.country
  }
  catch (_) { }
  if (!p)
    return "https://textlinksms.com/assets/img/logo/logo2.png"

  return `http://purecatamphetamine.github.io/country-flag-icons/1x1/${p}.svg`
}

const getImageISO = (iso) => {
  if (iso) {
    return `http://purecatamphetamine.github.io/country-flag-icons/1x1/${iso}.svg`
  }
  else {
    return "https://textlinksms.com/assets/img/logo/logo2.png"
  }
}

function SubuserManagement() {
  const { subuserId } = useParams();
  const token = Cookies.get('token');
  const navigate = useNavigate();

  const [pageNumber, setPageNumber] = useState(1);
  const [maxPageNumber, setMaxPageNumber] = useState(1);
  const [modal, setModal] = useState(false);
  const [permissions, setPermissions] = useState(null);
  const [phoneNumberInput, setPhoneNumberInput] = useState("");
  const [message, setMessage] = useState(null);
  const [isError, setIsError] = useState(false);
  const [simCards, setSimCards] = useState([]);
  const [recipients, setRecipients] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredRecipients, setFilteredRecipients] = useState([]);
  const [pageRecipients, setPageRecipients] = useState([]);
  const [pendingChanges, setPendingChanges] = useState({});
  const [pendingChangesSim, setPendingChangesSim] = useState({});
  const [transitionDirection, setTransitionDirection] = useState('forwards');
  const [useAllPhoneNumbers, setUseAllPhoneNumbers] = useState(true);
  const [useAllSimCards, setUseAllSimCards] = useState(true);
  const [email, setEmail] = useState("");
  const [limits, setLimits] = useState({
    max_sms_daily: null,
    max_sms_monthly: null,
    max_sms_total: null,
  });
  const [limitsS, setLimitsS] = useState({
    max_sms_daily: null,
    max_sms_monthly: null,
    max_sms_total: null,
  });
  const handleNextPage = () => {
    if (transitionDirection == 'forward')
      setPageNumber(p => p + 1);
    if (transitionDirection == 'backward' || transitionDirection == 'forwards')
      setTransitionDirection('forward');
  };

  const handlePrevPage = () => {
    if (transitionDirection == 'backward')
      setPageNumber(p => p - 1);
    if (transitionDirection == 'forward')
      setTransitionDirection('backward');
  };

  useEffect(() => {
    if (transitionDirection == 'forward')
      setPageNumber(p => p + 1);
    if (transitionDirection == 'backward')
      setPageNumber(p => p - 1);
  }, [transitionDirection])
  useEffect(() => {
    if (subuserId) {
      fetchPermissions();
    }
  }, [subuserId]);

  const saveAll = async () => {
    try {
      const response = await fetch(`${global.SERVIP}/user/update-subuser-permissions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          subuser_id: subuserId, limits,
          pending_changes: pendingChanges,
          pending_changes_sim: pendingChangesSim,
          use_all_devices: useAllSimCards,
          use_all_phone_numbers: useAllPhoneNumbers,
          limits
        })
      });
      const result = await response.json();
      if (!result.ok) {
        throw new Error(result.message || "Could not update the permissions");
      }
      setLimitsS(structuredClone(limits));
      setPendingChanges({});
      setPendingChangesSim({});
      setPermissions(e => ({ ...e, use_all_devices: useAllSimCards, use_all_phone_numbers: useAllPhoneNumbers }));
      Swal.fire("Success", "Permissions successfuly updated!", 'success');
    }
    catch (e) {
      Swal.fire("Error", e?.message || "Could not update the permissions", 'error');
    }
  }


  useEffect(() => {
    const isDirty = Object.keys(limits).some(e => (limitsS[e] != limits[e]))
      || Object.keys(pendingChangesSim).length > 0 || (permissions?.use_all_devices !== useAllSimCards)
      || (Object.keys(pendingChanges).length > 0 || (permissions?.use_all_phone_numbers != useAllPhoneNumbers));

    const handleBeforeUnload = (event) => {
      if (isDirty) {
        event.preventDefault();
        event.returnValue = "";
      }
    };

    if (isDirty) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    } else {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    }

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };

  }, [limits, limitsS, pendingChangesSim, pendingChanges, permissions, useAllPhoneNumbers, useAllSimCards]);
  useEffect(() => {
    if (search || search === '') {
      const newFiltered = recipients.filter(e => (e?.phone_number?.trim().includes(search?.trim()) || e?.contact_name?.toLowerCase().trim().includes(search.toLowerCase().trim())));

      setFilteredRecipients(newFiltered);
      setMaxPageNumber(Math.ceil(newFiltered.length / PAGE_SIZE) || 1);

      if (pageNumber > (Math.ceil(newFiltered.length / PAGE_SIZE) || 1))
        setPageNumber(1);
    }
  }, [search, recipients]);

  useEffect(() => {
    if (pageNumber)
      setPageRecipients(filteredRecipients.slice(PAGE_SIZE * (pageNumber - 1), PAGE_SIZE * pageNumber));
  }, [pageNumber, filteredRecipients]);

  const fetchPermissions = async () => {
    try {
      const response = await fetch(`${global.SERVIP}/user/get-subuser-permissions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ subuser_id: subuserId })
      });
      const result = await response.json();
      if (result.ok) {
        setPermissions(result.permissions);
        setEmail(result.email);

        setLimits({
          max_sms_daily: result.permissions.max_sms_daily || '',
          max_sms_monthly: result.permissions.max_sms_monthly || '',
          max_sms_total: result.permissions.max_sms_total || '',
        });
        setLimitsS({
          max_sms_daily: result.permissions.max_sms_daily || '',
          max_sms_monthly: result.permissions.max_sms_monthly || '',
          max_sms_total: result.permissions.max_sms_total || '',
        });
        setUseAllPhoneNumbers(result.permissions.use_all_phone_numbers);
        setUseAllSimCards(result.permissions.use_all_devices);
        setSimCards(result.permissions.sim_cards);


        const response = await fetch(`${global.SERVIP}/crm/get-recipients`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({})
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const phone_numbers = result.permissions.phone_numbers;
        const phone_numbers_map = {};
        for (const phone_number of phone_numbers) {
          phone_numbers_map[phone_number] = true;
        }
        var { ok, recipients, end } = await response.json();

        if (!ok) {
          console.log("greska")
          return;
        }

        const responseContacts = await fetch(`${global.SERVIP}/user/get-contacts`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: "{}"
        });

        if (!responseContacts.ok) {
          console.log(`HTTP error! Status: ${responseContacts.status}`)
          return;
        }

        var { ok, contacts } = await responseContacts.json();
        if (!ok)
          return;


        let enabled_recipients = recipients.filter(e => phone_numbers_map[e.phone_number]).map(e => ({ ...e, enabled: true }));
        let disabled_recipients = recipients.filter(e => !phone_numbers_map[e.phone_number]).map(e => ({ ...e, enabled: false }));

        const enabled_recipients_map = {};
        for (const enabled_recipient of enabled_recipients) {
          enabled_recipients_map[enabled_recipient.phone_number] = true;
        }

        const enabled_non_recipients = phone_numbers.filter(e => !enabled_recipients_map[e]).map(e => ({ phone_number: e, date: null, text: '', enabled: true, contact_name: null }));

        const contacts_map = {};
        for (const contact of contacts) {
          contacts_map[contact.phone_number] = contact.name;
        }
        enabled_recipients = enabled_recipients.map(e => ({ ...e, contact_name: contacts_map[e.phone_number] || null }))
        disabled_recipients = disabled_recipients.map(e => ({ ...e, contact_name: contacts_map[e.phone_number] || null }))

        const allRec = [...enabled_non_recipients, ...enabled_recipients, ...disabled_recipients];
        setMaxPageNumber(Math.ceil(allRec.length / PAGE_SIZE));

        setRecipients(allRec);
      } else {
        setMessage('Failed to fetch permissions.');
        setIsError(true);
      }
    } catch (error) {
      setMessage('Error fetching permissions.');
      setIsError(true);
    }
  };

  const updateLocalSelected = (e2, e, index) => {
    e2.stopPropagation();
    const sc = structuredClone(recipients);
    for (let i = 0; i < sc.length; i++) {
      if (sc[i].phone_number == e.phone_number) {
        sc[i].enabled = !sc[i].enabled;
        const pc2 = structuredClone(pendingChanges);
        if (pc2[sc[i].phone_number] === true || pc2[sc[i].phone_number] === false) {
          delete pc2[sc[i].phone_number];
        }
        else {
          pc2[sc[i].phone_number] = sc[i].enabled;
        }
        setPendingChanges(pc2);
        break;
      }
    }
    setRecipients(sc);
  }

  const updateLocalSelectedSim = (e2, e, index) => {
    e2.stopPropagation();
    const sc = structuredClone(simCards);
    for (let i = 0; i < sc.length; i++) {
      if (sc[i].sim_card_id == e.sim_card_id) {
        sc[i].enabled = !sc[i].enabled;
        const pc2 = structuredClone(pendingChangesSim);
        if (pc2[sc[i].sim_card_id] === true || pc2[sc[i].sim_card_id] === false) {
          delete pc2[sc[i].sim_card_id];
        }
        else {
          pc2[sc[i].sim_card_id] = sc[i].enabled;
        }
        setPendingChangesSim(pc2);
        break;
      }
    }
    setSimCards(sc);
  }

  const updatePermission = async (url, body) => {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(body)
      });
      const result = await response.json();
      if (result.ok) {
        setMessage('Counter successfully reset.');
        setIsError(false);
        setTimeout(_ => { setMessage('') }, 3000);

      } else {
        setMessage('Failed to update permission.');
        setIsError(true);
      }
    } catch (error) {
      setMessage('Error updating permission.');
      setIsError(true);
    }
  };

  const handleLimitChange = (field, value) => {
    setLimits({ ...limits, [field]: value });
  };

  const toggleUnlimited = (field, updateUrl) => {
    const isUnlimited = limits[field] === '';
    const newLimit = isUnlimited ? 100 : '';

    setLimits({ ...limits, [field]: newLimit });
  };

  const updateLimit = (url, field) => {
    const limitValue = limits[field] === '' ? null : parseInt(limits[field], 10);
    updatePermission(url, { subuser_id: subuserId, limit: limitValue });
  };

  const resetUsage = (url) => {
    updatePermission(url, { subuser_id: subuserId });
  };

  return (
    <>
      <Head title={"Manage Team member"} />

      <Content>
        <h4 style={{ marginBottom: 10 }}>Manage permissions for {email}</h4>

        <Row className="my-3">
          <Col lg="12" className="mb-lg-0" >
            <Card className="shadow-sm h-100">
              <CardBody>
                {message && (
                  <Alert color={isError ? 'danger' : 'success'}>
                    {message}
                  </Alert>
                )}
                {permissions && (
                  <>
                    <h5>SMS Limit management</h5>
                    <p style={{ marginBottom: 2 }}>Set the message limits for the team member here. Counter for daily limit automatically resets every day, monthly every month, and total limit can only be reset manually from this dashboard. </p>
                    {/* Limit Controls */}
                    <div className="row g-3">
                      {[
                        {
                          label: 'Daily',
                          field: 'max_sms_daily',
                          sentField: 'sent_sms_daily',
                          updateUrl: '/user/update-subuser-daily-limit',
                          resetUrl: '/user/reset-subuser-daily-sent',
                        },
                        {
                          label: 'Monthly',
                          field: 'max_sms_monthly',
                          sentField: 'sent_sms_monthly',
                          updateUrl: '/user/update-subuser-monthly-limit',
                          resetUrl: '/user/reset-subuser-monthly-sent',
                        },
                        {
                          label: 'Total',
                          field: 'max_sms_total',
                          sentField: 'sent_sms_total',
                          updateUrl: '/user/update-subuser-total-limit',
                          resetUrl: '/user/reset-subuser-total-sent',
                        },
                      ].map(({ label, field, sentField, updateUrl, resetUrl }) => (
                        <div key={field} className="col-12 col-md-4">
                          <Card className="h-100">
                            <CardBody>
                              <div className="d-flex flex-column justify-content-between h-100">
                                <div>
                                  <h6>{label}</h6>
                                  <div>
                                    <span>
                                      Usage: {permissions[sentField]} / {limits[field] === '' ? 'Unlimited' :
                                        <Input
                                          type="number"
                                          value={limits[field]}
                                          min="1"
                                          className="d-inline w-auto"
                                          style={{ maxWidth: 150 }}
                                          onChange={(e) => handleLimitChange(field, e.target.value)}
                                        />}
                                    </span>
                                  </div>
                                </div>

                                <FormGroup check className="mt-2">
                                  <Label check>
                                    <Input
                                      type="checkbox"
                                      checked={limits[field] !== ''}
                                      onChange={() => toggleUnlimited(field, `${global.SERVIP}${updateUrl}`)}
                                    />
                                    Set Limit
                                  </Label>
                                </FormGroup>

                                <Button
                                  color="primary"
                                  size="sm"
                                  className="mt-2 w-100"
                                  onClick={() => resetUsage(`${global.SERVIP}${resetUrl}`)}
                                >
                                  Reset {label.toLowerCase()} counter
                                </Button>
                              </div>
                            </CardBody>
                          </Card>
                        </div>
                      ))}
                    </div>
                    <TransitionGroup>
                      {Object.keys(limits).some(e => (limitsS[e] != limits[e])) ? (
                        <CSSTransition timeout={600} classNames="fade">
                          <div
                            style={{
                              color: 'red',
                              fontWeight: 'bold',
                              textAlign: 'center',
                              marginTop: 10
                            }}
                          >
                            ⚠️ You have unsaved changes! <Link className="lighttttt" style={{ cursor: 'pointer', color: '#0d6efd' }} onClick={e => {
                              e.preventDefault();
                              e.stopPropagation();
                              saveAll();
                            }}>Save all</Link>
                          </div>
                        </CSSTransition>) : null}
                    </TransitionGroup>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Col>
          {permissions && <Row style={{ display: 'flex', alignItems: 'stretch' }}>
            <Col lg="6" className="mb-4 mb-lg-0" >
              <Card className="h-100">
                <CardBody style={{ minHeight: '50vh', display: 'flex', flexDirection: 'column', justifyContent: 'start' }} className="shadow-sm">
                  <h5>SIM Card Management</h5>
                  <p style={{ marginBottom: 2 }}>Choose which SIM cards the team member can use, or enable team member to use all of your organization's resources. </p>

                  <FormGroup check style={{ marginBottom: 6 }}>
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={useAllSimCards}
                        onChange={(e) => {
                          setUseAllSimCards(e => !e);
                        }}
                      />
                      Allow all SIM cards
                    </Label>
                  </FormGroup>
                  <TransitionGroup component={null}>
                    {!useAllSimCards && <CSSTransition
                      timeout={300}
                      classNames="contacts-toggle"
                      unmountOnExit
                    >
                      <div style={{
                        position: 'relative',
                        height: '50vh',
                        overflow: 'hidden',
                        border: '1px solid #ddd',
                        borderRadius: '5px'
                      }}>
                        <div style={{
                          position: 'absolute',
                          width: '100%',
                          height: '100%',
                          overflowY: 'auto',
                          padding: '10px'
                        }}>
                          <TransitionGroup component="ul" className="list-unstyled mb-0">
                            {simCards.map((e, index) => {
                              return <CSSTransition
                                key={e.sim_card_id}
                                timeout={200}
                                classNames="item"
                              >
                                <div
                                  onClick={e2 => updateLocalSelectedSim(e2, e, index)}
                                  style={{
                                    background: pendingChangesSim[e.sim_card_id] === true ? '#90EE90' :
                                      (pendingChangesSim[e.sim_card_id] === false ? '#FF7F7F' : ''),
                                    marginBottom: 10,
                                    opacity: e.enabled ? '1' : 0.5,
                                    border: '1px solid rgba(0,0,0,0.1)',
                                    padding: 5,
                                    borderRadius: 5,
                                    cursor: 'pointer',
                                    transition: 'all 0.2s ease',
                                    boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
                                  }}
                                  className="contact-card"
                                >
                                  <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    transition: 'transform 0.2s ease'
                                  }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                      <UserAvatar theme={'purple'} text={e.sim_card_id + ""} image={getImageISO(e.source_country)} className="chat-media">
                                      </UserAvatar>


                                      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'start', marginLeft: '1rem', fontSize: '0.9375rem' }}>
                                        <div style={{ fontWeight: 500 }}>{e.name.split('/')[1] || "Unnamed"}</div>
                                        <div style={{ fontWeight: 400, color: '#777777' }}>{e.name.split('/')[0] || "Unknown"}</div>
                                      </div>
                                    </div>
                                    <div><Input onChange={e2 => updateLocalSelectedSim(e2, e, index)} type="checkbox" style={{ width: '18px', height: '18px', marginRight: '1rem' }} checked={e.enabled}></Input></div>
                                  </div>
                                </div>
                              </CSSTransition>
                            }
                            )}
                          </TransitionGroup></div>
                      </div>
                    </CSSTransition>}


                    <TransitionGroup>
                      {Object.keys(pendingChangesSim).length > 0 || (permissions.use_all_devices !== useAllSimCards) ? (
                        <CSSTransition timeout={600} classNames="fade">
                          <div
                            style={{
                              color: 'red',
                              fontWeight: 'bold',
                              textAlign: 'center'
                            }}
                          >
                            ⚠️ You have unsaved changes! <Link className="lighttttt" style={{ cursor: 'pointer', color: '#0d6efd' }} onClick={e => {
                              e.preventDefault();
                              e.stopPropagation();
                              saveAll();
                            }}>Save all</Link>
                          </div>
                        </CSSTransition>) : null}</TransitionGroup>
                  </TransitionGroup>
                </CardBody>
              </Card>
            </Col>
            <Col lg="6" className="mb-4 mb-lg-0">
              <Card className="h-100">
                <CardBody style={{ minHeight: '50vh', display: 'flex', flexDirection: 'column', justifyContent: 'start' }} className="shadow-sm">
                  <h5>Contact Management</h5>
                  <p style={{ marginBottom: 2 }}>Here you can restrict which contacts the team member will have access to, or enable team member to access all contacts. </p>

                  <FormGroup check style={{ marginBottom: 6 }}>
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={useAllPhoneNumbers}
                        onChange={(e) => {
                          setUseAllPhoneNumbers(e => !e);
                        }}
                      />
                      Allow communication with all contacts
                    </Label>
                  </FormGroup>

                  <TransitionGroup component={null}>
                    {!useAllPhoneNumbers && (
                      <CSSTransition
                        timeout={300}
                        classNames="contacts-toggle"
                        unmountOnExit
                      ><div>
                          <Input
                            type="text"
                            className="form-round"
                            id="default-03"
                            placeholder="Search by name or phone number"
                            onChange={(e) => {
                              setSearch(e.target.value);
                            }}
                          />
                          <div style={{
                            display: 'flex', flexDirection: "row", justifyContent: "space-between",
                            width: "100%", marginTop: 20, alignItems: 'center', marginBottom: 10
                          }}>

                            <Button className="btn-dim" outline color="primary" onClick={() => {
                              if (pageNumber > 1) {
                                handlePrevPage();
                              }
                            }} style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: 5, paddingTop: 0, paddingBottom: 0 }}>
                              <Icon name="chevron-left" />
                            </Button>

                            <span>Page {pageNumber}/{maxPageNumber}</span>
                            <Button className="btn-dim" outline color="primary" onClick={() => {
                              if (pageNumber < maxPageNumber) {
                                handleNextPage();
                              }
                            }} style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: 5, paddingTop: 0, paddingBottom: 0 }}>
                              <Icon name="chevron-right" />
                            </Button>
                          </div>
                          <div style={{
                            position: 'relative',
                            height: '50vh',
                            overflow: 'hidden',
                            border: '1px solid #ddd',
                            borderRadius: '5px'
                          }}>
                            <SwitchTransition mode="out-in">
                              <CSSTransition
                                key={pageNumber}
                                timeout={300}
                                classNames={`page-${transitionDirection}`}
                                unmountOnExit
                              >
                                <div style={{
                                  position: 'absolute',
                                  width: '100%',
                                  height: '100%',
                                  overflowY: 'auto',
                                  padding: '10px'
                                }}>
                                  <TransitionGroup component="ul" className="list-unstyled mb-0">
                                    {pageRecipients.map((e, index) => <CSSTransition
                                      key={e.phone_number}
                                      timeout={200}
                                      classNames="item"
                                    >
                                      <div
                                        onClick={e2 => updateLocalSelected(e2, e, index)}
                                        style={{
                                          background: pendingChanges[e.phone_number] === true ? '#90EE90' :
                                            (pendingChanges[e.phone_number] === false ? '#FF7F7F' : ''),
                                          marginBottom: 10,
                                          opacity: e.enabled ? '1' : 0.5,
                                          border: '1px solid rgba(0,0,0,0.1)',
                                          padding: 5,
                                          borderRadius: 5,
                                          cursor: 'pointer',
                                          transition: 'all 0.2s ease',
                                          boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
                                        }}
                                        className="contact-card"
                                      >
                                        <div style={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          justifyContent: 'space-between',
                                          alignItems: 'center',
                                          transition: 'transform 0.2s ease'
                                        }}>
                                          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                            <UserAvatar theme={'purple'} text={e.contact_name || e.phone_number} image={getImage(e.phone_number)} className="chat-media">
                                            </UserAvatar>


                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'start', marginLeft: '1rem', fontSize: '0.9375rem' }}>
                                              <div style={{ fontWeight: 500 }}>{e.phone_number}</div>
                                              <div style={{ fontWeight: 400, color: '#777777' }}>{e.contact_name || "Unknown"}</div>
                                            </div>
                                          </div>
                                          <div><Input onChange={e2 => updateLocalSelected(e2, e, index)} type="checkbox" style={{ width: '18px', height: '18px', marginRight: '1rem' }} checked={e.enabled}></Input></div>
                                        </div>
                                      </div>
                                    </CSSTransition>
                                    )}
                                  </TransitionGroup>
                                </div>
                              </CSSTransition>
                            </SwitchTransition>
                          </div>
                          <div style={{ marginTop: 10 }}>If you would like to add a contact that is not on the list, enter the phone number below</div>
                          <div className="mt-3 d-flex align-items-center gap-2"
                            style={{ marginBottom: 20 }}>
                            <Input
                              type="text"
                              placeholder="Add a new phone number"
                              value={phoneNumberInput}
                              onChange={(e) => setPhoneNumberInput(e.target.value)}
                            />
                            <Button
                              color="primary"
                              onClick={() => {
                                let phone_number = null;
                                try {
                                  const ppn = parsePhoneNumber(phoneNumberInput).format('E.164');
                                  if (!ppn)
                                    return Swal.fire("Error", "Invalid phone number")
                                  phone_number = ppn;
                                }
                                catch (e) {
                                  return Swal.fire("Error", "Invalid phone number")
                                }
                                const recp = recipients.find(ee => ee.phone_number == phone_number);
                                if (recp) {
                                  if (!recp.enabled) {
                                    const pc2 = structuredClone(pendingChanges);
                                    if (pc2[phone_number] === false) {
                                      delete pc2[phone_number];
                                    }
                                    else {
                                      pc2[phone_number] = true;
                                    }
                                    setPendingChanges(pc2);
                                  }
                                  setRecipients(e => e.map(ee => (ee.phone_number == phone_number ? { ...ee, enabled: true } : ee)));
                                }
                                else {
                                  const pc2 = structuredClone(pendingChanges);
                                  pc2[phone_number] = true;
                                  if (pc2[phone_number] === false) {
                                    delete pc2[phone_number];
                                  }
                                  else {
                                    pc2[phone_number] = true;
                                  }
                                  setPendingChanges(pc2);
                                  setRecipients(e => [{ phone_number: phone_number, date: null, text: '', enabled: true, contact_name: null }, ...e]);
                                }
                                setPhoneNumberInput('');
                              }
                              }
                            >
                              Add
                            </Button>
                          </div>
                        </div>
                      </CSSTransition>
                    )}

                    <TransitionGroup>
                      {(Object.keys(pendingChanges).length > 0 || (permissions.use_all_phone_numbers != useAllPhoneNumbers)) ? (
                        <CSSTransition timeout={600} classNames="fade">
                          <div
                            style={{
                              color: 'red',
                              fontWeight: 'bold',
                              textAlign: 'center'
                            }}
                          >
                            ⚠️ You have unsaved changes! <Link className="lighttttt" style={{ cursor: 'pointer', color: '#0d6efd' }} onClick={e => {
                              e.preventDefault();
                              e.stopPropagation();
                              saveAll();
                            }}>Save all</Link>
                          </div>
                        </CSSTransition>) : null}
                    </TransitionGroup>


                  </TransitionGroup>
                </CardBody>
              </Card>

            </Col>
          </Row>}
        </Col >
      </Content >
    </>
  )
}

export default SubuserManagement;