import React from "react";
import "./GHLSMSIntegration.css";

function GHLSMSIntegration() {
  return (
    <div className="ghl-sms-integration-wrapper">
      {/* Back Button */}
      <div className="ghl-back-button">
        <a href="/">
          <ion-icon name="arrow-back-outline" className="ghl-back-icon"></ion-icon>
          Back
        </a>
      </div>

      {/* HERO / TITLE SECTION */}
      <div className="ghl-title-div">
        <h1>
          Supercharge Your GoHighLevel CRM <br />
          with Native SMS
        </h1>
        <p className="ghl-hero-subtitle">
          Get your SMS marketing up and running effortlessly in minutes with{" "}
          <strong>TextLinks + HighLevel</strong>. Experience seamless integration
          and cost-effective messaging like never before.
        </p>
        <button
          className="ghl-btn-primary ghl-btn-large"
          onClick={() => (window.location.href = '/textlink-ghl-integration')}
        >
          Get Started
        </button>
      </div>

      {/* FIRST ROW: Video (left) + Text/Stats (right) */}
      <div className="ghl-content-row">
        <div className="ghl-video-column">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/HYOGQ1l3dzA"
            title="Setup Tutorial"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>

        <div className="ghl-text-column">
          <p className="ghl-text-paragraph">
            Get your SMS marketing up and running effortlessly in 5 minutes with{" "}
            <span className="ghl-highlight">TextLinks + HighLevel</span> and enjoy
            unlimited text messaging capabilities without the hassle of A2P
            registration or per-message charges.
          </p>

          <div className="ghl-content-stats">
            <div className="ghl-stat">
              <ion-icon name="people-outline" className="ghl-icon"></ion-icon>
              <p>120 Active Accounts</p>
            </div>
            <div className="ghl-stat">
              <ion-icon name="chatbubble-outline" className="ghl-icon"></ion-icon>
              <p>120k Messages/Day</p>
            </div>
          </div>
        </div>
      </div>

      {/* SECOND ROW: Text (left) + Video (right) */}
      <div className="ghl-content-row reverse">
        <div className="ghl-text-column">
          <h2 className="ghl-section-title">
            Ditch Twilio, MessageBird &amp; EZText
          </h2>
          <p className="ghl-text-paragraph">
            Cut costs and streamline your text messaging process with{" "}
            <strong>TextLinks</strong>. Say goodbye to expensive third-party
            services. Connect your SIM card directly to HighLevel and start
            sending messages for as low as <strong>$0.00167 per SMS</strong>.
          </p>
          <button
            className="ghl-btn-secondary ghl-btn-large"
            onClick={() => (window.location.href = '/textlink-ghl-integration')}
          >
            Learn More
          </button>
        </div>

        <div className="ghl-video-column">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/yh9zxE1iCQk"
            title="Pricing Comparison"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>

      {/* CTA SECTION */}
      <div className="ghl-cta-section">
        <h3>Compare Pricing &amp; Features</h3>
        <p className="ghl-text-paragraph">
          Explore our flexible plans that fit your needs and maximize your ROI with{" "}
          <strong>TextLink</strong>.
        </p>
        <button
          className="ghl-btn-primary ghl-btn-large"
          onClick={() => window.open('https://youtu.be/yh9zxE1iCQk', '_blank')}
        >
          View Pricing &amp; Feature Comparison
        </button>
      </div>
    </div>
  );
}

export default GHLSMSIntegration;
