import React, { useState, createContext, useEffect } from "react";
import { chatData } from "./ChatData";
import Cookies from "js-cookie";

export const ChatContext = createContext();

export const ChatContextProvider = (props) => {
  const [chat, setChat] = useState(chatData);
  const [favData, setFavData] = useState(chatData);
  const [contacts, setContacts] = useState([])
  const [favContacts, setFavContacts] = useState([])
  const [filter, setFilter] = useState(() => true)
  const [activeTab, setActiveTab] = useState(null);
  const [groupModalMode, setGroupModalMode] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [groups, setGroups] = useState([]);
  const token = Cookies.get('token');

  useEffect(() => {
    if (groupModalMode === null || groups.length)
      return;
    const fetchGroups = async () => {
      try {
        const url = `${global.SERVIP}/outreach/groups/get-all`;
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Set the authorization header
          }
        });
        if (!response.ok)
          return;
        const data = await response.json();
        if (!data.ok)
          return;
        setGroups(data.groups);
        console.log(data.groups);
      }
      catch (e) {
        console.log(e);
      }
    }
    fetchGroups()
  }, [groupModalMode]);

  const [contactForAdding, setContactForAdding] = useState(null) // null or phone_number
  const [contactForRenaming, setContactForRenaming] = useState(null) // null or id

  // Function for deleting a conversation
  const deleteConvo = (id) => {
    let data = chat;
    data = data.filter((item) => item.id !== id);
    setChat([...data]);
  };

  // Universal function for any props
  const propAction = (id, prop) => {
    let data = chat;
    const index = data.findIndex((item) => item.id === id);
    data[index][prop] = true;
    setChat([...data]);
  };

  // Function to add favourite to favourite list
  const favAction = (id) => {
    let data = favData;
    const index = data.findIndex((item) => item.id === id);
    data[index].fav = true;
    setFavData([...data]);
    setChat([...data]);
  };

  // Function to change nickame
  const changeNickname = (id, name) => {
    const data = chat;
    const index = data.findIndex((item) => item.id === id);
    data[index].nickname = name;
    setChat([...data]);
  };

  // Function to change theme
  const changeTheme = (id, name) => {
    const data = chat;
    const index = data.findIndex((item) => item.id === id);
    data[index].chatTheme = name;
    setChat([...data]);
  };

  // Removes user from group
  const deleteUser = (id, userId) => {
    let data = chat;
    const grouped = data.findIndex((item) => item.id === id);
    const group = data.find((item) => item.id === id);
    const user = group.user.filter((user) => user.id !== userId);
    group.user = user;
    data[grouped] = group;
    setChat([...data]);
  };

  // Makes a user admin
  const makeAdmin = (id, userId) => {
    let data = chat;
    const grouped = data.findIndex((item) => item.id === id);
    const group = data.find((item) => item.id === id);
    group.user.forEach((element) => {
      element.role = "User";
    });
    const userIndex = group.user.findIndex((item) => item.id === userId);
    group.user[userIndex].role = "Admin";
    data[grouped] = group;
    setChat([...data]);
  };


  return (
    <ChatContext.Provider
      value={{
        groupModalMode,
        setGroupModalMode,
        phoneNumber,
        setPhoneNumber,
        groups,
        activeTabState: [activeTab, setActiveTab],
        contactForAddingState: [contactForAdding, setContactForAdding],
        contactForRenamingState: [contactForRenaming, setContactForRenaming],
        contactsState: [contacts, setContacts],
        favContactsState: [favContacts, setFavContacts],
        filterState: [filter, setFilter],
        chatState: [chat, setChat],
        chatData: chat,
        fav: [favData],
        deleteConvo: deleteConvo,
        propAction: propAction,
        favAction: favAction,
        changeNickname: changeNickname,
        changeTheme: changeTheme,
        deleteUser: deleteUser,
        makeAdmin: makeAdmin,
      }}
    >
      {props.children}
    </ChatContext.Provider>
  );
};
