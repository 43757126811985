import { Link } from "react-router-dom";
import MetisMenu from '@metismenu/react';
import Cookies from "js-cookie";

export default function SideBar() {
  const signedIn = Cookies.get('token') ? (Cookies.get('token').length > 5 && true) : false;
  const signedInButtonStyle = {
    backgroundColor: "#eff7ff",
    borderRadius: "45px",
    color: "#086ad8",
    display: 'flex',
    justifyContent: "center",
    alignItems: 'center',
    marginTop: 10
  };

  const SideBarRemove = () => {
    document.body.classList.remove('on-side');
  };

  // Function to handle menu item clicks
  const handleMenuItemClick = (e) => {
	const isDropdownToggle = e.target.closest('.has-dropdown > a');
	if (!isDropdownToggle) {
	  SideBarRemove(); // Close the sidebar only if it's not a dropdown toggle
	}
  };

  return (
    <aside className="slide-bar off-canvas">
      <div className="close-mobile-menu" onClick={SideBarRemove}>
        <i className="fas fa-times"></i>
      </div>

      <nav className="side-mobile-menu">
        <MetisMenu id="mobile-menu-active">
          <li><Link to="/" onClick={handleMenuItemClick}>Home</Link></li>
          <li><Link to="/how-textlink-works" onClick={handleMenuItemClick}>How It Works</Link></li>
          <li><Link to="/ghl" onClick={handleMenuItemClick}>GoHighLevel Integration</Link></li>

          <li className="has-dropdown">
            <Link to="services" onClick={handleMenuItemClick}>Products</Link>
            <ul className="sub-menu">
              <li><Link to="/smstoolsoverview" onClick={handleMenuItemClick}>Overview</Link></li>
              <li><Link to="/web-sms-app" onClick={handleMenuItemClick}>Web Texting App</Link></li>
              <li><Link to="/smsapi" onClick={handleMenuItemClick}>SMS API</Link></li>
              <li><Link to="/bulksms" onClick={handleMenuItemClick}>No Code Bulk SMS</Link></li>
            </ul>
          </li>

          <li className="has-dropdown">
            <Link to="services" onClick={handleMenuItemClick}>Industries</Link>
            <ul className="sub-menu">
              <li><Link to="/trucking" onClick={handleMenuItemClick}>Trucking</Link></li>
              <li><Link to="/sms-marketing" onClick={handleMenuItemClick}>Marketing</Link></li>
              <li><Link to="/saas" onClick={handleMenuItemClick}>SAAS</Link></li>
            </ul>
          </li>

          <li><Link to="/affiliate-program" onClick={handleMenuItemClick}>Affiliate</Link></li>
          <li><Link to="/pricing" onClick={handleMenuItemClick}>Pricing</Link></li>
          <li><Link to="/contact" onClick={handleMenuItemClick}>Contact</Link></li>
          <li><Link to="/blogs" onClick={handleMenuItemClick}>Blog</Link></li>

          <li className={!signedIn && "nav-item"} style={signedIn ? signedInButtonStyle : {}}>
            <a className={signedIn ? "theme_btn quote-btn" : "nav-link"} href={signedIn ? "/dashboard" : "/signin"} onClick={handleMenuItemClick}>
              {signedIn ? "Dashboard" : "SignIn"}
            </a>
          </li>

          {!signedIn && (
            <li className="nav-item">
              <a className="nav-link" href="/signup" onClick={handleMenuItemClick}>SignUp</a>
            </li>
          )}
        </MetisMenu>
      </nav>
    </aside>
  );
}
/*----------------------------------------------------------------
			<li><Link to="/textlink-ghl-integration">GoHighLevel</Link></li>

*/