import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MainMenu from './mainmenu';
import SideBar from './SideBar';
import { useNavigate } from 'react-router-dom';
import CookieConsent from '../components/CookieConsent';
import Cookies from 'js-cookie';

export default function HeaderOne() {
    const navigate = useNavigate();
    const signedIn = Cookies.get('token') ? Cookies.get('token').length > 5 : false;

    const openSideBar = () => {
        document.body.classList.add('on-side');
    };

    const [isNavbarVisible, setIsNavbarVisible] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    // Scroll behavior logic
    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            if (currentScrollY > lastScrollY && currentScrollY > 100) {
                // Scrolling down
                setIsNavbarVisible(false);
            } else {
                // Scrolling up
                setIsNavbarVisible(true);
            }
            setLastScrollY(currentScrollY);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [lastScrollY]);

    return (
        <>
            <CookieConsent />
            <div className="body-overlay activemode"></div>
            <SideBar />
            <header className={`header-top-area ${isNavbarVisible ? 'visible' : 'hidden'}`}>
                {/* Main Navbar */}
                <div className="main-header-area">
                    <div className="container">
                        <div className="navbar">
                            {/* Logo */}
                            <div className="logo-area">
                                <Link to="/" className="logo">
                                    <img src="assets/img/logo/logo2.png" className="ic-logo" alt="Header-logo" />
                                    <span className="logo-text">TextLink</span>
                                </Link>
                            </div>

                            {/* Menu Items */}
                            <div className="menu-area">
                                <MainMenu />
                            </div>

                            {/* Dashboard/Signup Button */}
                            <div className="nav-links">
                                <Link
                                    to={signedIn ? '/dashboard' : '/signup'}
                                    className={signedIn ? 'dashboard-btn' : 'signup-btn'}
                                >
                                    {signedIn ? 'Dashboard' : 'Sign up now'}
                                </Link>
                            </div>

                            {/* Menu Toggle for Mobile */}
                            <div className="menu-toggle" onClick={openSideBar}>
                                <img src="assets/img/icon/menu.png" alt="Menu" />
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            {/* Styles */}
            <style jsx>{`
                .header-top-area {
                    background: #ffffff;
                    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    z-index: 1000;
                    transition: transform 0.3s ease;
                }

                .header-top-area.visible {
                    transform: translateY(0);
                }

                .header-top-area.hidden {
                    transform: translateY(-100%);
                }

                .main-header-area {
                    padding: 0px 0;
                }

                .navbar {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

                .logo-area {
                    display: flex;
                    align-items: center;
                    margin-right: 20px
                }

                .logo {
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    font-family: 'Nunito', sans-serif;
                    font-size: 1.5em;
                    font-weight: bold;
                    color: #333;
                }

                .logo img {
                    margin-right: 10px;
                }

                .menu-area {
                    flex-grow: 1;
                    margin: 0 20px;
                }

                .nav-links {
                    display: flex;
                    align-items: center;
                    gap: 30px;
                }

                /* Sign up now Button */
                .signup-btn {
                    background: #007bff;
                    color: #fff;
                    padding: 12px 30px;
                    border-radius: 10px;
                    text-decoration: none;
                    font-weight: 600;
                    font-size: 1em;
                    transition: all 0.3s ease;
                    box-shadow: 0 4px 6px rgba(0, 123, 255, 0.2);
                }

                .signup-btn:hover {
                    background:rgb(7, 125, 252);
                    transform: translateY(-2px);
                }

                /* Dashboard Button */
                .dashboard-btn {
                    background: #eff7ff;
                    color: #086ad8;
                    padding: 12px 20px;
                    border-radius: 10px;
                    text-decoration: none;
                    font-weight: 600;
                    font-size: 1em;
                    transition: all 0.3s ease;
                    box-shadow: 0 4px 6px rgba(8, 106, 216, 0.1);
                }

                .dashboard-btn:hover {
                    background: #d6e7ff;
                    transform: translateY(-2px);
                    box-shadow: 0 6px 12px rgba(8, 106, 216, 0.2);
                }

                .menu-toggle {
                    cursor: pointer;
                    display: none;
                }

                .menu-toggle img {
                    width: 24px;
                    height: 24px;
                }
                @media (min-width: 993px) and (max-width: 1220px) {
                    .ic-logo {
                        display: none;
                    }
                }

                @media (max-width: 1299.98px) {
                .logo-text {
                    display: none;
                }
                    
                @media (max-width: 992px) {
                    .menu-area {
                        display: none;
                    }

                    .nav-links {
                        display: none;
                    }

                    .menu-toggle {
                        display: block;
                    }
                }
            `}</style>
        </>
    );
}