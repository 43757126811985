import React from "react";
import { Link, useNavigate } from "react-router-dom";
import FooterOne from "../../footer/FooterOne";
import Head from "../../components/Head";
import HeaderOne from "../../header/HeaderOne";

const HowItWorks = () => {
  const navigate = useNavigate();

  return (
    <>
      <Head title="How TextLink Works" />
      <HeaderOne />

      <section className="how-it-works-section">
        <div className="container">
          {/* Description Section */}
          <div className="description">
            <h1 className="gradient-text">How TextLink Works</h1>
            <p className="lead-text">
              TextLink allows you to send messages through your own devices and
              SIM cards using our <strong>APIs</strong>,{" "}
              <strong>no-code tools</strong>, and{" "}
              <strong>integrations</strong>. Enjoy{" "}
              <strong className="highlight">lower costs</strong>,{" "}
              <strong className="highlight">no restrictions</strong>, and an{" "}
              <strong className="highlight">easy setup process</strong>—no forms,
              licenses, or registrations required.
            </p>
          </div>

          {/* Step-by-Step Section */}
          <div className="steps-container">
            <div className="steps-grid">
              <div className="step-card">
                <div className="step-header">
                  <div className="step-icon">1</div>
                  <div className="step-title-content">
                    <h2>Device Setup</h2>
                    <p>
                      <Link to="/signup" className="register-link">
                        Register here
                      </Link>{" "}
                      and start with 500 free messages. Download the TextLink app on
                      your spare Android device, insert your SIM card, and connect to
                      Wi-Fi.
                    </p>
                  </div>
                </div>
              </div>

              <div className="step-card">
                <div className="step-header">
                  <div className="step-icon">2</div>
                  <div className="step-title-content">
                    <h2>Sub-Accounts</h2>
                    <p>
                      Create sub-accounts for your team and allocate SIM cards to each
                      account. Manage resources through the dashboard.
                    </p>
                  </div>
                </div>
              </div>

              <div className="step-card">
                <div className="step-header">
                  <div className="step-icon">3</div>
                  <div className="step-title-content">
                    <h2>Integrations</h2>
                    <p>
                      Use no-code tools for bulk messaging or integrate our APIs for
                      two-way SMS, user verification, and more.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Call to Action Section */}
          <div className="cta-section">
            <div className="cta-content">
              <h2>Ready to Get Started?</h2>
              <p>Join TextLink today and experience seamless SMS communication.</p>
              <button className="cta-primary" onClick={() => navigate("/signup")}>
                <span>Sign Up Now</span>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  className="cta-icon"
                >
                  <path d="M5 12h14M12 5l7 7-7 7" stroke="currentColor" strokeWidth="2" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </section>

      <FooterOne />

      <style jsx>{`
        .how-it-works-section {
          padding: 10rem 0 4rem;
          background: linear-gradient(to bottom, #f8faff 0%, #ffffff 100%);
        }

        .container {
          max-width: 1200px;
          margin: 0 auto;
          padding: 0 1.5rem;
        }

        .description {
          text-align: center;
          margin-bottom: 4rem;
        }

        .gradient-text {
        
          -webkit-background-clip: text;
          background-clip: text;
          color: black;
          font-size: 2.25rem;
          font-weight: 800;
          margin-bottom: 1.5rem;
          line-height: 1.2;
        }

        .lead-text {
          font-size: 1.1rem;
          color: #475569;
          line-height: 1.7;
          margin: 0 auto;
          max-width: 800px;
        }

        .highlight {
          color: #3b82f6;
          font-weight: 600;
        }

        .steps-container {
          overflow-x: auto;
          -webkit-overflow-scrolling: touch;
          margin-bottom: 4rem;
          padding-bottom: 1rem;
        }

        .steps-grid {
          display: inline-flex;
          gap: 1.5rem;
          min-width: 100%;
          padding: 0 1rem;
        }

        .step-card {
          background: white;
          border-radius: 16px;
          padding: 1.5rem;
          border: 1px solid #e2e8f0;
          transition: all 0.2s ease;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
          min-width: 300px;
          max-width: 360px;
          scroll-snap-align: start;
        }

        .step-card:hover {
          transform: translateY(-3px);
          box-shadow: 0 8px 12px rgba(59, 130, 246, 0.1);
        }

        .step-header {
          display: flex;
          gap: 1.5rem;
          align-items: flex-start;
        }

        .step-icon {
          width: 40px;
          height: 40px;
          border-radius: 10px;
          background: linear-gradient(45deg, #3b82f6, #6366f1);
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.25rem;
          font-weight: 700;
          flex-shrink: 0;
          margin-top: 0.25rem;
        }

        .step-title-content {
          flex: 1;
        }

        .step-card h2 {
          font-size: 1.3rem;
          color: #0f172a;
          margin: 0 0 0.75rem;
        }

        .step-card p {
          color: #64748b;
          line-height: 1.6;
          margin: 0;
          font-size: 1rem;
        }

        .register-link {
          color: #3b82f6;
          font-weight: 600;
          text-decoration: none;
          transition: all 0.2s ease;
        }

        .register-link:hover {
          color: #2563eb;
          text-decoration: underline;
        }

        .cta-section {
          text-align: center;
          margin-top: 3rem;
        }

        .cta-content {
          max-width: 600px;
          margin: 0 auto;
          padding: 2.5rem;
          background: white;
          border-radius: 16px;
        }

        .cta-section h2 {
          font-size: 1.75rem;
          color: #0f172a;
          margin-bottom: 1rem;
        }

        .cta-section p {
          color: #64748b;
          margin-bottom: 2rem;
          font-size: 1.05rem;
        }

        .cta-primary {
          background: linear-gradient(45deg, #3b82f6, #6366f1);
          color: white;
          padding: 1rem 2rem;
          border-radius: 8px;
          font-weight: 600;
          border: none;
          cursor: pointer;
          display: inline-flex;
          align-items: center;
          gap: 0.75rem;
          transition: all 0.2s ease;
          box-shadow: 0 4px 6px rgba(59, 130, 246, 0.1);
        }

        .cta-primary:hover {
          transform: translateY(-2px);
          box-shadow: 0 8px 12px rgba(59, 130, 246, 0.2);
        }

        .cta-icon {
          width: 20px;
          height: 20px;
        }

        @media (min-width: 1024px) {
          .steps-container {
            overflow: visible;
          }
          .steps-grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            width: 100%;
            padding: 0;
          }
          .step-card {
            min-width: auto;
            max-width: none;
          }
        }

        @media (max-width: 768px) {
          .container {
            padding: 0 1rem;
          }

          .gradient-text {
            font-size: 1.875rem;
          }

          .step-header {
            gap: 1rem;
          }

          .step-icon {
            width: 36px;
            height: 36px;
            font-size: 1.1rem;
          }

          .step-card h2 {
            font-size: 1.2rem;
          }

          .cta-content {
            padding: 1.5rem;
          }
        }

        @media (max-width: 480px) {
          .how-it-works-section {
            padding: 4rem 0;
          }

          .gradient-text {
            font-size: 1.625rem;
          }

          .lead-text {
            font-size: 1rem;
          }

          .step-header {
            flex-direction: column;
            align-items: center;
            text-align: center;
          }

          .step-icon {
            margin: 0 0 1rem;
          }

          .step-card {
            min-width: 280px;
            padding: 1.25rem;
          }

          .cta-section h2 {
            font-size: 1.5rem;
          }

          .cta-primary {
            width: 100%;
            justify-content: center;
          }
        }
      `}</style>
    </>
  );
};

export default HowItWorks;