import { Link } from "react-router-dom";
import React from 'react';
import HomeWorkProcess from "./HomeWorkPorcess";
import './HomeAbout2.css'; // Import the CSS file for this component

export default function HomeAbout2() {
  return (
    <section id="about" className="about-us-area pt-155 pb-120 pt-md-60 pb-md-30 pt-xs-60 pb-xs-30">
      <div className="container">
        <div className="row">
          {/* Left Column: Text Content */}
          <div className="col-lg-6">
            <div className="about-text-wrapper mb-30">
              <h2 className="style-text">TOOLS</h2>
              <h2 className="sect-title">More About The Setup Process</h2>

              {/* What Do I Need To Start */}
              <div className="quote-paragraph">
                <h3>What Do I Need To Start</h3>
                <br />
                <p className="paragraph-title">
                  Not much; everything you need is a spare Android phone, a SIM card that can inexpensively send SMS to desired locations, and a stable internet connection.
                </p>
              </div>

              {/* How To */}
              <div className="quote-paragraph">
                <h3>How To</h3>
                <br />
                <p className="paragraph-title">
                  Once you register, you will be guided step by step, but to sum it up: <br />
                  Download our app onto the spare Android phone (it should already have the desired SIM card inside of it), log in with your TextLink account, and enable the country you want to send to. Once that is done, just connect it to a charger and hook onto a stable Wi-Fi connection. Now go back to your TextLink Dashboard on your browser, and you will be able to use all of our SMS tools.
                </p>
              </div>
            </div>
          </div>

          {/* Right Column: Embedded Video */}
          <div className="col-lg-6" >
            <div className="about-video-wrapper mb-30" >

              <div className="video-container">
                <iframe
                  src="https://www.youtube.com/embed/nyVm4U5iScg"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>
  );
}
