import React, { useContext } from "react";
import { DropdownToggle, DropdownMenu, UncontrolledDropdown, DropdownItem } from "reactstrap";
import { Link } from "react-router-dom";
import { Icon, UserAvatar } from "../../../components/Component";
import { findUpper } from "../../../utils/Utils";
import { ChatContext } from "./ChatContext";
import { useState } from "react";
import Cookies from "js-cookie";
import Swal from "sweetalert2";

export const MeChat = ({ item, chat, onRemoveMessage }) => {
  return (
    <div className="chat is-me">
      <div className="chat-content" style={{ width: '100%' }}>
        <div className="chat-bubbles">
          {item.chat.map((msg, idx) => {
            return (
              <div className="chat-bubble" key={idx}>
                <React.Fragment>
                  <div onClick={(item.status == 'failed') ? () => {
                    Swal.fire({
                      icon: 'error',
                      title: 'Your message could not be delivered',
                      html: `<p style="text-align: left;"><br>Common causes include: <br>● Insufficient carrier credits<br>● Poor network connectivity<br>● Invalid or uninstalled TextLink app config (try logging in again)<br><br>Please check if messages are working on your device's default SMS app, and if sending the same message to the same number would work.<br><br>The failed messages can also happen if the TextLink app config is invalid, e.g. your android app is uninstalled or corrupted. You may need to delete your old device installation in <a style="color: blue;" href="https://textlinksms.com/dashboard/your-devices/overview">your device dashboard</a></p>`,
                      confirmButtonText: 'Okay'
                    });
                  } : ((item.status == 'sending') ? () => {
                    Swal.fire({
                      icon: 'info',
                      title: 'We are sending your message...',
                      html: `<p style="text-align: left;"><br>Please wait for your message to be sent.<br><br>
                  If the message has been sending for too long, the most probable cause is that we can not reach your Android device. 
                    Check its internet connection and make sure that the app is running. <br><br>If you uninstall the app, we can not reach
                    your device and the messages are permanently left in this state. </p>`,
                      confirmButtonText: 'Okay'
                    });
                  } : ((item.status == 'queued') ? () => {
                    Swal.fire({
                      icon: 'info',
                      title: 'Message is waiting in queue...',
                      html: `<p style="text-align: left;"><br>Please wait for your message to be sent.<br><br>If the message has been queued for too long, it is because its SIM card has too many queued messages.<br><br>Please wait for your other messages to be sent. We limit your sending speed to save your number from becoming blocked by carrier. The pause we make between messages is 3-3.5 seconds.  </p>`,
                      confirmButtonText: 'Okay'
                    });
                  } : () => { }))} style={{
                    backgroundColor: (item.status == 'failed') ? "#d32f2f" : "#6576ff",
                    whiteSpace: 'pre-wrap',
                    wordWrap: 'break-word',
                    maxWidth: '60%',
                    cursor: (item.status == 'sending' || item.status == 'queued' || item.status == 'failed') ? 'pointer' : 'default'
                  }} className={"chat-msg"}>{msg}</div>
                </React.Fragment>
              </div>
            );
          })}
        </div>
        {(item.status == 'sending' || item.status == 'queued') ? <ul className="chat-meta">
          <li>{item.date} ({((item.status) == 'queued') ? 'Queued' : 'Sending'}...)</li>
        </ul> : (
          item.status == 'failed' ? <ul className="chat-meta">
            <li style={{ color: '#b22222' }}>{item.date} (Failed)</li>
          </ul> : (
            <ul className="chat-meta">
              <li>{item.date}</li>
            </ul>
          )
        )}
      </div>
    </div>
  );
};

export const YouChat = ({ item, chat }) => {
  return (
    <div className="chat is-you">
      <div className="chat-avatar">
        {chat.group ? (
          <UserAvatar image={item.user.image} theme={item.user.theme} text={findUpper(item.user.name)}>
            {" "}
            {chat.active === true ? (
              <span className="status dot dot-lg dot-success"></span>
            ) : (
              <span className="status dot dot-lg dot-gray"></span>
            )}
          </UserAvatar>
        ) : (
          <UserAvatar image={chat.image} theme={chat.theme} text={findUpper(chat.name)}>
            {" "}
            {chat.active === true ? (
              <span className="status dot dot-lg dot-success"></span>
            ) : (
              <span className="status dot dot-lg dot-gray"></span>
            )}
          </UserAvatar>
        )}
      </div>
      <div className="chat-content" style={{ width: '100%' }}>
        <div className="chat-bubbles">
          {item.chat.map((msg, idx) => {
            return (
              <div className="chat-bubble" key={idx}>
                <div className="chat-msg" style={{
                  whiteSpace: 'pre-wrap',
                  wordWrap: 'break-word',
                  maxWidth: '60%'
                }}>{msg}</div>
              </div>
            );
          })}
        </div>
        <ul className="chat-meta" style={{ paddingLeft: "0px !important" }}>
          <li>{item.date}</li>
        </ul>
      </div>
    </div>
  );
};

export const MetaChat = ({ item }) => {
  return (
    <div className="chat-sap">
      <div className="chat-sap-meta">
        <span>{item.metaText}</span>
      </div>
    </div>
  );
};

export const ChatItem = ({ item, chatItemClick, setSelectedId, selectedId, openGroupModal }) => {
  const token = Cookies.get('token');
  const isSubUser = Cookies.get("subuser") == "true"
  const { contactsState, favContactsState, contactForAddingState, contactForRenamingState } = useContext(ChatContext);

  const [contacts, setContacts] = contactsState
  const [favContacts, setFavContacts] = favContactsState
  const [contactForAdding, setContactForAdding] = contactForAddingState
  const [contactForRenaming, setContactForRenaming] = contactForRenamingState

  const addContact = (item) => {
    setContactForAdding(item.name);
  }
  const renameContact = (item) => {
    setContactForRenaming(item.name);
  }
  const removeContact = async (item) => {
    const id = item?.name ? (contacts[item.name]?.id) : null

    if (!id)
      return;
    const url = `${global.SERVIP}/user/delete-contact`;
    const body = { id };
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(body)
      });
      const result = await response.json();

      if (result.ok) {
        delete contacts[item?.name]
        setContacts({ ...contacts })
        if (favContacts[item?.name]) {
          delete favContacts[item?.name]
          setFavContacts({ ...favContacts })
        }
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  }
  const addFavoriteContact = async (item) => {
    const id = contacts[item.name].id;

    if (!id)
      return;
    const url = `${global.SERVIP}/user/add-favorite-contact`;
    const body = { id };
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(body)
      });
      const result = await response.json();

      if (result.ok) {
        favContacts[item?.name] = true;
        setFavContacts({ ...favContacts })
      }
    } catch (error) {
      console.log(error);
    }
  }
  const removeFavoriteContact = async (item) => {
    const id = contacts[item.name].id;

    if (!id)
      return;
    const url = `${global.SERVIP}/user/remove-favorite-contact`;
    const body = { id };
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(body)
      });
      const result = await response.json();

      if (result.ok) {
        delete favContacts[item?.name];
        setFavContacts({ ...favContacts })
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <li className={`chat-item ${item.unread ? "is-unread" : ""}`}>
      <a
        className="chat-link"
        href="#chat-link"
        onClick={(ev) => {
          ev.preventDefault();
          chatItemClick(item.id);
        }}
      >
        {item.group === true ? (
          <div className="chat-media user-avatar user-avatar-multiple">
            {item.map((user, idx) => {
              return (
                <UserAvatar
                  key={idx}
                  theme={user.theme}
                  text={contacts[user.name] ? contacts[user.name].name : user.name}
                  image={user.image}
                  className="chat-media"
                ></UserAvatar>
              );
            })}
            <span className={"status dot dot-lg dot-success"}></span>
          </div>
        ) : (
          <UserAvatar theme={item.theme} text={contacts[item.name] ? contacts[item.name].name : item.name} image={item.image} className="chat-media">
            <span className={`status dot dot-lg dot-${item.active === true ? "success" : "gray"}`}></span>
          </UserAvatar>
        )}
        <div className="chat-info">
          <div className="chat-from">
            <div className="name">{contacts[item.name] ? contacts[item.name].name : item.name}</div>
            <span className="time">{item.date}</span>
          </div>
          <div className="chat-context">
            <div className="text">
              <p>{item.convo.length !== 0 && item.convo[item.convo.length - 1].chat.at(-1)}</p>
            </div>
            <div className="status delivered">
              <Icon
                name={`${item.delivered === true ? "check-circle-fill" : item.delivered === "sent" ? "check-circle" : ""
                  }`}
              ></Icon>
            </div>
          </div>
        </div>
      </a>
      <div className="chat-actions">
        <UncontrolledDropdown>
          <DropdownToggle tag="a" className="btn btn-icon btn-sm btn-trigger dropdown-toggle">
            <Icon name="more-h"></Icon>
          </DropdownToggle>
          <DropdownMenu end>
            <ul className="link-list-opt no-bdr">
              {(isSubUser && contacts[item.name] && !favContacts[item.name]) && <li onClick={() => addFavoriteContact(item)}>
                <DropdownItem tag="a" href="#delete" onClick={(ev) => { ev.preventDefault(); }} >
                  Add to Your Contacts
                </DropdownItem>
              </li>}
              {(isSubUser && contacts[item.name] && favContacts[item.name]) && <li onClick={() => removeFavoriteContact(item)}>
                <DropdownItem tag="a" href="#delete" onClick={(ev) => { ev.preventDefault(); }}    >
                  Remove from Your Contacts
                </DropdownItem>
              </li>}
              {!contacts[item.name] && <li onClick={() => addContact(item)}>
                <DropdownItem tag="a" href="#delete" onClick={(ev) => { ev.preventDefault(); }} >
                  Add to All Contacts
                </DropdownItem>
              </li>}
              {contacts[item.name] && <li onClick={() => renameContact(item)}>
                <DropdownItem tag="a" href="#delete" onClick={(ev) => { ev.preventDefault(); }} >
                  Rename Contact
                </DropdownItem>
              </li>}
              {contacts[item.name] && <li onClick={() => removeContact(item)}>
                <DropdownItem tag="a" href="#delete" onClick={(ev) => { ev.preventDefault(); }} >
                  Remove from All Contacts
                </DropdownItem>
              </li>}
              <li onClick={() => {
                openGroupModal(item.name, true);
              }}>
                <DropdownItem tag="a" href="#delete" onClick={(ev) => { ev.preventDefault(); }} >
                  Add to Group
                </DropdownItem>
              </li>
              <li onClick={() => {
                openGroupModal(item.name, false);
              }}>
                <DropdownItem tag="a" href="#delete" onClick={(ev) => { ev.preventDefault(); }} >
                  Remove from Group
                </DropdownItem>
              </li>
            </ul>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    </li>
  );
};

export const ContactItem = ({ item, setTab, setSelectedId }) => {
  return (
    <ul className="contacts-list">
      <li>
        <h6 className="title overline-title-alt">{item.contacts.length > 0 && item.title}</h6>
      </li>
      {item.contacts.map((contact, idx) => {
        return (
          <li
            key={idx}
            onClick={() => {
              setTab("Chats");
              setSelectedId(contact.id);
            }}
          >
            <div className="user-card">
              <a href="#card" onClick={(ev) => ev.preventDefault()}>
                <UserAvatar text={findUpper(contact.name)} theme={contact.theme} image={contact.image}></UserAvatar>
                <div className="user-name">{contact.name}</div>
              </a>
              <div className="user-actions">
                <Link to={`${process.env.PUBLIC_URL}/app-chat`}>Start Chat</Link>
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
};
