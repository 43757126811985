import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import Cookies from "js-cookie";
import Swal from "sweetalert2"; // Import SweetAlert2
import WithdrawButton from "./components/WithdrawButton";
// Import custom components and layout
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Row,
  Col,
  PreviewAltCard,
} from "../../components/Component";
import { Card, Button, Input, Spinner, CardBody } from "reactstrap";
import { Icon } from "../../components/Component";

// Import charts (using dummy data for now)
import AffiliateChart from "./components/AffiliateChart";
import "./affiliate.css";



const AffiliatePage = () => {
  const navigate = useNavigate();
  const token = Cookies.get("token");

  // State for affiliate code
  const [affiliateCode, setAffiliateCode] = useState("");
  const [isSavingCode, setIsSavingCode] = useState(false);
  const [isCodeSet, setIsCodeSet] = useState(false);

  // State for analytics data
  const [analyticsData, setAnalyticsData] = useState({
    lifetimeRevenue: 0,
    withdrawableEarnings: 0,
    chartData: [],
  });

  // Fetch affiliate code and analytics data on page load
  useEffect(() => {
    const fetchAffiliateCode = async () => {
      try {
        const url = `${global.SERVIP}/user/get-affiliate-code`;
        const jwtToken = Cookies.get("token");

        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        if (data.ok) {
          if (data.affiliateCode) {
            setAffiliateCode(data.affiliateCode);
            setIsCodeSet(true);
          } else {
            setIsCodeSet(false);
          }
        } else {
          throw new Error(data.message || "Failed to fetch affiliate code.");
        }
      } catch (error) {
        console.error("Error fetching affiliate code:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to fetch affiliate code. Please try again.",
        });
      }
    };

    const fetchAnalyticsData = async () => {
      try {
        const url = `${global.SERVIP}/user/get-affiliate-analytics`;
        const jwtToken = Cookies.get("token");

        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
        });

        if (!response.ok) {
          return;
        }

        const data = await response.json();

        if (data.ok) {
          setAnalyticsData({
            lifetimeRevenue: data.lifetimeRevenue,
            withdrawableEarnings: data.withdrawableEarnings,
            chartData: data.chartData,
          });
        } else {
          throw new Error(data.message || "Failed to fetch analytics data.");
        }
      } catch (error) {
        console.error("Error fetching analytics data:", error);

      }
    };

    fetchAffiliateCode();
    fetchAnalyticsData();
  }, []);

  // Handle copying the affiliate link
  const copyAffiliateLink = () => {
    const link = `https://textlinksms.com/affiliate/${affiliateCode}`;
    navigator.clipboard.writeText(link);
    Swal.fire({
      icon: "success",
      title: "Copied!",
      text: "Affiliate link copied to clipboard.",
    });
  };

  // Handle saving the affiliate code
  const handleSaveCode = async () => {
    if (!affiliateCode) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please enter a valid affiliate code.",
      });
      return;
    }

    setIsSavingCode(true);

    try {
      const url = `${global.SERVIP}/user/set-affiliate-link`;
      const jwtToken = Cookies.get("token");

      const requestBody = {
        affiliate_link: affiliateCode,
      };

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      if (data.ok) {
        setIsCodeSet(true);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: data.message || "Affiliate code saved successfully!",
        });
      } else {
        throw new Error(data.message || "Failed to save affiliate code.");
      }
    } catch (error) {
      console.error("Error saving affiliate code:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message || "Failed to save affiliate code. Please try again.",
      });
    } finally {
      setIsSavingCode(false);
    }
  };

  return (
    <>
      <Head title="Affiliate Program" />
      <Content>
        <BlockHead size="sm">
          <div className="nk-block-between">
            <BlockHeadContent>
              <BlockTitle page tag="h3">
                Affiliate Program
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>Earn commissions by referring users to TextLink.</p>
              </BlockDes>
            </BlockHeadContent>
          </div>
        </BlockHead>

        <Block>
          <Row className="g-gs">
            {/* Analytics Section (Left Column) */}
            <Col lg="6" xxl="6" className="mb-4">
              <PreviewAltCard className="h-100">
                <h5 className="card-title">Analytics</h5>
                <div className="affiliate-analytics">
                  <div className="affiliate-metric">
                    <span className="metric-label">Lifetime Commissions</span>
                    <span className="metric-value">${analyticsData.lifetimeRevenue}</span>
                  </div>
                  <div className="affiliate-metric">
                    <span className="metric-label">Withdrawable Now</span>
                    <span className="metric-value">${analyticsData.withdrawableEarnings}</span>
                    <WithdrawButton analyticsData={analyticsData ? analyticsData : null} />
                  </div>
                </div>
                <div className="affiliate-chart">
                  <AffiliateChart data={analyticsData.chartData} />
                </div>
              </PreviewAltCard>
            </Col>

            {/* Right Column */}
            <Col lg="6" xxl="6" className="mb-4">
              {/* Your Affiliate Code Section */}
              <Card className="card-bordered mb-4">
                <CardBody>
                  <h5 className="card-title">Your Affiliate Code</h5>
                  <div className="affiliate-code-section text-left">
                    <p className="text-soft mb-3">
                      Set your own affiliate code and share your unique link to
                      start earning commissions.{" "}
                      <br />
                      {isCodeSet ? (
                        <strong className="text-success">
                          You have set your affiliate code, you can't change it.
                        </strong>
                      ) : (
                        <strong className="text">
                          Note: You can only set your affiliate code once. After
                          setting, it cannot be changed.
                        </strong>
                      )}
                    </p>
                    <div className="d-flex align-items-center gap-2">
                      <Input
                        type="text"
                        value={affiliateCode}
                        onChange={(e) => setAffiliateCode(e.target.value)}
                        placeholder="Create and save your affiliate code"
                        disabled={isCodeSet}
                      />
                      {!isCodeSet && (
                        <Button
                          color="primary"
                          className="btn-dim"
                          onClick={handleSaveCode}
                          disabled={isSavingCode}
                          style={{ width: 200 }}
                        >
                          {isSavingCode ? (
                            <Spinner size="sm" color="light" />
                          ) : (
                            "Save Code"
                          )}
                        </Button>
                      )}
                    </div>
                    <Button
                      color="primary"
                      className="btn-dim mt-4"
                      onClick={copyAffiliateLink}
                      disabled={!isCodeSet} // Disable if affiliateCode is empty
                    >
                      <Icon name="copy"></Icon> Copy Affiliate Link
                    </Button>
                    {isCodeSet ? <p className="mt-3 text-soft">
                      Your affiliate link:{" "}
                      <a
                        href={`https://textlinksms.com/affiliate/${affiliateCode}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        https://textlinksms.com/affiliate/{affiliateCode}
                      </a>
                    </p> : null}
                  </div>
                </CardBody>
              </Card>

              {/* How It Works Section */}
              <Card className="card-bordered">
                <CardBody>
                  <h5 className="card-title">How It Works</h5>
                  <div className="affiliate-explanation">
                    <p>
                      Earn <strong>20%</strong> of each registered user's monthly
                      subscription as long as they are using the service. Here's
                      how it works:
                    </p>
                    <div className="steps-list">
                      <div className="step-item">
                        <div className="step-icon">
                          <Icon name="share" style={{ color: "white" }} />
                        </div>
                        <div className="step-content">
                          <h6>Share Your Link</h6>
                          <p>
                            Share your affiliate link with friends, colleagues, or
                            on social media.
                          </p>
                        </div>
                      </div>
                      <div className="step-item">
                        <div className="step-icon">
                          <Icon name="user-check" style={{ color: "white" }} />
                        </div>
                        <div className="step-content">
                          <h6>Refer Users</h6>
                          <p>
                            When someone signs up using your link, they become your
                            referral.
                          </p>
                        </div>
                      </div>
                      <div className="step-item">
                        <div className="step-icon">
                          <Icon name="wallet" style={{ color: "white" }} />
                        </div>
                        <div className="step-content">
                          <h6>Earn Commissions</h6>
                          <p>
                            You earn 20% of their monthly subscription fees for as
                            long as they remain a customer.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Block>
      </Content>
    </>
  );
};

export default AffiliatePage;