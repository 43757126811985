import React from 'react';
import { Card, CardBody, Progress, UncontrolledTooltip, Badge, Row, Col } from 'reactstrap';
import { FiClock, FiMessageSquare, FiPhone } from 'react-icons/fi';

// Helper: get next message time from nextMessage.time
const getNextMessageTime = (nextMessage) => nextMessage.time || '';

export function CampaignStats({ campaign, bulkJob, nextMessage }) {
  // Basic counts.
  const sent = bulkJob.sent_messages || 0;
  const sending = bulkJob.sending_messages || 0;
  const failed = bulkJob.failed_messages || 0;
  const responses = bulkJob.responses || 0;
  const totalProcessed = sent + sending + failed;
  const total = bulkJob.total_messages || 0;
  const remaining = total - totalProcessed;

  // Derived rates.
  const responseRate = sent > 0 ? ((responses / sent) * 100).toFixed(1) : 'N/A';
  const deliveryRate =
    totalProcessed > 0 ? ((sent / totalProcessed) * 100).toFixed(1) : 'N/A';

  // Determine campaign status.
  let statusLabel = '';
  if (totalProcessed >= total) {
    statusLabel = 'Done';
  } else if (bulkJob.paused) {
    statusLabel = 'Paused';
  } else if (
    nextMessage &&
    // I ovo je heuristika, al tipa recice ongoing kad si stavio da salje poruku za 5 sati a tipa ima 7.5 sati do sledece zato sto se ceka, nije nesto lose
    new Date(nextMessage.scheduled_time) - new Date() > Math.floor(bulkJob.wait * (bulkJob.randomized_interval ? 1.5 : 1))
  ) {
    statusLabel = 'On hold';
  } else {
    statusLabel = 'Ongoing';
  }
  // For "On hold", use nextMessage.time for resume time.
  const resumeTime =
    statusLabel === 'On hold' && nextMessage ? getNextMessageTime(nextMessage) : '';

  // Badge color mapping.
  const badgeColor =
    statusLabel === 'Done'
      ? 'success'
      : statusLabel === 'Ongoing'
        ? 'warning'
        : statusLabel === 'Paused'
          ? 'danger'
          : statusLabel === 'On hold'
            ? 'secondary'
            : 'primary';

  let nextMessageDetails = null;
  if (!bulkJob.paused && nextMessage) {
    const formatMessageText = (text) => {
      const cleanText = text.trim();
      const firstLine = cleanText.split('\n')[0];

      if (cleanText.length <= 35 && !cleanText.includes('\n')) return cleanText;
      const truncateAt = Math.min(firstLine.length, 32);
      return `${firstLine.substring(0, truncateAt)}...`;
    };

    const tooltipId = `msg-${nextMessage.id || Date.now()}`;

    nextMessageDetails = (
      <Card className="border position-relative" style={{
        width: 'fit-content',
        maxWidth: '400px',
        minHeight: '132px', // Fixed minimum height
        borderColor: '#dee2e6',
        paddingTop: '12px',
        marginTop: 12
      }}>
        {/* Breaking border label */}
        <div className="position-absolute bg-white px-2"
          style={{
            top: '0',
            left: '12px',
            fontSize: '0.75rem',
            fontWeight: 600,
            letterSpacing: '0.5px',
            color: '#495057',
            transform: 'translateY(-50%)'
          }}>
          NEXT MESSAGE
        </div>

        <CardBody className="py-3 px-3 pt-4"> {/* Adjusted padding */}
          <div className="d-flex flex-column gap-3"> {/* Increased gap */}
            {/* Message Content */}
            <div className="d-flex align-items-center gap-2">
              <FiMessageSquare className="text-muted" size={16} />
              <div
                id={tooltipId}
                style={{
                  fontSize: '0.9rem',
                  maxWidth: '300px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  lineHeight: '1.5' // Consistent line height
                }}
              >
                {formatMessageText(nextMessage.text)}
              </div>
              <UncontrolledTooltip
                placement="top"
                target={tooltipId}
                innerClassName="text-pre-wrap"
              >
                {nextMessage.text}
              </UncontrolledTooltip>
            </div>

            {/* Phone Number */}
            <div className="d-flex align-items-center gap-2" style={{ marginTop: '2px' }}>
              <FiPhone className="text-muted" size={16} />
              <span className="text-muted small">
                {nextMessage.phone_number}
              </span>
            </div>

            {/* Time Section */}
            <div className="d-flex align-items-center gap-2" style={{ marginTop: '2px' }}>
              <FiClock className="text-muted" size={16} />
              <span className="text-muted small">
                {getNextMessageTime(nextMessage)} ({bulkJob.timezone})
              </span>
            </div>
          </div>
        </CardBody>
      </Card>
    );
  }




  // Calculate processed percentage.
  const processedPct = total > 0 ? ((totalProcessed / total) * 100).toFixed(0) : '0';

  return (
    <Card className="mb-3 shadow-sm h-100">
      <CardBody>
        {/* Top Row: Status (left) & Next Message (right) */}
        <div className="d-flex flex-wrap justify-content-between align-items-center mb-3">
          {/* Status */}
          <div id="statusDisplay">
            <Badge color={badgeColor} style={{ fontSize: '1.2rem', padding: '0.3rem 0.6rem' }}>
              {statusLabel}
            </Badge>
            {statusLabel === 'On hold' && resumeTime && (
              <div style={{ fontSize: '0.8rem', color: '#555' }}>Resumes at {resumeTime}</div>
            )}
          </div>
          {/* Next Message Details */}
          {nextMessageDetails}
        </div>

        {/* Campaign Progress */}
        <div style={{ marginBottom: '1rem' }}>
          <div style={{ fontWeight: 'bold', fontSize: '1.1rem', marginBottom: '0.5rem' }}>
            Campaign Progress{' '}
            <a
              onClick={(e) => {
                e.preventDefault();
                window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
              }}
              style={{
                fontSize: '0.9rem',
                fontWeight: 'normal',
                color: '#007bff',
                cursor: 'pointer',
                textDecoration: 'none',
                marginLeft: '0.3rem',
                borderBottom: '1px dotted transparent',
                transition: 'all 0.2s ease'
              }}
              onMouseOver={(e) => (e.currentTarget.style.borderBottomColor = '#007bff')}
              onMouseOut={(e) => (e.currentTarget.style.borderBottomColor = 'transparent')}
            >
              (View messages)
            </a>
          </div>
          <div style={{ fontSize: '0.9rem' }}>
            Processed {processedPct}% of {total} messages
          </div>
          <Progress value={(totalProcessed / total) * 100} className="mb-1" />
          <div style={{ fontSize: '0.9rem' }}>Remaining: {remaining} messages</div>
        </div>

        {/* Message Metrics */}
        <div style={{ marginBottom: '1rem' }}>
          <div style={{ fontWeight: 'bold', fontSize: '1.1rem', marginBottom: '0.5rem' }}>
            Message Metrics
          </div>
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
            {/* Leads Contacted */}
            <div id="leadsContactedMetric" style={{ margin: '0.5rem', textAlign: 'center' }}>
              <div style={{ fontSize: '2rem', fontWeight: 'bold' }}>{sent}</div>
              <div style={{ fontSize: '0.9rem' }}>Leads Contacted</div>
              <UncontrolledTooltip placement="top" target="leadsContactedMetric">
                Number of leads that have been contacted.
              </UncontrolledTooltip>
            </div>

            {/* Replies */}
            <div id="repliesMetric" style={{ margin: '0.5rem', textAlign: 'center' }}>
              <div style={{ fontSize: '2rem', fontWeight: 'bold' }}>{responses}</div>
              <div style={{ fontSize: '0.9rem' }}>
                Replies {sent > 0 ? `(${responseRate}%)` : ''}
              </div>
              <UncontrolledTooltip placement="top" target="repliesMetric">
                Number of replies received.
              </UncontrolledTooltip>
            </div>

            {/* Failed Messages (only if > 0) */}
            {failed > 0 && (
              <div id="failedMetric" style={{ margin: '0.5rem', textAlign: 'center' }}>
                <div style={{ fontSize: '2rem', fontWeight: 'bold' }}>{failed}</div>
                <div style={{ fontSize: '0.9rem' }}>Failed Messages</div>
                <UncontrolledTooltip placement="top" target="failedMetric">
                  Number of messages that failed to deliver.
                </UncontrolledTooltip>
              </div>
            )}

            {/* Sending Messages (only if > 0) */}
            {sending > 0 && (
              <div id="sendingMetric" style={{ margin: '0.5rem', textAlign: 'center' }}>
                <div style={{ fontSize: '2rem', fontWeight: 'bold' }}>{sending}</div>
                <div style={{ fontSize: '0.9rem' }}>Sending Messages</div>
                <UncontrolledTooltip placement="top" target="sendingMetric">
                  Number of messages currently being sent.
                </UncontrolledTooltip>
              </div>
            )}

            {/* Delivery Rate (only if below 100%) */}
            {deliveryRate !== 'N/A' && parseFloat(deliveryRate) < 100 && (
              <div id="deliveryMetric" style={{ margin: '0.5rem', textAlign: 'center' }}>
                <div style={{ fontSize: '2rem', fontWeight: 'bold' }}>{deliveryRate}%</div>
                <div style={{ fontSize: '0.9rem' }}>Delivery Rate</div>
                <UncontrolledTooltip placement="top" target="deliveryMetric">
                  Percentage of messages successfully delivered.
                </UncontrolledTooltip>
              </div>
            )}
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

