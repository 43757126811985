import Head from "../../layout/head/Head"
import Content from "../../layout/content/Content"
import { Row, Col, Button } from "reactstrap"
import { Accordian, PreviewAltCard } from "../../components/Component"
import {
  SpecialTable,
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  BackTo,
  OrderTable,
  LoginLogTable,
} from "../../components/Component";
import {
  Card, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Modal,
  ModalHeader,
  ModalBody,
  ModalFooter, CardBody, CardSubtitle, CardTitle, CardLink, CardText
} from "reactstrap";
import { Icon } from "../../components/Component"
import BulkSmsFaq from "./components/BulkSmsFaq";
import { CampaignsTable } from "./components/CampaignsTable";
import { RecipientGroupsTable } from "./components/RecipientGroupsTable";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import BulkSMSChat from "./chat/BulkSMSChat";
import { ChatContextProvider } from "./chat/ChatContext";
import moment from "moment-timezone";
import { CampaignStats } from "./components/CampaignView";
import { CampaignConfig } from "./components/CampaignConfig";

function formatFuture(timestamp, timezone) {
  const now = new Date();
  const target = new Date(timestamp);
  const diff = target - now;

  // Relative formatting for very near-future events:
  if (diff < 1000) {
    return "in 1 second";
  }
  const seconds = Math.round(diff / 1000);
  if (seconds < 60) {
    return `in ${seconds} seconds`;
  }
  const minutes = Math.round(diff / (60 * 1000));
  if (minutes <= 10) {
    return `in ${minutes} minute${minutes === 1 ? '' : 's'}`;
  }

  // For events more than 10 minutes away, show an absolute time.
  // Convert the target timestamp to the destination timezone using Moment Timezone.
  const targetDest = moment.tz(target, timezone);
  const nowDest = moment.tz(now, timezone);

  // If the event is today in the destination timezone, display only the time:
  if (targetDest.isSame(nowDest, 'day')) {
    const destTime = targetDest.format("h:mm A");
    return `${destTime}`;
  } else {
    // For tomorrow or any later day, display date and time:
    const destFormatted = targetDest.format("MMM D, h:mm A");
    return `${destFormatted}`;
  }
}


function BulkSmsCampaign() {
  const { campaignId } = useParams();
  const token = Cookies.get('token');
  const navigate = useNavigate();
  const [campaign, setCampaign] = useState(null);
  const [bulkJob, setBulkJob] = useState(null);
  const [nextMessage, setNextMessage] = useState(null);

  useEffect(() => {
    if (!token) {
      navigate("/signup")
    }

  }, []);

  const nm = nextMessage ? { ...nextMessage, time: formatFuture(nextMessage.scheduled_time, bulkJob?.timezone || 'Asia/Bangkok') } : null;
  return (
    <>
      <Head title={campaign?.name || 'Unnamed campaign'} />

      <Content>
        <h4 style={{ marginBottom: 10 }}>This is your dashboard for "{campaign?.name || 'Unnamed campaign'}"</h4>
        <p style={{ marginBottom: 20 }}>Here you can monitor the campaign progress, individually communicate with leads, and group them based on their response for the future campaigns. </p>
        <Col>
          <Row style={{ display: 'flex', alignItems: 'stretch' }}>
            <Col lg="7" className="mb-4 mb-lg-0" >
              {(bulkJob && campaign) ? <CampaignStats campaign={campaign} bulkJob={bulkJob} nextMessage={nm}></CampaignStats> : null}
            </Col>
            <Col lg="5" className="mb-4 mb-lg-0">
              {(bulkJob && campaign) ? <CampaignConfig token={token} campaign={campaign} bulkJob={bulkJob} nextMessage={nm}></CampaignConfig> : null}
            </Col>
          </Row>

          <Row className="my-3">
            <Col lg="12" className="mb-lg-0" >
              <Card className="shadow-sm h-100">
                <CardBody style={{ padding: 1 }}>
                  <ChatContextProvider
                    token={token}
                    campaignId={campaignId}
                    campaign={campaign}
                    setCampaign={setCampaign}
                    bulkJob={bulkJob}
                    setBulkJob={setBulkJob}
                    setNextMessage={setNextMessage}
                  >
                    <BulkSMSChat></BulkSMSChat>
                  </ChatContextProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Col>
      </Content>
    </>
  )
}

export default BulkSmsCampaign;