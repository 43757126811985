import React, { useState, useEffect } from 'react';
import { Container, Card, CardBody, Form, FormGroup, Label, Input, Button, Table } from 'reactstrap';
import Head from '../../components/Head';
import { useNavigate } from 'react-router';
const SetupGHL = () => {
  const navigate = useNavigate(); // Hook for navigation

  // Replace with the real TextLink registration link
  const TextLinkRegisterAddr = "https://textlinksms.com/signup";

  // Local state for the callback response, errors, and selected location.
  const [callbackData, setCallbackData] = useState(null);
  const [error, setError] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);

  // New state to hold API keys entered by the user.
  // For multiple locations, companyApiKey is the fallback/master key.
  const [companyApiKey, setCompanyApiKey] = useState("");
  // locationApiKeys maps a location id to its entered API key.
  const [locationApiKeys, setLocationApiKeys] = useState({});
  // A message to display when the update call succeeds.
  const [submitMessage, setSubmitMessage] = useState("");

  useEffect(() => {
    // Run this effect only once on mount.

    // 1. Extract the code from the URL.
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    console.log('Code found in query params:', code);

    if (code) {
      const callbackUrl = global.SERVIP+`/crm1/auth/callback?code=${code}`;
      fetch(callbackUrl)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Fetch error: ${response.status} ${response.statusText}`);
          }
          return response.json();
        })
        .then((data) => {
          console.log('Callback response:', data);
          // The API returns { response: { company, locations, isSingleLocation } }
          setCallbackData(data.response);
          // Store callback data in localStorage.

          if (data.response.isSingleLocation) {
            const loc = data.response.locations[0];
            setSelectedLocation(loc);
            setLocationApiKeys((prev) => {
              if (!prev[loc.location_id] && loc.apiKey && loc.apiKey.api_key) {
                return { ...prev, [loc.location_id]: loc.apiKey.api_key };
              }
              return prev;
            });
          } else {
            data.response.locations.forEach((loc) => {
              setLocationApiKeys((prev) => {
                if (!prev[loc.location_id] && loc.apiKey && loc.apiKey.api_key) {
                  return { ...prev, [loc.location_id]: loc.apiKey.api_key };
                }
                return prev;
              });
            });
            if (data.response.company && data.response.company.apiKey && data.response.company.apiKey.api_key) {
              setCompanyApiKey(data.response.company.apiKey.api_key);
            }
          }
        })
        .catch((err) => {
          console.error('Error calling callback:', err);
          setError(err.message);
        });

    }
  }, []); // Empty dependency array to run this effect only once

  // For multi-location responses, update the selected location when the user chooses one.
  const handleLocationChange = (e) => {
    const locationId = e.target.value;
    if (callbackData && callbackData.locations) {
      const loc = callbackData.locations.find((l) => l.location_id === locationId);
      setSelectedLocation(loc);
      // Auto-fill API key if available.
      if (loc && loc.apiKey && loc.apiKey.api_key) {
        setLocationApiKeys((prev) => ({ ...prev, [loc.location_id]: loc.apiKey.api_key }));
      }
    }
  };

  // Update the API key for a specific location.
  const handleLocationApiKeyChange = (e, locationId) => {
    setLocationApiKeys((prev) => ({
      ...prev,
      [locationId]: e.target.value,
    }));
  };

  // Update the company master API key.
  const handleCompanyApiKeyChange = (e) => {
    setCompanyApiKey(e.target.value);
  };

  // On submission, build a payload and call the new endpoint.
  const handleApiKeysSubmit = (e) => {
    e.preventDefault();
    if (!callbackData) {
      setError("No callback data available.");
      return;
    }

    // Build the payload.
    const payload = {};

    // For multiple locations, include a company key if provided.
    if (!callbackData.isSingleLocation && companyApiKey && callbackData.company && callbackData.company.company_id) {
      payload.companyKey = {
        api_key: companyApiKey,
        companyid: callbackData.company.company_id,
      };
    }

    // Build an array of location keys.
    let locationKeys = [];
    if (callbackData.isSingleLocation) {
      const locId = callbackData.locations[0].location_id;
      locationKeys.push({
        location_id: locId,
        api_key: locationApiKeys[locId] || "",
      });
    } else {
      locationKeys = callbackData.locations.map((loc) => ({
        location_id: loc.location_id,
        api_key: locationApiKeys[loc.location_id] || "",
      }));
    }
    payload.locationKeys = locationKeys;

    // Call the new endpoint (/crm1/update_api_keys)
    fetch(global.SERVIP+`/crm1/auth/update_api_keys`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`Error: ${res.status} ${res.statusText}`);
        }
        return res.json();
      })
      .then((data) => {
        console.log("API Keys updated:", data);
        setSubmitMessage("API keys updated successfully.");
        // Remove callback data from localStorage after a successful update.
        localStorage.removeItem("ghlCallbackData");
        setTimeout(() => {
          navigate("/signup");
        }, 2000); // 2000 milliseconds = 2 seconds
      })
      .catch((err) => {
        console.error("Error updating API keys, try removing and installing the App again in ghl :", err);
        setError(err.message);
      });
  };

  return (
    <Container fluid style={styles.container}>
      <Head title="Integration API Key Connection" />

      <p style={styles.note}>
        Please Note: You need to register for a TextLink account{" "}
        <a
          href={TextLinkRegisterAddr}
          target="_blank"
          rel="noopener noreferrer"
          style={styles.link}
        >
          here
        </a>{" "}
       if you haven't already, before you can connect your CRM sub-accounts.
      </p>

      {submitMessage && <div style={styles.success}>{submitMessage}</div>}

      <Card style={styles.card}>
        <CardBody>
          {/* Show loading message until callbackData is available */}
          {callbackData ? (
            <>
              {callbackData.isSingleLocation ? (
                <>
                  {/* Single Location Section */}
                  <div style={styles.locationHeader}>
                    <div style={styles.locationId}>
                      [location ID] {callbackData.locations[0].location_id}
                    </div>
                    <div style={styles.locationName}>
                      [Name] {callbackData.locations[0].name}
                    </div>
                  </div>
                  <p style={styles.instructions}>
                    Please copy the API key from the TextLink subaccount you wish to connect to this GHL location.
                    Note that this location will have access only to the numbers and contacts of the connected subaccount.
                    You will later be able to manage this in your admin dashboard.
                  </p>
                  <Form onSubmit={handleApiKeysSubmit}>
                    <FormGroup>
                      <Label for="locationApiKey">TextLink sub-account API Key for this location</Label>
                      <Input
                        type="text"
                        id="locationApiKey"
                        placeholder="Enter API Key"
                        value={locationApiKeys[callbackData.locations[0].location_id] || ""}
                        onChange={(e) =>
                          handleLocationApiKeyChange(e, callbackData.locations[0].location_id)
                        }
                      />
                    </FormGroup>
                    <Button color="primary" style={styles.button}>
                      Save API Key
                    </Button>
                  </Form>
                </>
              ) : (
                <>
                  {/* Multiple Locations Section */}

                  <div style={{ textAlign: 'left' }}>
                    <p style={{ ...styles.instructions, fontWeight: 'bold', textAlign: 'left' }}>
                      You've installed TextLink across multiple locations—important things to know:
                    </p>

                    <p style={{ ...styles.instructions, textAlign: 'left' }}>
                      <strong>You can only install TextLink on 10 locations at a time.</strong> If you have more than 10 locations, some might not appear yet. You’ll need to install them in batches of 10.
                    </p>

                    <p style={{ ...styles.instructions, textAlign: 'left' }}>
                      Connect each location to its own TextLink subaccount using dedicated API keys. Go to your dashboard and generate an API key for each location’s subuser.
                    </p>

                    <p style={{ ...styles.instructions, textAlign: 'left' }}>
                      Note: Locations without dedicated API keys will share access to all contacts and numbers. To keep data isolated, set individual API keys for each location.
                    </p>

                    <Button
                      color="primary"
                      style={{ marginTop: '15px', marginBottom: '20px' }}
                      onClick={() => window.open('https://youtu.be/HYOGQ1l3dzA', '_blank')}
                    >
                      Watch Setup Tutorial
                    </Button>
                  </div>


                  <Form onSubmit={handleApiKeysSubmit}>
                    <Table striped>
                      <thead>
                        <tr>
                          <th>Location ID</th>
                          <th>Name</th>
                          <th>API Key</th>
                        </tr>
                      </thead>
                      <tbody>
                        {callbackData.locations.map((loc) => (
                          <tr key={loc.location_id}>
                            <td>{loc.location_id}</td>
                            <td>{loc.name}</td>
                            <td>
                              <Input
                                type="text"
                                placeholder="Enter API Key"
                                value={locationApiKeys[loc.location_id] || ""}
                                onChange={(e) => handleLocationApiKeyChange(e, loc.location_id)}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>

                    <Button color="primary" style={styles.button}>
                      Save API Keys
                    </Button>
                  </Form>
                </>
              )}
            </>
          ) : (
            <div>Loading...</div>
          )}
        </CardBody>
      </Card>
    </Container>
  );
};

const styles = {
  container: {
    minHeight: '100vh',
    padding: '20px',
    background: 'linear-gradient(to bottom right, #ffffff, #e3f2fd)',
  },
  note: {
    textAlign: 'center',
    marginBottom: '20px',
    fontSize: '0.95rem',
    color: '#333',
  },
  link: {
    color: '#007bff',
    textDecoration: 'underline',
  },
  card: {
    maxWidth: '800px',
    margin: '0 auto',
    borderRadius: '8px',
    border: 'none',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
  },
  locationHeader: {
    textAlign: 'center',
    marginBottom: '20px',
  },
  locationId: {
    fontWeight: 'bold',
    fontSize: '1rem',
  },
  locationName: {
    color: '#6c757d',
    fontSize: '0.9rem',
  },
  instructions: {
    textAlign: 'center',
    fontSize: '0.85rem',
    color: '#333',
    marginBottom: '20px',
    padding: '0 10px',
  },
  button: {
    width: '100%',
  },
  error: {
    color: 'red',
    textAlign: 'center',
    marginBottom: '10px',
  },
  success: {
    color: 'green',
    textAlign: 'center',
    marginBottom: '10px',
  },
  masterKeySection: {
    marginTop: '20px',
    paddingTop: '20px',
    borderTop: '1px solid #ccc',
  },
  masterKeyLabel: {
    fontWeight: 'bold',
    marginBottom: '10px',
    display: 'block',
  },
  masterKeyText: {
    marginBottom: '10px',
  },
};

export default SetupGHL;
