import React from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { Block, BlockHead, BlockHeadContent, BlockTitle, BlockDes } from "../../components/Component";
import { Card, CardBody, CardTitle, CardText, Button, Row, Col } from "reactstrap";
import { useNavigate } from "react-router";

const Tutorials = () => {
    const navigate = useNavigate();
    const tutorials = [
        {
            title: "TextLink Setup Tutorial",
            description: "Learn how to set up TextLink on your Android device and start sending SMS seamlessly.",
            videoUrl: "https://www.youtube.com/embed/nyVm4U5iScg",
            serviceUrl: "/dashboard/your-devices/get-started"
        },
        {
            title: "Bulk SMS Playlist",
            description: "Discover how to use TextLink's Bulk SMS tool to send messages efficiently.",
            videoUrl: "https://www.youtube.com/embed/videoseries?si=c4E8agUKN3A3jGAy&amp;list=PLijvTaHjnix_tMkjRKy_BidMtlz3xfs6Q",
            serviceUrl: "/dashboard/bulksms-create"
        },
        {
            title: "Subaccount system explanation",
            description: "Learn how to efficiently manage your team and how to control subaccount limits.",
            videoUrl: "https://www.youtube.com/embed/fPUEaO963aA",
            serviceUrl: "/dashboard/team"
        },
        {
            title: "TextLink and GHL",
            description: "Integrate TextLink with GoHighLevel to automate and enhance your messaging workflows.",
            videoUrl: "https://www.youtube.com/embed/iEQxln7P_Uc"
        },
        {
            title: "TextLink and Make.com",
            description: "Learn how to use TextLink with Make.com to create powerful automation scenarios for your business.",
            videoUrl: "https://www.youtube.com/embed/2OU3KNbn9PY"
        },
        {
            title: "TextLink and Zapier",
            description: "Integrate TextLink with Zapier to automate and enhance your messaging workflows.",
            videoUrl: "https://www.youtube.com/embed/Owc1V5n9MUE"
        },
    ];

    return (
        <>
            <Head title="Tutorials" />
            <Content>
                {/* Header Section */}
                <Block size="md">
                    <BlockHead>
                        <BlockHeadContent>
                            <BlockTitle tag="h5">Tutorials</BlockTitle>
                            <BlockDes>
                                <p>Explore these step-by-step video tutorials to make the most out of TextLink's features.</p>
                                <br />
                            </BlockDes>
                        </BlockHeadContent>
                    </BlockHead>
                </Block>

                {/* Tutorials Section */}
                <Row className="g-4">
                    {tutorials.map((tutorial, index) => (
                        <Col lg="5" md="12" key={index} className="mb-4">
                            <Card className="card-bordered shadow-sm h-100" style={{ borderRadius: "12px", overflow: "hidden" }}>
                                <div className="ratio ratio-16x9">
                                    <iframe
                                        src={tutorial.videoUrl}
                                        title={tutorial.title}
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                        className="card-img-top"
                                        style={{ borderRadius: "0" }}
                                    ></iframe>
                                </div>
                                <CardBody className="card-inner d-flex flex-column">
                                    <CardTitle tag="h4" className="mb-2">{tutorial.title}</CardTitle>
                                    <CardText className="mb-4">{tutorial.description}</CardText>
                                    {tutorial.serviceUrl && (
                                        <div className="mt-auto text-center">
                                            <Button
                                                color="primary"
                                                href={tutorial.serviceUrl}
                                                target="_blank"
                                                className="w-100"
                                                onClick={() => navigate(tutorial.serviceUrl)}
                                            >
                                                Go to Service
                                            </Button>
                                        </div>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Content>
        </>
    );
};

export default Tutorials;
