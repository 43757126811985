import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const AffiliatePage = () => {
  // Extract the `code` parameter from the URL
  const { code } = useParams();

  // Hook for programmatic navigation
  const navigate = useNavigate();

  useEffect(() => {
    // Store the affiliate code in localStorage
    if (code) {
      localStorage.setItem('affiliateCode', code);
    }

    // Navigate to the signup page immediately
    navigate('/signup', { state: { code: code } });
}, [code, navigate]);

  return (
    <div>
      <h1>Redirecting to Signup...</h1>
      <p>Please wait while we process your referral.</p>
    </div>
  );
};

export default AffiliatePage;