import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Icon } from "../../../../components/Component";
import { DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import QRCode from "react-qr-code";
import './UserProfileAside.css';

const UserProfileAside = ({ updateSm, sm }) => {
  const [profileName, setProfileName] = useState("Abu Bin Ishtiak");

  useEffect(() => {
    sm ? document.body.classList.add("toggle-shown") : document.body.classList.remove("toggle-shown");
  }, [sm]);

  return (
    <div className="card-inner-group">
      <div className="card-inner p-0">
        <ul className="link-list-menu">
          <li onClick={() => updateSm(false)}>
            <Link
              to={`${process.env.PUBLIC_URL}/dashboard/your-devices/get-started`}
              className={
                window.location.pathname === `${process.env.PUBLIC_URL}/dashboard/your-devices/get-started` ? "active" : ""
              }
            >
              <Icon name="user-fill-c"></Icon>
              <span>Explanation</span>
            </Link>
          </li>
          <li onClick={() => updateSm(false)}>
            <Link
              to={`${process.env.PUBLIC_URL}/dashboard/your-devices/step-by-step`}
              className={
                window.location.pathname === `${process.env.PUBLIC_URL}/dashboard/your-devices/step-by-step` ? "active" : ""
              }
            >
              <Icon name="bell-fill"></Icon>
              <span>Step by step guide</span>
            </Link>
          </li>
          <li onClick={() => updateSm(false)}>
            <Link
              to={`${process.env.PUBLIC_URL}/dashboard/your-devices/self-service-video`}
              className={
                window.location.pathname === `${process.env.PUBLIC_URL}/dashboard/your-devices/self-service-video`
                  ? "active"
                  : ""
              }
            >
              <Icon name="activity-round-fill"></Icon>
              <span>Video tutorial</span>
            </Link>
          </li>
          {/* New Button for Recommended SIM Plans */}
          <li onClick={() => updateSm(false)}>
            <a
              href="https://docs.google.com/spreadsheets/d/1ts7dP3Cn2cAGCDGnIZ97nYsZSRCl5YKCcHvWB_HxQE8/edit?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
              className="recommended-sim-plans"
            >
              <Icon name="info"></Icon>
              <span>Recommended SIM Plans</span>
            </a>
          </li>
        </ul>
      </div>

      {/* QR Code Section */}
      <div className="qr-section">
        <h4 className="qr-title">Download TextLink App Here</h4>
        <div className="qr-code-container">
          <QRCode
            value="https://textlinksms.com/textlink.apk"
            size={200}
            bgColor="#ffffff"
            fgColor="#000000"
            level="H"
          />
        </div>
        <p className="qr-text">Scan the QR code or visit the link below:</p>
        <a
          href="https://textlinksms.com/textlink.apk"
          target="_blank"
          rel="noopener noreferrer"
          className="qr-link"
        >
          https://textlinksms.com/textlink.apk
        </a>
      </div>
    </div>
  );
};

export default UserProfileAside;