import React, { useState, useEffect } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import {
    Card, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Modal,
    ModalHeader,
    ModalBody,
    ModalFooter, CardBody, CardSubtitle, CardTitle, CardLink, CardText
  } from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
  TooltipComponent
} from "../../components/Component";
import { useNavigate } from "react-router";

const SubUserDashPage = () => {
const navigate = useNavigate()
  return (
    <>
      <Head title="Balance and overview" />
      <Content>
        <BlockHead size="sm">
          <div className="nk-block-between">
            <BlockHeadContent>
              <BlockTitle page tag="h3">
                Welcome
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>As a user that is a part of an organisation you have access to the following tools.</p>
              </BlockDes>
            </BlockHeadContent>
         
          </div>
        </BlockHead>
        <Block>
        <Row className="g-gs">
          <Col lg="6" xxl="6" className="mb-3 mb-md-0">
              <PreviewAltCard className="h-100">
              <Card className="card h-100" >
                <CardBody className="card-inner">
                  <CardTitle tag="h5">Chat app (For Trucking Dispathers, Custumer Relations people, ...) </CardTitle>
                  <br />
                  <p tag="h6" className="mb-2 ff-base">
                    This is a simple web app that you can use to send messages, create contacts,... 
                    Everything you need to talk to your employees and customers
                  </p>
                  <br />
                  <p tag="h6" className="mb-2 ff-base">
                    Just like on your phone, but online
                  </p>
                  <Button className="btn-dim" outline color="primary" style={{marginBottom:10, marginTop:5, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center",width:'100%'}}
                  onClick={()=>navigate("/dashboard/chat")}>
            <Icon name="chat-circle" style={{ marginRight: 3 }} />
              <a>Go To Chat App</a>
              </Button>
                  <br />

                </CardBody>
              </Card>
              </PreviewAltCard>
          </Col>
      
      
          <Col lg="6" xxl="6" className="mb-3 mb-md-0">
              <PreviewAltCard className="h-100">
              <Card className="card h-100" >
                <CardBody className="card-inner">
                  <CardTitle tag="h5">No-Code Bulk SMS </CardTitle>
                  <br />
                  <p tag="h6" className="mb-2 ff-base">
                    Simple, zero code tool to send large number of customized messages to your users or potential clients.
                    If you are familiar with Smartlead, this is it, just for messages.
                  </p>
                  <br />
                  <p tag="h6" className="mb-2 ff-base">
                    Get started now
                  </p>
                  <Button className="btn-dim" outline color="primary" style={{marginBottom:10, marginTop:5, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center",width:'100%'}}
                  onClick={()=>navigate("/dashboard/bulksms-dashboard")}>
            <Icon name="user-c" style={{ marginRight: 3 }} />
              <a>Go To BulkSMS Dashboard</a>
              </Button>
              
                  <br />

                </CardBody>
              </Card>
              </PreviewAltCard>
          </Col>
         
           
          
        </Row>
      </Block>
      
      </Content>
    </>
  );
};

export default SubUserDashPage;