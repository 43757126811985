import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap";
import OrderActivity from "./components/order-activity/OrderActivity";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import DevicesTable from "../../Sell/SellingDashboard/components/DevicesTable";
import { useNavigate } from "react-router";
import { Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";
import { Icon } from "../../../components/Component";
import SubscriptionsCard from "../../Analytics/SubscriptionsCard";
import Swal from "sweetalert2";

function YourDevicesService() {
  const [modalShown, setModalShown] = useState(false);
  const [currentDevice, setCurrentDevice] = useState({});
  const [devicesData, setDevicesData] = useState([]);
  const [currentSims, setCurrentSims] = useState([]);
  const [userSubscriptions, setUserSubscriptions] = useState([]);

  const navigate = useNavigate();

  async function showSimsForDevice(item) {
    setModalShown(!modalShown);

    if (item) {
      setCurrentDevice(item);
      await fetchDeviceSims(item.Id);
    }
  }

  const fetchDeviceSims = async (deviceId) => {
    try {
      const response = await fetch(global.SERVIP + `/device/get-sims/${deviceId}`); // Adjust the URL as needed
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setCurrentSims(data.simCards);
      return data;
    } catch (error) {
      console.error("Error fetching device SIM cards:", error);
    }
  };

  const deleteDevice = async (id) => {
    const result = await Swal.fire({
      title: "Delete device",
      text: `Are you sure that you want to delete "Device ${id}"? This action can not be undone. `,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });

    if (!result.isConfirmed) {
      return;
    }

    const token = Cookies.get("token");
    const url = `${global.SERVIP}/user/delete-device`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ id }),
      });

      if (!response.ok) {
        console.log(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      if (data.ok) {
        setDevicesData((prevDevicesData) => prevDevicesData.filter((e) => e.id !== id));
        Swal.fire({ title: "Success", text: "Device successfully deleted", icon: "success" });
      } else {
        Swal.fire({ title: "Error", text: "Could not delete device", icon: "error" });
      }
    } catch (error) {
      console.error("Could not delete device:", error);
      Swal.fire({ title: "Error", text: "Could not delete device", icon: "error" });
    }
  };

  const fetchUserDevices = async () => {
    const token = Cookies.get("token"); // Get the token from cookies
    const url = `${global.SERVIP}/user/get_user_devices_info`; // Update the endpoint URL

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Set the authorization header
        },
      });

      if (!response.ok) {
        console.log(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setDevicesData(data.devices);
      // You can set state or process data here as needed
      return data;
    } catch (error) {
      console.error("Error fetching user devices:", error);
      throw error;
    }
  };

  const fetchSubscriptions = async () => {
    const token = Cookies.get("token"); // Get the token from cookies
    const url = `${global.SERVIP}/user/get_subscriptions`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Set the authorization header
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setUserSubscriptions(data.subscriptions);
      return data;
    } catch (error) {
      console.error("Error fetching messages by month:", error);
      throw error;
    }
  };

  const fetchAllData = async () => {
    await fetchSubscriptions();
    await fetchUserDevices();
  };

  useEffect(() => {
    fetchAllData();
  }, []);

  return (
    <>
      <Head title="Your devices dashboard" />
      <Content>
        <h3>Your own service/devices overview</h3>
        <p style={{ margin: 10 }}>Manage your devices, including setting the countries they send messages to</p>
        <Col>
          <Row className="my-3">
            <Col className="mb-4 mb-lg-0">
              <Card>
                <CardBody>
                  <DevicesTable deleteDevice={deleteDevice} showSimsForDevice={showSimsForDevice} devicesData={devicesData} />
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <SubscriptionsCard subscriptions={userSubscriptions} selling={false} />
                </CardBody>
              </Card>
            </Col>

            <Col lg={6} xs={12}>
              <Card className="card h-100">
                <h4 style={{ margin: "5px" }}>Setup tutorial</h4>
                <CardBody>
                  <iframe
                    width="100%"
                    height="300"
                    src="https://www.youtube.com/embed/nyVm4U5iScg?si=82H9FQGli8gPlusj"
                    title="YouTube video"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                  {/* New Button for Recommended SIM Plans */}
                  <Button
                    color="dark"
                    outline
                    className="mt-3 w-100"
                    onClick={() =>
                      window.open(
                        "https://docs.google.com/spreadsheets/d/1ts7dP3Cn2cAGCDGnIZ97nYsZSRCl5YKCcHvWB_HxQE8/edit?usp=sharing",
                        "_blank"
                      )
                    }
                  >
                    <Icon name="sim-card" className="me-2" />
                    View Recommended SIM Plans
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
        <Modal isOpen={modalShown} toggle={() => setModalShown(false)}>
          <ModalHeader
            toggle={() => setModalShown(false)}
            close={
              <button className="close" onClick={() => setModalShown(false)}>
                <Icon name="cross" />
              </button>
            }
          >
            {currentDevice.name}
          </ModalHeader>
          <ModalBody>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Provider</th>
                  <th scope="col">Country</th>
                  {/* Include other columns as needed */}
                  <th scope="col">Actions</th> {/* Added Actions column */}
                </tr>
              </thead>
              <tbody>
                {currentSims.map((sim, index) => (
                  <tr key={index}>
                    {/* Removed onClick from <tr> */}
                    <th scope="row">{sim.id}</th>
                    <td>{sim.name}</td>
                    <td>{sim.sourceCountry}</td>
                    {/* Include other data as needed */}
                    <td>
                      <button
                        onClick={() => navigate("/dashboard/edit/sim", { state: { id: sim.id } })}
                        className="btn btn-primary btn-sm"
                      >
                        Configure
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <p>
              This is the list of your SIM cards for {currentDevice.name ? currentDevice.name : "this device"}, select which
              one you want to edit (add/remove countries or change content type).
            </p>
          </ModalBody>
          <ModalFooter className="bg-light">
            <span className="sub-text">
              <a href="https://youtu.be/nyVm4U5iScg" target="_blank" style={{ color: "blue" }}>
                Quick tutorial
              </a>
            </span>
          </ModalFooter>
        </Modal>
      </Content>
    </>
  );
}

export default YourDevicesService;