import { Link, useNavigate } from "react-router-dom"; // Import useNavigate
import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import './HomeAbout3.css'; // Import the CSS file for this component

export default function HomeAbout3() {
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false); // Modal state for first image
  const [isModalOpen2, setIsModalOpen2] = useState(false); // Modal state for second image
  const [isHovered1, setIsHovered1] = useState(false); // Hover state for first image
  const [isHovered2, setIsHovered2] = useState(false); // Hover state for second image

  const navigate = useNavigate(); // Initialize the navigate function

  const openModal = () => setIsOpen(!isOpen);

  const openImageModal1 = () => {
    setIsModalOpen1(true);
  };

  const openImageModal2 = () => {
    setIsModalOpen2(true);
  };

  const closeImageModal1 = () => {
    setIsModalOpen1(false);
  };

  const closeImageModal2 = () => {
    setIsModalOpen2(false);
  };

  const handleMouseEnter1 = () => {
    setIsHovered1(true);
  };

  const handleMouseLeave1 = () => {
    setIsHovered1(false);
  };

  const handleMouseEnter2 = () => {
    setIsHovered2(true);
  };

  const handleMouseLeave2 = () => {
    setIsHovered2(false);
  };

  // Function to handle tool card clicks
  const handleToolClick = (path) => {
    navigate(path); // Navigate to the specified path
  };

  return (
    <section id="about" className="about-us-area pt-155 pb-30 pt-md-60 pb-md-30 pt-xs-60 pb-xs-30">
      <div className="container">
        <div className="row">
          {/* Tools Section */}
          <div className="col-lg-7">
            <div className="about-text-wrapper mb-30">
              <h2 className="style-text">Tools</h2>
              <h2 className="sect-title">Tools That We Offer</h2>

              {/* Tool Cards */}
              <div className="tool-card" onClick={() => handleToolClick('/web-sms-app')}>
                <div className="tool-icon">
                  <i className="fas fa-comments"></i> {/* Example icon */}
                </div>
                <div className="tool-content">
                  <h3>Web Messaging App</h3>
                  <p className="paragraph-title" style={{ color: 'black' }}>
                    Easily communicate with your clients and employees using our web chat. You can receive messages, create and customize contacts, and send messages that deliver instantly.
                  </p>
                </div>
              </div>

              <div className="tool-card" onClick={() => handleToolClick('/bulksms')}>
                <div className="tool-icon">
                  <i className="fas fa-bullhorn"></i> {/* Example icon */}
                </div>
                <div className="tool-content">
                  <h3>Bulk SMS Campaigns</h3>
                  <p className="paragraph-title" style={{ color: 'black' }}>
                    Use our no-code solution to send bulk messages to your customers. Upload an Excel or CSV file, craft personalized messages, or use our Bulk SMS API for advanced workflows.
                  </p>
                </div>
              </div>

              <div className="tool-card" onClick={() => handleToolClick('/smsapi')}>
                <div className="tool-icon">
                  <i className="fas fa-exchange-alt"></i> {/* Example icon */}
                </div>
                <div className="tool-content">
                  <h3>Two-Way SMS API</h3>
                  <p className="paragraph-title" style={{ color: 'black' }}>
                    Verify users, send messages, and receive messages via webhooks. Our API is simple to set up, with tutorials and packages for JS, Python, C#, and more.
                  </p>
                </div>
              </div>

              {/* GHL Integration Tool Card */}
              <div className="tool-card">
                <div className="tool-icon">
                  <i className="fas fa-cogs"></i> {/* Example icon */}
                </div>
                <div className="tool-content">
                  <h3>GHL (GoHighLevel) Integration</h3>
                  <p className="paragraph-title" style={{ color: 'black' }}>
                    Seamlessly integrate TextLink with GoHighLevel to automate your SMS workflows. Sync contacts, trigger SMS campaigns, and manage conversations directly from your GHL dashboard.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Image Section */}
          <ModalVideo channel='youtube' isOpen={isOpen} videoId='-gajhLRMYIE' onClose={openModal} />
          <div className="col-lg-5">
            <div className="about-video-wrapper mb-30">
              {/* First Image with Modal */}
              <div className="image-container" style={{ marginBottom: "40px" }}>
                <img 
                  className="img-one hover-zoom" 
                  src="assets/img/about/bulkDashboard.png"  
                  alt="Bulk Dashboard Img" 
                  onMouseEnter={handleMouseEnter1}
                  onMouseLeave={handleMouseLeave1}
                  onClick={openImageModal1}
                  style={{ 
                    cursor: 'pointer',
                    transform: isHovered1 ? 'scale(1.05)' : 'scale(1)',
                    transition: 'transform 0.3s ease-in-out',
                    border: isHovered1 ? '2px solid #000' : 'none',
                    width: '100%',
                    borderRadius: '10px',
                  }} 
                />
              </div>

              {/* Second Image with Modal */}
              <div className="image-container">
                <img 
                  className="img-one hover-zoom" 
                  src="assets/img/about/chat.png" 
                  alt="Chat Img"
                  onMouseEnter={handleMouseEnter2}
                  onMouseLeave={handleMouseLeave2}
                  onClick={openImageModal2}
                  style={{ 
                    cursor: 'pointer',
                    transform: isHovered2 ? 'scale(1.05)' : 'scale(1)',
                    transition: 'transform 0.3s ease-in-out',
                    border: isHovered2 ? '2px solid #000' : 'none',
                    width: '100%',
                    borderRadius: '10px',
                  }} 
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modals for Images */}
      {isModalOpen1 && (
        <div 
          className="image-modal-overlay"
          onClick={closeImageModal1}
        >
          <img 
            src="assets/img/about/bulkDashboard.png" 
            alt="Enlarged Bulk Dashboard Img" 
            className="image-modal-content"
          />
        </div>
      )}

      {isModalOpen2 && (
        <div 
          className="image-modal-overlay"
          onClick={closeImageModal2}
        >
          <img 
            src="assets/img/about/chat.png" 
            alt="Enlarged Chat Img" 
            className="image-modal-content"
          />
        </div>
      )}
    </section>
  );
}