import React from "react";
import { Card, CardBody, Button } from "reactstrap";
import Cookies from "js-cookie";
import '../styles/ToggleSwitch.css';
import { useNavigate } from "react-router";

function SubscriptionComponent({ item, usersSubscription, yearlyPlan }) {
  const token = Cookies.get('token');
  const navigate = useNavigate();

  // Enhanced card style for the "Pro" plan
  const cardStyle = {
    position: 'relative',  // To position the badge absolutely
    borderRadius: '16px',
    boxShadow:  '0 2px 4px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
    fontSize: '0.75rem',
    height: '330px',
    display: 'flex', 
    flexDirection: 'column', 
    justifyContent: 'space-between',
    border: item.name === 'Pro' ? '1px solid black' : '1px solid #e0e0e0', // Highlight border for Pro
    backgroundColor: '#fff', // Light blue background for Pro
  };

  const titleStyle = {
    fontSize: '1.3rem',
    fontWeight: '700',
    color:  '#333', // Blue color for Pro title
    marginBottom: '0.7rem',
  };

  const priceStyle = {
    fontSize: '1.4rem',
    fontWeight: '400',
    color: '#333',
  };

  const featureListStyle = {
    listStyle: 'none',
    paddingLeft: '0',
    marginTop: '10px',
    flexGrow: 1 
  };

  const featureItemStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '5px',
  };

  const buttonStyle = {
    width: '100%',
    padding: '5px',
    fontSize: '0.9rem',
    marginTop: '10px',
    display: 'block',
  };

  const choosePlanButtonStyle = {
    ...buttonStyle,
    backgroundColor: item.name === 'Pro' ? '#98FB98' : '#98FB98', // Blue button for Pro
    color: item.name === 'Pro' ? '#005f00' : '#005f00', // White text for Pro button
    borderColor:'#98FB98',
    height: '50px',
  };

  // Badge style for the "Recommended" tag
  const badgeStyle = {
    position: 'absolute',
    top: '10px',
    right: '10px',
    backgroundColor:  'black', // Blue badge for Pro
    color: '#fff',
    padding: '5px 10px',
    borderRadius: '8px',
    fontSize: '0.8rem',
    fontWeight: 'bold',
    zIndex: 1,
  };

  function calculatePricePerMessage() {
    if (item.message_limit === 0) return 0;
    return yearlyPlan ? (item.price / item.message_limit * 0.8304) : (item.price / item.message_limit);
  }

  function calculateYearlyPrice() {
    if (yearlyPlan) {
      if (item.price == 25)
        return (249.00 / 12 + 0.02).toFixed(2)
      if (item.price == 45)
        return (448.00 / 12 + 0.02).toFixed(2)
      else if (item.price == 95)
        return (946.00 / 12 + 0.02).toFixed(2)
      else if (item.price == 195)
        return (1942.00 / 12 + 0.02).toFixed(2)
      else
        return "0.00";
    } else {
      return item.price.toFixed(2);
    }
  }

  const buyPlan = async (lookup_key, yearly, view) => {
    try {
      const url = `${global.SERVIP}/payment/${view ? 'view-plan' : 'buy-plan'}`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ lookup_key, yearly })
      });

      if (!response.ok) throw new Error('Network response was not ok.');

      const data = await response.json();
      if (!data.ok) throw new Error('Error from server.');

      window.location.assign(data.url);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Card style={cardStyle}>
      {/* Conditionally render the "Recommended" badge if item.name is "PRO" */}
      {item.name === 'Pro' && <div style={badgeStyle}>Most Used</div>}
      <CardBody style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <div style={titleStyle}>{item.name}</div>
        <div style={priceStyle}>{calculateYearlyPrice()} USD/Month</div>
        <ul style={featureListStyle}>
          <li style={featureItemStyle}>
            <span>Monthly SMS Quota</span>
            <span>{item.message_limit === 0 ? "Unlimited" : item.message_limit}</span>
          </li>
          <li style={featureItemStyle}>
            <span>Price per Message</span>
            <span>${calculatePricePerMessage().toFixed(5)}</span>
          </li>
          <li style={featureItemStyle}>
            <span>Yearly Price</span>
            <span>${(calculateYearlyPrice() * 12).toFixed(0)}</span>
          </li>
        </ul>

        {usersSubscription.lookup_key === item.lookup_key ? (
          <Button style={choosePlanButtonStyle} onClick={() => buyPlan(item.lookup_key, yearlyPlan, true)}>Manage your subscription</Button>
        ) : (
          <Button style={choosePlanButtonStyle} onClick={() => buyPlan(item.lookup_key, yearlyPlan)}>Choose {yearlyPlan ? 'Yearly' : 'Monthly'} {item.name} Plan</Button>
        )}
      </CardBody>
    </Card>
  );
}

export default SubscriptionComponent;