import React from "react";
import Button from "react-bootstrap/esm/Button";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { Tooltip } from "react-tooltip"; // Import the Tooltip component

const WithdrawButton = ({ analyticsData }) => {
  const requestPayout = async () => {
    try {
      const url = `${global.SERVIP}/user/request-payout`;
      const jwtToken = Cookies.get("token");

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      if (data.ok) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Payout request sent successfully!",
        });
      } else {
        throw new Error(data.message || "Failed to request payout.");
      }
    } catch (error) {
      console.error("Error requesting payout:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to request payout. Please try again.",
      });
    }
  };

  if (!analyticsData) {
    return null; // Return null if analyticsData is not available
  }

  const isDisabled = analyticsData.withdrawableEarnings < 50;

  return (
    <>
      {/* Wrap the button in a div and attach the tooltip to the wrapper */}
      <div
        data-tooltip-id="withdraw-tooltip" // Add tooltip ID to the wrapper
        data-tooltip-content="Earn $50 or more in commissions to withdraw" // Tooltip content
      >
        <Button
          color="primary"
          className="btn-dim mt-2"
          onClick={requestPayout}
          disabled={isDisabled}
        >
          Withdraw Now
        </Button>
      </div>

      {/* Conditionally render the tooltip only if the button is disabled */}
      {isDisabled && (
        <Tooltip
          id="withdraw-tooltip" // Match the ID used in the wrapper
          place="top" // Position of the tooltip
          effect="solid" // Tooltip effect
        />
      )}
    </>
  );
};

export default WithdrawButton;