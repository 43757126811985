import React from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import "./AffiliateProgram.css"; // Import the CSS file for styling
import FooterOne from "../../footer/FooterOne";
import Head from "../../components/Head";
import HeaderOne from "../../header/HeaderOne";
import { useNavigate } from "react-router-dom"; // Assuming you're using React Router for navigation

const AffiliateProgram = () => {
  const navigate = useNavigate(); // Hook for navigation

  return (
    <>
      <Head title="TextLink Affiliate Program" />
      <HeaderOne />

      <section className="about-us-area pt-145 pb-90 pt-md-60 pb-md-30 pt-xs-60 pb-xs-30">
        <div className="affiliate-program">
          {/* Description Section */}
          <section className="description">
            <h1>TextLink Affiliate Program</h1>
            <p>
              Earn <strong>20%</strong> of each registered user's monthly subscription as long as they are using the service. Here's how it works:
            </p>
          </section>

          {/* Step-by-Step Section */}
          <section className="steps">
            <div className="step-card">
              <div className="step-number">1</div>
              <div className="step-content">
                <h2>Share Your Link</h2>
                <p>
                  Share your unique affiliate link with friends, colleagues, or on social media. You can find your link after signing up for the affiliate program.
                </p>
              </div>
            </div>
            <div className="step-card">
              <div className="step-number">2</div>
              <div className="step-content">
                <h2>Refer Users</h2>
                <p>
                  When someone signs up using your link, they become your referral. They need to create a TextLink admin account to complete the registration.
                </p>
              </div>
            </div>
            <div className="step-card">
              <div className="step-number">3</div>
              <div className="step-content">
                <h2>Earn Commissions</h2>
                <p>
                  You earn <strong>20%</strong> of their monthly subscription fees for as long as they remain a customer. Withdraw your earnings once they exceed <strong>$50</strong>.
                </p>
              </div>
            </div>
          </section>

          {/* Call to Action Section */}
          <section className="cta">
            <h2>Ready to Start Earning?</h2>
            <p>Join the TextLink Affiliate Program today and start earning passive income.</p>
            <button className="cta-button" onClick={() => navigate("/signup")}>
              <span>Sign Up Now</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="cta-icon"
              >
                <path d="M5 12h14M12 5l7 7-7 7" />
              </svg>
            </button>
          </section>
        </div>
      </section>
      <br />
      <FooterOne />
    </>
  );
};

export default AffiliateProgram;