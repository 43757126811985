import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export default function Home_banner() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <>
      <div id="slider-one" className={`main-slider-area ${loaded ? 'fade-in' : ''}`} >
        <div className="slider-bg" >
          <div className="item">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="slider__content">
                    <h1 className="main-title-one">
                      Send SMS Through Your Own Devices <span className="gradient-text">For the Best Rates on the Market</span>
                    </h1>
                    <h2 className="sub-title-one">Like SmartLead.ai, but for SMS</h2>

                    <p className="aftertitle-p">
                      Scale your SMS communication without breaking the bank.<br className="d-none d-md-block" />
                      With your own SIMs and devices, you get the most affordable rates and full control over your messaging.
                    </p>

                    <div className="features-container">
                      <div className="features-grid">
                        <div className="feature-card">
                          <div className="feature-icon">💸</div>
                          <div className="feature-content">
                            <h3>Up to 95% Cheaper</h3>
                            <p>Beat Twilio & competitors, no A2P registration</p>
                          </div>
                        </div>
                        <div className="feature-card">
                          <div className="feature-icon">
                            <img
                              src="https://app.matatika.com/assets/logos/extractors/gohighlevel.png"
                              alt="GHL Integration"
                              className="ghl-logo"
                            />
                          </div>
                          <a href="/ghl" style={{ textDecoration: 'none', color: 'inherit' }}>
                            <div className="feature-content" style={{ cursor: 'pointer' }}>
                              <h3>GHL Integration</h3>
                              <p>Native Conversation Provider Integration</p>
                            </div>
                          </a>

                        </div>
                        <div className="feature-card">
                          <div className="feature-icon">🔓</div>
                          <div className="feature-content">
                            <h3>No Restrictions</h3>
                            <p>Send any content type, including links</p>
                          </div>
                        </div>

                        <div className="feature-card">
                          <div className="feature-icon">⚡</div>
                          <div className="feature-content">
                            <h3>1 SIM, multiple accounts</h3>
                            <p>Share one device across multiple sub-accounts</p>
                          </div>
                        </div>


                      </div>
                    </div>

                    <div className="cta-buttons">
                      <Link className="cta-primary" to="/signup">
                        Start Free Trial ➔
                      </Link>
                      <Link className="cta-secondary" to="/how-textlink-works">
                        <span>See How It Works</span>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M5 12H19M19 12L13 6M19 12L13 18" stroke="currentColor" strokeWidth="2" />
                        </svg>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ width: "100%", overflow: 'hidden' }}>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-6 px-0">
              <h2 className="video-title">Introduction Video</h2>
              <div className="video-container">
                <iframe
                  src="https://www.youtube.com/embed/nC_In3YIA9Y"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .main-slider-area {
          padding: 6rem 0 4rem;
          background: linear-gradient(to bottom, #f8faff 0%, #ffffff 100%);
        }

        .container-fluid {
          padding: 0 1rem;
        }

        .slider__content {
          max-width: 1200px;
          margin: 0 auto;
          padding: 0 1rem;
        }

        .main-title-one {
          font-size: 2rem;
          font-weight: 800;
          color: #0f172a;
          margin-bottom: 1rem;
          line-height: 1.2;
          text-align: center;
        }

        .gradient-text {
          background: linear-gradient(45deg, #3b82f6, #6366f1);
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
          display: block;
          margin-top: 0.5rem;
        }

        .sub-title-one {
          font-size: 1.1rem;
          color: #64748b;
          font-weight: 500;
          margin-bottom: 1.5rem;
          text-align: left;
          position: relative;
          padding-left: 1.5rem;
        }

        .sub-title-one::before {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 8px;
          height: 8px;
          background: #3b82f6;
          border-radius: 50%;
        }

        .aftertitle-p {
          font-size: 1rem;
          color: #475569;
          margin-bottom: 2rem;
          line-height: 1.6;
          text-align: left;
          max-width: 800px;
        }

        .features-container {
          width: 100%;
          overflow: hidden;
        }

        .features-grid {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
          gap: 1rem;
          margin-bottom: 2rem;
        }

        .feature-card {
          background: white;
          border-radius: 12px;
          padding: 1.5rem;
          display: flex;
          align-items: center;
          gap: 1rem;
          border: 1px solid #e2e8f0;
          transition: all 0.2s ease;
          min-height: 100px;
        }

        .feature-icon {
          font-size: 1.75rem;
          flex-shrink: 0;
        }

        .ghl-logo {
          width: 40px;
          height: 40px;
          object-fit: contain;
        }

        .feature-content h3 {
          font-size: 1.1rem;
          color: #0f172a;
          margin-bottom: 0.25rem;
          font-weight: 700;
          text-align: left;
        }

        .feature-content p {
          color: #64748b;
          margin: 0;
          font-size: 0.9rem;
          line-height: 1.4;
          text-align: left;
        }

        .cta-buttons {
          display: flex;
          flex-wrap: wrap;
          gap: 1rem;
          justify-content: flex-start;
          margin-top: 2rem;
        }

        .cta-primary {
          background: linear-gradient(45deg, #3b82f6, #6366f1);
          color: white;
          padding: 1rem 2rem;
          border-radius: 8px;
          font-weight: 600;
          text-decoration: none;
          transition: all 0.2s ease;
          display: inline-flex;
          align-items: center;
          gap: 0.5rem;
        }

        .cta-secondary {
          padding: 1rem 2rem;
          border-radius: 8px;
          font-weight: 600;
          text-decoration: none;
          background: white;
          color: #3b82f6;
          border: 2px solid #e2e8f0;
          transition: all 0.2s ease;
          display: inline-flex;
          align-items: center;
          gap: 0.5rem;
        }

        .video-section {
          margin-top: 3rem;
          padding: 0 1rem;
        }

        .video-title {
          font-size: 1.5rem;
          text-align: left;
          margin-bottom: 1.5rem;
          color: #0f172a;
          padding-left: 1rem;
        }

        .video-container {
          position: relative;
          padding-bottom: 56.25%;
          height: 0;
          border-radius: 12px;
          overflow: hidden;
          box-shadow: 0 4px 12px rgba(0,0,0,0.1);
        }

        .video-container iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: none;
        }

        @media (min-width: 640px) {
          .main-slider-area {
            padding: 8rem 0 4rem;
          }

          .main-title-one {
            font-size: 2.5rem;
          }

          .sub-title-one {
            font-size: 1.25rem;
          }

          .aftertitle-p {
            font-size: 1.1rem;
          }

          .features-grid {
            grid-template-columns: repeat(2, 1fr);
          }
        }

        @media (min-width: 768px) {
         

          .main-title-one {
            font-size: 3rem;
            line-height: 1.1;
          }

          .gradient-text {
            display: inline-block;
            margin-top: 0;
          }

          .features-grid {
            grid-template-columns: repeat(4, 1fr);
          }

          .video-section {
            max-width: 800px;
            margin: 4rem auto 0;
          }

          .video-title {
            padding-left: 0;
          }
        }

        @media (max-width: 480px) {
          .main-slider-area {
            padding-top: 8rem;
          }

          .sub-title-one {
            padding-left: 1.25rem;
          }

          .sub-title-one::before {
            left: 0.25rem;
          }

          .cta-buttons {
            justify-content: center;
          }

          .feature-card {
            padding: 1rem;
          }

          .ghl-logo {
            width: 35px;
            height: 35px;
          }

          .cta-primary,
          .cta-secondary {
            width: 100%;
            justify-content: center;
            text-align: center;
          }
        }
      `}</style>
    </>
  );
}