import React, { useEffect, useRef, useState } from "react";
import Dropzone from "react-dropzone";
import { Button } from "reactstrap";
import { Icon } from "../../../components/Component";
import { bytesToMegaBytes } from "../../../utils/Utils";
import { iconsType } from "../../app/file-manager/components/Icons";
import ContactsGrid from "../../../components/contacts/ContactsGrid";
import { getNumberType, isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import Cookies from "js-cookie"
import * as XLSX from 'xlsx';
import Swal from "sweetalert2";
import './UserGroup.css'
import { Select } from "antd";
import { saveAs } from 'file-saver';

const { Option } = Select;
const UserGroup = ({ groupId, close, setGroups, deleteCallback, saveCallback }) => {
  const [name, setName] = useState('')
  const [fields, setFields] = useState([])
  const [contacts, setContacts] = useState([])

  const [duplicatesExist, setDuplicatesExist] = useState(false);


  const [selectedCountry, setSelectedCountry] = useState(null)
  const [countries, setCountries] = useState([])

  useEffect(() => {

    const fetchCountries = async () => {
      try {
        const response = await fetch(`${global.SERVIP}/countries/get-all`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        data.sort((a, b) => {
          if (a.name < b.name)
            return -1;
          if (a.name > b.name)
            return 1;
          return 0;
        }
        );
        setCountries(data);
      } catch (err) {
      } finally {
      }
    };

    fetchCountries();
  }, [])

  const [rowData, setRowData] = useState([{}]);
  const getCellStyle = (params) => {
    const isLastRow = params.node.rowIndex === params.api.getDisplayedRowCount() - 1;

    if (!isLastRow && !isValidPhoneNumber(params.value || "")) {
      return { backgroundColor: '#ffc7c7', color: 'black' };
    }
    return null;
  };
  const DeleteCellRenderer = (props) => {
    const isLastRow = props.node.rowIndex === props.api.getDisplayedRowCount() - 1;

    const onDeleteRow = () => {
      setRowData(rowData => rowData.filter((_, index) => props.node.rowIndex !== index));
    };

    return !isLastRow ? (
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%" }}>
        <img
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8p5Q9D87iMIzM-_SZoyVg5KSbccZHC0U2Ow&usqp=CAU"
          alt="Delete"
          onClick={onDeleteRow}
          style={{ cursor: "pointer" }}
          width={15}
          height={15}
        />
      </div>
    ) : null;
  };



  const [columnDefs, setColumnDefs] = useState([
    {
      colId: 'Phone Number',
      field: 'Phone Number',
      editable: true,
      cellEditor: 'agTextCellEditor',
      deletable: false,
      cellStyle: getCellStyle,
      sortable: false
    },
    {
      colId: 'deleteColumn',
      headerName: '',
      sortable: false,
      cellRenderer: DeleteCellRenderer,
      editable: false,
      width: 50,
      cellStyle: { display: "flex", alignItems: "center", justifyContent: "center", padding: 0, margin: 0 }
    }
  ])

  useEffect(() => {
    const phoneNumbers = new Set();
    for (const contact of rowData) {
      if (phoneNumbers.has(contact['Phone Number'])) {
        setDuplicatesExist(true);
        return;
      }
      phoneNumbers.add(contact['Phone Number']);
    }
    setDuplicatesExist(false);
  }, [rowData]);

  const handleDropChange = (acceptedFiles) => {
    const file = acceptedFiles[0]; // since only one file is allowed
    // Make 

    const stateFields = columnDefs.filter(e => (e.field && (e.field != 'Phone Number'))).map(e => e.field);
    const stateContacts = rowData.slice(0, -1).map(data => {
      let values = { ...data };
      const phone_number = data['Phone Number'];
      delete values['Phone Number'];
      return { phone_number, values };
    });
    const reader = new FileReader();
    reader.onload = async (e) => {
      function parseSpreadsheet(file) {
        return new Promise((resolve, reject) => {
          try {
            const arrayBuffer = e.target.result;

            if (file.type === "text/csv") {
              const text = new TextDecoder("utf-8").decode(arrayBuffer);
              const workbook = XLSX.read(text, { type: "string" });
              const sheetName = workbook.SheetNames[0];
              const sheet = workbook.Sheets[sheetName];
              const jsonSheet = XLSX.utils.sheet_to_json(sheet, { header: 1 });
              resolve(jsonSheet);
            } else {
              const workbook = XLSX.read(arrayBuffer, { type: "array" });
              const sheetName = workbook.SheetNames[0];
              const sheet = workbook.Sheets[sheetName];
              const jsonSheet = XLSX.utils.sheet_to_json(sheet, { header: 1 });
              resolve(jsonSheet);
            }
          } catch (err) {
            reject(err);
          }
        });
      }

      // Convert sheet to JSON
      const jsonSheet = await parseSpreadsheet(file);
      const fields = jsonSheet[0].filter((e) => e !== 'phone_number');

      if (fields.length !== jsonSheet[0].length - 1) {
        return alert("Phone number column has to be called phone_number");
      }
      const phone_number_index = jsonSheet[0].indexOf('phone_number');

      const contacts = [...stateContacts];
      let succ = 0, fail = 0;
      const problematic_contacts = [];
      for (let i = 1; i < jsonSheet.length; i++) {
        const contact = { values: {} };
        let phone_number;
        try {
          phone_number = jsonSheet[i][phone_number_index]?.toString();
          if (
            phone_number &&
            (!phone_number.startsWith('+') && isValidPhoneNumber((selectedCountry ? '' : '+') + phone_number, selectedCountry))
          ) {
            phone_number = parsePhoneNumber((selectedCountry ? '' : '+') + phone_number, selectedCountry).format('E.164');
          }
          else if (phone_number.startsWith('+'))
            phone_number = parsePhoneNumber(phone_number, selectedCountry).format('E.164');
          else {

          }
        } catch (e) {
          console.log(e);
          // Skip this row if phone_number cannot be parsed
          problematic_contacts.push(jsonSheet[i]);
          fail++
          continue;
        }

        if (!phone_number || !isValidPhoneNumber(phone_number)) {
          // Skip this row if phone_number is invalid
          problematic_contacts.push(jsonSheet[i]);
          fail++
          continue;
        }
        else {
          const parsedNumber = parsePhoneNumber(phone_number);
          if (!parsedNumber) {
            fail++;
            problematic_contacts.push(jsonSheet[i]);
            continue;
          }
        }
        succ++;

        contact.phone_number = phone_number;

        let validFields;
        if (stateContacts.length)
          validFields = stateFields;
        for (let j = 0; j < jsonSheet[i].length; j++) {
          if (j !== phone_number_index) {
            if (stateContacts.length) {
              const currentField = jsonSheet[0][j];
              if (validFields.includes(currentField)) {
                contact.values[currentField] =
                  jsonSheet[i][j] !== undefined ? jsonSheet[i][j].toString() : "";
              }
            }
            else {
              contact.values[jsonSheet[0][j]] =
                jsonSheet[i][j] !== undefined ? jsonSheet[i][j].toString() : "";
            }
          }
        }

        contacts.push(contact);
      }

      console.log(problematic_contacts);
      if (fail > succ) {
        Swal.fire("Warning", "A lot of contacts from the CSV could not be uploaded, make sure you have included the country prefix or selected a country manually from the dropdown. If you have some successfully uploaded contacts, they might be invalid. In that case, reopen this window to refresh the list. ", 'warning');
      }
      else {
        Swal.fire("Success", "Uploaded " + succ + " of " + (jsonSheet.length - 1) + " contacts", 'success');
      }
      if (!stateContacts.length && !stateFields.length)
        setFields(fields);
      else
        setFields(stateFields);
      setContacts(contacts);
    };

    reader.readAsArrayBuffer(file);
  };



  const token = Cookies.get('token');

  const updateGroup = async (id, name, fields, contacts) => {
    try {
      const url = `${global.SERVIP}/outreach/groups/update`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ id, name, fields, contacts })
      });
      if (!response.ok)
        return false;
      const data = await response.json();
      if (!data.ok)
        return false;

      if (saveCallback)
        saveCallback({ id, name, fields, contacts })

      return true;
    }
    catch (e) {
      console.log(e);
      return false;
    }
  }

  const fetchGroup = async (id) => {
    try {
      const url = `${global.SERVIP}/outreach/groups/get-full`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ id })
      });
      if (!response.ok)
        return;
      const data = await response.json();
      if (!data.ok)
        return;

      setName(data.group.name)
      setFields(data.group.fields)
      setContacts(data.group.contacts)
    }
    catch (e) {
      console.log(e);
    }
  }

  const deleteGroup = async (id) => {
    try {
      const url = `${global.SERVIP}/outreach/groups/delete`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ id })
      });
      if (!response.ok)
        return false;
      const data = await response.json();
      if (!data.ok)
        return false;

      if (deleteCallback)
        deleteCallback(groupId);
      setGroups(groups => groups.filter(group => (group.id != groupId)));
      close();
      return true;
    }
    catch (e) {
      console.log(e);
      return false;
    }
  }

  useEffect(() => {
    if (groupId)
      fetchGroup(groupId);
  }, [groupId])



  const columnDefsRef = useRef(columnDefs);
  const rowDataRef = useRef(rowData);

  useEffect(() => {
    rowDataRef.current = rowData;
    columnDefsRef.current = columnDefs;
  }, [columnDefs, rowData]);

  const CustomHeader = props => {
    const [editing, setEditing] = useState(false);
    const [headerName, setHeaderName] = useState(props.displayName);

    const onDeleteColumn = async () => {
      const result = await Swal.fire({
        title: "Delete column",
        text: `Are you sure that you want to delete the column ${props.column.colDef.field}?`,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      });

      if (result.isConfirmed) {
        setColumnDefs(prev => prev.filter(c => c.field !== props.column.colDef.field));
      }
    };

    const startEditing = () => setEditing(true);

    const stopEditing = () => {
      setEditing(false);
      const oldHeaderName = props.column.colDef.headerName;
      if (columnDefsRef.current.some(e => e.field === headerName)) {
        props.column.colDef.headerName = oldHeaderName;
        props.api.refreshHeader();
        return;
      }
      props.column.colDef.headerName = headerName;
      const newRowData = structuredClone(rowDataRef.current);
      for (let i = 0; i < Math.max(newRowData.length - 1, 0); i++) {
        newRowData[i][headerName] = newRowData[i][oldHeaderName];
        delete newRowData[i][oldHeaderName];
      }
      setRowData(newRowData);
      const ncd = columnDefsRef.current.map(e =>
        (e.field === oldHeaderName) ? { ...e, field: headerName, headerName: headerName, colId: headerName } : e
      );
      setColumnDefs(ncd);
      props.api.setColumnDefs(ncd);
      props.api.refreshHeader();
    };

    const handleNameChange = (e) => setHeaderName(e.target.value);

    return (
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        minWidth: '0',
        padding: '5px'  // ✅ Apply padding to the header
      }}>
        {editing ? (
          <input
            type="text"
            value={headerName}
            onChange={handleNameChange}
            onBlur={stopEditing}
            autoFocus
            style={{ flex: 1, minWidth: '0' }}
          />
        ) : (
          <div
            style={{
              flex: '1',
              display: 'flex',
              alignItems: 'center',
              minWidth: '0',
              cursor: 'pointer',
              overflow: 'hidden'
            }}
            onClick={startEditing}
          >
            <span
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                minWidth: '0'
              }}
            >
              {headerName}
            </span>
          </div>
        )}
        {props.column.colDef.deletable && (
          <img
            src="https://cdn-icons-png.flaticon.com/512/6861/6861362.png"
            alt="Delete"
            onClick={onDeleteColumn}
            style={{
              marginLeft: 5,
              cursor: 'pointer',
              width: 20,
              height: 20,
              flexShrink: 0
            }}
          />
        )}
      </div>
    );
  };

  useEffect(() => {
    setColumnDefs([
      {
        colId: 'Phone Number',
        field: 'Phone Number',
        editable: true,
        cellEditor: 'agTextCellEditor',
        deletable: false,
        cellStyle: getCellStyle,
        sortable: false
      },
      ...(fields.map(e => ({
        field: e,
        editable: true,
        cellEditor: 'agTextCellEditor',
        headerComponent: CustomHeader,
        headerName: e,
        deletable: true
      }))),
      {
        colId: 'deleteColumn',
        headerName: '',
        sortable: false,
        cellRenderer: DeleteCellRenderer,
        editable: false,
        width: 50,
        cellStyle: { display: "flex", alignItems: "center", justifyContent: "center", padding: 0, margin: 0 }
      }
    ])
    setRowData([...(contacts.map(contact => ({
      'Phone Number': contact.phone_number,
      ...contact.values
    }))), {}])
  }, [fields, contacts])

  const handleDownload = (name, contacts, fields, isCsv) => {
    // Prepare the data for export
    const data = contacts.map(contact => {
      let row = { phone_number: contact.phone_number };
      fields.forEach(field => {
        row[field] = contact.values[field] || '';
      });
      return row;
    });
    const filename = "TextLink " + (name || 'Unnamed group');

    if (isCsv) {
      // Convert to CSV
      const worksheet = XLSX.utils.json_to_sheet(data);
      const csv = XLSX.utils.sheet_to_csv(worksheet);
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      saveAs(blob, filename + ".csv");
    } else {
      // Convert to XLSX
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Contacts");
      const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
      const blob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
      saveAs(blob, filename + ".xlsx");
    }
  };

  const saveGroup = async () => {
    for (let i = 0; i < rowData.length - 1; i++) {
      if (!isValidPhoneNumber(rowData[i]['Phone Number'] || "")) {
        alert("Invalid phone number")
        return;
      }
    }

    const newFields = columnDefs.filter(e => (e.field && (e.field != 'Phone Number'))).map(e => e.field);
    const newContacts = rowData.slice(0, -1).map(data => {
      let values = { ...data };
      const phone_number = data['Phone Number'];
      delete values['Phone Number'];
      return { phone_number, values };
    });
    if (await updateGroup(groupId, name, newFields, newContacts)) {
      setGroups(groups => groups.map(group => ((group.id != groupId) ? group : { ...group, name, contacts_length: newContacts.length })))
      setFields(newFields);
      setContacts(newContacts);
    }
    else {
      alert("Error");
    }
    close();

  }

  return (
    <React.Fragment>
      <a
        href="#close"
        onClick={(ev) => {
          ev.preventDefault();
          close();
        }}
        className="close"
      >
        <Icon name="cross-sm"></Icon>
      </a>
      <div className="modal-body modal-body-lg">

        <h5 className="title mb-3">Group options</h5>
        <p>Enter the group name, used for your reference (e.g. Customers, Employees, Subscribers)</p>
        <input className="form-control form-control-md" placeholder="Group name" style={{ display: "flex", flexGrow: 1, marginBottom: 10 }} value={name} onChange={(e) => setName(e.target.value)} />
        <div className="nk-upload-form" style={{ marginBottom: 0 }}>
          {(rowData.length > 1) ? null : <h5 className="title mb-3">Upload File with recipients (csv,xlx)</h5>}
          <div style={{ marginBottom: 5 }}><span style={{ marginRight: 10 }}>The file you upload should contain prefixes in front of phone numbers (e.g. +1 for US). If it does not, select the country here:</span>
            <Select
              showSearch
              filterOption={(input, option) =>
                option.label.toLowerCase().trim().includes(input.toLowerCase().trim())
              }
              onMouseDown={(e) => e.stopPropagation()} // Stops propagation
              onClick={(e) => e.stopPropagation()} // Stops propagation
              value={selectedCountry}
              defaultValue={null}
              placeholder="Use prefixes from file"
              style={{ flexGrow: 1, minWidth: '200px', margin: 'auto' }}
              onChange={setSelectedCountry}
            >
              <Option value={null} label="Use prefixes from file">
                Use prefixes from file
              </Option>
              {countries.map(country => (
                <Option
                  key={country.iso2}
                  value={country.iso2}
                  label={`${country.name} (${country.calling_code.startsWith('+') ? country.calling_code : ('+' + country.calling_code)})`}
                >
                  {country.name} ({country.calling_code.startsWith('+') ? country.calling_code : ('+' + country.calling_code)})
                </Option>
              ))}
            </Select>

          </div>
          <Dropzone
            onDrop={(acceptedFiles) => handleDropChange(acceptedFiles)}
            accept={['.csv', '.xls', '.xlsx']} // Use an array for file extensions
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div
                  {...getRootProps()}
                  className="dropzone upload-zone small bg-lighter my-2 dz-clickable"
                  style={{ paddingTop: 5, paddingBottom: 5 }}
                >
                  <input {...getInputProps()} />
                  <div className="dz-message">
                    <span className="dz-message-text">
                      <span>Drag and drop</span> file here or <span>browse</span>{(rowData.length > 1) ? (<>
                        <text>. Since you already have contacts in the group, only the existing columns will stay in the group, and will be filled for the newly uploaded contacts if they exist.</text>
                      </>) : null}
                    </span>
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
        </div>
        {duplicatesExist && <p style={{ textAlign: 'center' }}>⚠️ <b>DUPLICATE PHONE NUMBERS FOUND!</b> Some contacts may receive two messages. If it's unintentional, check your contacts file for duplicates. </p>}
        <div className="form-control-wrap">
          <div className="input-group">
            <ContactsGrid duplicatesExist={duplicatesExist} columnDefs={columnDefs} setColumnDefs={setColumnDefs} rowData={rowData} setRowData={setRowData} CustomHeader={CustomHeader} ></ContactsGrid>
          </div>
        </div>
        <div style={{ marginBottom: 100 }}></div>
        <div className="nk-modal-action justify-between" >
          <ul className="btn-toolbar g-4 align-center">
            <li>
              <a
                href="#toggle"
                onClick={(ev) => {
                  ev.preventDefault();
                  Swal.fire({
                    title: "Delete group",
                    text: "Are you sure that you want to delete this group? This action is irreversible.",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes, delete this group",
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      const result = await deleteGroup(groupId);
                      if (result)
                        Swal.fire({ title: "Success", text: "Successfully deleted group", icon: "success" });
                      else
                        Swal.fire({ title: "Error", text: "Could not delete group", icon: "error" });
                    }
                  });
                }}
                className="link link-danger"
              >
                Delete group
              </a>
            </li>

            <li>
              <a
                href="#toggle"
                onClick={(ev) => {
                  ev.preventDefault();
                  handleDownload(name, contacts, fields, true);
                }}
                className="link link-primary"
              >
                Export CSV
              </a>
            </li>
            <li>
              <a
                href="#toggle"
                onClick={(ev) => {
                  ev.preventDefault();
                  handleDownload(name, contacts, fields, false);
                }}
                className="link link-primary"
              >
                Export XLSX
              </a>
            </li>
          </ul>
          <ul className="btn-toolbar g-4 align-center">
            <li>
              <a
                href="#toggle"
                onClick={(ev) => {
                  ev.preventDefault();
                  close();
                }}
                className="link link-primary"
              >
                Cancel
              </a>
            </li>
            <li>
              <Button onClick={saveGroup} color="primary">
                Save
              </Button>
            </li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UserGroup;
