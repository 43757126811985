import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import {
    Row,
    Col,
    Card,
    CardTitle,
    FormGroup,
    Label,
    Input
} from "reactstrap";
import { Block, BlockHead, BlockHeadContent, BlockTitle } from "../../components/block/Block";
import Button from "../../components/button/Button";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';

function Intro1() {
    const [source, setSource] = useState("");
    const [customSource, setCustomSource] = useState("");
    const [bonusValue, setBonusValue] = useState("");
    let navigate = useNavigate();

    const handleCreateRecommendation = async (recommendationText) => {
        const url = global.SERVIP + '/user/create-recommendation'; // Adjust with your server URL if necessary
        const jwtToken = Cookies.get('token');
        
        if (!recommendationText || recommendationText.length > 300) {
            Swal.fire({
                icon: 'warning',
                title: 'Invalid Input',
                text: 'Recommendation must be between 1 and 300 characters.'
            });
            return;
        }
    
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${jwtToken}` // Set JWT token in the Authorization header
                },
                body: JSON.stringify({
                    recommendation_text: recommendationText, bonus: bonusValue
                })
            });
    
            const result = await response.json();
            if (result.ok) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Recommendation submitted successfully!'
                }).then(() => {
                    navigate('/dashboard'); // Redirect to the dashboard after success
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Submission Failed',
                    text: result.message || "Failed to submit recommendation."
                });
            }
        } catch (error) {
            console.error("Error in handleCreateRecommendation:", error);
            Swal.fire({
                icon: 'error',
                title: 'Server Error',
                text: 'Please try again later.'
            });
      } finally{

        navigate('/dashboard'); // Redirect to the dashboard after success
      }
        
    };
    const bonus = {
        "YouTube": "What video were you watching?",
        "Google Search": "What did you search for?",
        "Blog/Article": "What were you reading?",
        "Social Media": "Which post? (Optional)"
    }

    return (
        <>
            <Head title="How Did You Hear About Us?"></Head>
            <Content page="component">
                <BlockHead size="lg" wide="sm">
                    <BlockHeadContent>
                        <BlockTitle tag="h2" className="fw-normal text-center">
                            Welcome To TextLink!
                        </BlockTitle>
                        <p className="text-muted text-center">Tell us how you found us and get <strong>500 free messages</strong>!</p>
                    </BlockHeadContent>
                </BlockHead>
                <Row className="justify-content-center">
                    <Col lg="8">
                        <Card className="card-bordered shadow-lg p-4 text-center">
                            <CardTitle>
                                <BlockTitle tag="h5" className="fw-normal text-center" >How did you hear about us?</BlockTitle>
                            </CardTitle>
                            <FormGroup>
                                <Label for="sourceSelect" className="fw-semibold">Choose an option</Label>
                                <Input
                                    type="select"
                                    id="sourceSelect"
                                    className="form-control-lg"
                                    value={source}
                                    onChange={(e) => setSource(e.target.value)}
                                >
                                    <option value="">Select</option>
                                    <option value="AppSumo">AppSumo</option>
                                    <option value="Rapid API">Rapid API</option>
                                    <option value="YouTube">YouTube</option>
                                    <option value="Google Search">Google Search</option>
                                    <option value="TextFlow">TextFlow</option>
                                    <option value="Social Media">Social Media (Twitter, LinkedIn, etc.)</option>
                                    <option value="Blog/Article">Blog/Article</option>
                                    <option value="Recommendation">Recommendation</option>
                                    <option value="Other">Other</option>
                                </Input>
                            </FormGroup>
                            {source === "Other" && (
                                <FormGroup>
                                    <Label for="customSource" className="fw-semibold">Please specify</Label>
                                    <Input
                                        type="text"
                                        id="customSource"
                                        className="form-control-lg"
                                        value={customSource}
                                        onChange={(e) => setCustomSource(e.target.value)}
                                        placeholder="Enter source"
                                    />
                                </FormGroup>
                            )}
                            {bonus[source] && (
                                <FormGroup>
                                    <Input
                                        type="text"
                                        id="customSource"
                                        className="form-control-lg"
                                        value={bonusValue}
                                        onChange={(e) => setBonusValue(e.target.value)}
                                        placeholder={bonus[source]}
                                    />
                                </FormGroup>
                            )}
                            <Button className="btn btn-primary" outline onClick={() => handleCreateRecommendation(source === "Other" ? customSource : source)}>Submit</Button>
                        </Card>
                    </Col>
                </Row>
            </Content>
        </>
    );
}

export default Intro1;