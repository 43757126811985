import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './PricingCardNew.css';
const subscriptions = [
  { name: 'Free', price: 0, messages: 500 },
  { name: 'Lite', price: 25, messages: 5000 },
  { name: 'Starter', price: 45, messages: 15000 },
  { name: 'Pro', price: 95, messages: 50000 },
  { name: 'Enterprise', price: 195, messages: 150000 },
];

function PricingCardNew() {
  const [isYearly, setIsYearly] = useState(false);
  const navigate = useNavigate();

  const toggleYearly = () => {
    setIsYearly(!isYearly);
  };

  return (
    <div className="pricing-container">
      <div className="toggle-container">
        <label className="toggle-switch">
          <input
            type="checkbox"
            checked={isYearly}
            onChange={toggleYearly}
          />
          <span className="slider"></span>
        </label>
        <span className="toggle-label">Yearly subscription (17% off)</span>
      </div>

      <div className="pricing-grid">
        {subscriptions.map((sub, index) => {
          const monthlyPrice = sub.price;
          const yearlyPrice = (sub.price * 12 * 0.83).toFixed(2);

          return (
            <div
              key={index}
              className={`pricing-card ${sub.name === 'Pro' ? 'highlight' : ''}`}
            >
              <div className="card-header">
                <h3 className="plan-name">
                  {sub.name}
                  {sub.name === 'Pro' && (
                    <span className="popular-badge">Most Popular</span>
                  )}
                </h3>
                <div className="price">
                  <span className="amount">
                    ${isYearly ? monthlyPrice * 0.83 : monthlyPrice}
                  </span>
                  <span className="period">/mo</span>
                </div>
                <p className="yearly-price">
                  ${isYearly ? `${Math.round(monthlyPrice * 0.83 * 12)}` : Math.round(monthlyPrice * 12)}/year
                </p>
              </div>

              <ul className="features-list">
                <li>
                  <span className="icon">✔️</span>
                  {sub.messages} messages monthly
                </li>
                <li>
                  <span className="icon">
                    {sub.name === 'Free' ? '❌' : '✔️'}
                  </span>
                  24/7 customer support
                </li>
                <li>
                  <span className="icon">
                    {sub.name === 'Pro' || sub.name === 'Enterprise' ? '✔️' : '❌'}
                  </span>
                  Free setup help
                </li>
                <li>
                  <span className="icon">✔️</span>
                  Access to all features
                </li>
              </ul>

              <button
                className="cta-button"
                onClick={() => navigate('/signup')}
              >
                {sub.name === 'Free' ? 'Sign Up' : 'Buy Now'}
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default PricingCardNew;