import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input, Alert } from 'reactstrap';

const ChangeSubuserEmail = ({ token, id, currentEmail, onChangeEmail }) => {
  const [modal, setModal] = useState(false);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);
  const [isError, setIsError] = useState(false);

  const toggle = () => {
    if (!modal) {
      setEmail('');
    }
    setModal(!modal);
    setMessage(null);
    setIsError(false);
  };

  const handleEmailChange = (e) => setEmail(e.target.value);

  const handleSubmit = async () => {
    const url = `${global.SERVIP}/user/change-subuser-email`;
    const body = { id, email };
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(body)
      });
      const result = await response.json();

      if (result.ok) {
        setMessage('Email has been changed successfully.');
        setIsError(false);
        setTimeout(toggle, 1500);
        onChangeEmail(result.subuser);
      } else {
        setMessage(result.message || 'Server error');
        setIsError(true);
      }
    } catch (error) {
      setMessage('Network error');
      setIsError(true);
    }
  };

  return (
    <>
      <a
        href="#toggle"
        onClick={(ev) => {
          ev.preventDefault();
          ev.stopPropagation();
          toggle();
        }}
        className="link link-primary"
      >
        Change
      </a>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader>{`Change Email (Current: ${currentEmail})`}</ModalHeader>
        <ModalBody>
          {message && (
            <Alert color={isError ? 'danger' : 'success'}>
              {message}
            </Alert>
          )}
          <Form>
            <FormGroup>
              <Label for="email">New Email</Label>
              <Input
                type="email"
                name="email"
                id="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Enter new email"
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit}>Submit</Button>{' '}
          <Button color="secondary" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ChangeSubuserEmail;
