import { Link } from "react-router-dom";
import './TestimonialOne.css'; // Import the CSS file for this component

export default function TestimonialOne() {
  return (
    <section className="testimonial-area">
      <div className="container">
        <div className="row align-items-center">
          {/* Left Column: Image and Text */}
          <div className="col-lg-6">
            <div className="testimonial-img-wrapper mb-30">
              <div className="image-text-container">
                <img 
                  src="assets/img/logo/logo2.png" 
                  alt="Logo" 
                  className="testimonial-logo"
                />
                <h2 className="textlink-heading">TextLink</h2>
              </div>
            </div>
          </div>

          {/* Right Column: Text and CTA */}
          <div className="col-lg-6">
            <div className="testimonial-text-wrapper mb-30">
              <div className="section-title mb-30">
                <h2 className="style-text">Sign Up</h2>
                <h2 className="sect-title">Ready to Get Started?</h2>
              </div>
              <p className="paragraph-title">
                Try it out for free now. <br /><br />
                You will get <strong>500 free messages</strong> to test the service once you set up your device. After that, you can choose one of our plans or contact us for a custom-tailored solution.
              </p>
              <div className="cta-wrapper">
                <Link to="/signup" className="cta-button">
                  Start Now, No Credit Card Required
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}