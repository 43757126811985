import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { Col, Row } from "reactstrap";
import {
  Card, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Modal,
  ModalHeader,
  ModalBody,
  ModalFooter, CardBody, CardSubtitle, CardTitle, CardLink, CardText
} from "reactstrap";
import { Icon } from "../../../components/Component";
import Button from "react-bootstrap/esm/Button";
import {
  SpecialTable,
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  BackTo,
  OrderTable,
  LoginLogTable,
} from "../../../components/Component";
import { CountriesTable } from "./components/CountriesTable";
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Select } from 'antd';
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
const { Option } = Select;

function SimPage() {
  const navigate = useNavigate()
  const [currentCountry, setCurrentCountry] = useState(null)
  const location = useLocation();
  const simId = location.state?.id;
  const [simData, setSimData] = useState([])
  const [countrySearch, setCountrySearch] = useState("")
  const [blockLinks, setBlockLinks] = useState(false);
  const [blockPhoneNumbers, setBlockPhoneNumbers] = useState(false);
  const [blockRegex, setBlockRegex] = useState(false)
  const [countries, setCountries] = useState([])
  const [updateCountryModal, setUpdateCountryModal] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [selectedCountryObject, setSelectedCountryObject] = useState({})
  const [seller, setSeller] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [price, setPrice] = useState('');
  const [regex, setRegex] = useState('');
  const token = Cookies.get('token');

  useEffect(() => {
    setSelectedCountry(null);
    setSeller(false);
  }, [updateCountryModal])

  useEffect(() => {
    // GET, ako vec postoji, prefillujemo sellera i price
    if (selectedCountry) {
      let priceset = false;
      for (let dt of simData) {
        if (dt.destinationCountry == selectedCountry) {
          priceset = true;
          setPrice(dt.price);
          setSeller(dt.seller);
        }
      }
      setUpdating(priceset);
      for (let country of countries) {
        if (country.iso2 == selectedCountry) {
          setSelectedCountryObject(country);
          if (!priceset) {
            setPrice(parseFloat(country.sms_max_price).toString())
            setSeller(false);
          }
        }
      }
    }
  }, [selectedCountry]);

  const fetchContentRules = async (simCardId) => {
    const url = `${global.SERVIP}/sender/get-content-rules`;

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ sim_card_id: simCardId })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const { ok, rules } = await response.json();
      if (!ok)
        return;
      setBlockLinks(rules.block_links);
      setBlockPhoneNumbers(rules.block_phone_numbers);
      setBlockRegex(rules.block_regex);
      setRegex(rules.blocked_regex);
    } catch (error) {
      console.error('Error fetching destinations:', error);
      return { ok: false, message: error.message };
    }
  };

  const updateContentRules = async (simCardId) => {
    const url = `${global.SERVIP}/sender/update-content-rules`;

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ sim_card_id: simCardId, block_links: blockLinks, block_phone_numbers: blockPhoneNumbers, block_regex: blockRegex, blocked_regex: regex })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const { ok, message } = await response.json();
      if (!ok) {
        return alert(message || "Error")
      }
      else {
        return alert("Success")
      }
    } catch (error) {
      console.error('Error fetching destinations:', error);
      return alert("Error")
    }
  };

  const fetchDestinationsBySimCard = async (simCardId) => {
    const url = `${global.SERVIP}/sim/get_destinations/${simCardId}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Set the authorization header if needed
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setSimData(data.destinations)
      return data;
    } catch (error) {
      console.error('Error fetching destinations:', error);
      return { ok: false, message: error.message };
    }
  };
  useEffect(() => {
    if (!token) {
      navigate("/signup")
    }
    const fetchCountries = async () => {
      try {
        const response = await fetch(`${global.SERVIP}/countries/get-all`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        data.sort((a, b) => {
          if (a.name < b.name)
            return -1;
          if (a.name > b.name)
            return 1;
          return 0;
        }
        );
        setCountries(data);
      } catch (err) {
      } finally {
      }
    };

    fetchCountries();
  }, []);

  useEffect(() => {
    if (simId) {
      fetchDestinationsBySimCard(simId);
      fetchContentRules(simId);
    } else {
      navigate("/dashboard/your-devices/overview");
    }
  }, [simId]); // Adding simId as a dependency

  const updateSenderDestination = async () => {
    const url = `${global.SERVIP}/user/update-sender-destination`;

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ sim_card_id: simId, destination_country: selectedCountry, seller, price })
      });

      if (!response.ok)
        return Swal.fire({ title: "Error!", text: "Server error", icon: "error" });

      const { ok, message } = await response.json();
      if (!ok)
        return Swal.fire({ title: "Error!", text: message || "Unknown error occured", icon: "error" });

      else {
        let st = false;
        for (let dt of simData) {
          if (dt.destinationCountry == selectedCountry) {
            dt.seller = seller;
            dt.price = price;
            st = true;
          }
        }
        if (!st) {
          simData.push({
            countryName: selectedCountryObject.name,
            destinationCountry: selectedCountry,
            seller,
            price,
            simCardId: simId
          });
        }
        setSimData([...simData]);
        setUpdateCountryModal(false);
        return Swal.fire({ title: "Success!", text: "Changes successfully saved", icon: "success" });
      }

    } catch (error) {
      console.error('Error fetching destinations:', error);
      return alert("Error")
    }
  }
  return (
    <>
      <Head title="Sim Edit Page" />
      <Content>
        <h5>  Here you can edit this SIM card's functionalities.</h5>
        <br />
        <Row style={{ display: 'flex', alignItems: 'stretch' }}>
          <Col lg="5">
            <Card className="card h-100" >
              <CardBody className="card-inner">
                <CardTitle tag="h5">Do you want to block something from sending? </CardTitle>
                <CardSubtitle tag="h6" className="mb-2 ff-base">
                  This will apply to all regions
                </CardSubtitle>
                <br />


                <br />
                <a>If you don't disallow any, your devices will send all content.</a>
                <br />
                <ul className="custom-control-group custom-control-vertical w-100">
                  <br />

                  <li>
                    <div className="custom-control custom-control-sm custom-check custom-control-pro">
                      <input type="checkbox" className="custom-control-input" name="paymentCheck" id="paymentCheck1" checked={blockLinks} onChange={(e) => setBlockLinks(e.target.checked)} />
                      <label className="custom-control-label" htmlFor="paymentCheck1" style={{ display: 'flex', flexDirection: 'column' }}>
                        <Icon className="icon-lg" name="link"></Icon>
                        <span>Block links </span>

                      </label>
                    </div>
                  </li>
                  <li>
                    <div className="custom-control custom-control-sm custom-check custom-control-pro">
                      <input type="checkbox" className="custom-control-input" name="paymentCheck" id="paymentCheck2" checked={blockPhoneNumbers} onChange={(e) => setBlockPhoneNumbers(e.target.checked)} />
                      <label className="custom-control-label" htmlFor="paymentCheck2" style={{ display: 'flex', flexDirection: 'column' }}>
                        <Icon className="icon-lg" name="call-fill"></Icon>
                        <span>Block phone numbers</span>
                      </label>
                    </div>
                  </li>

                </ul>
                <div className="form-control-wrap" style={{ marginTop: 15 }}>
                  <p>Write custom regex if you want to block ceratain messages from sending.
                  </p>
                  <div className="input-group">
                    <div className="input-group-prepend input-group-lg">
                      <div className="input-group-text">
                        <input type="checkbox" checked={blockRegex} onChange={(e) => setBlockRegex(e.target.checked)} />
                      </div>
                    </div>
                    <textarea placeholder="/Facebook|Instagram/i" type="text" className="form-control" value={regex} onChange={(e) => setRegex(e.target.value)} />
                  </div>
                </div>
                <Button className="btn-dim" outline color="success" style={{ marginTop: 10, width: '100%' }} onClick={() => {
                  if (simId)
                    updateContentRules(simId)
                }}>Save</Button>
              </CardBody>
            </Card>
          </Col>
          <Col lg="7">
            <Card className="card h-100" style={{ padding: 20 }}>
              <Block size="lg">
                <BlockHead>
                  <BlockHeadContent >
                    <div style={{ height: 4 }}></div>
                    <BlockTitle tag="h4" >Countries you send to</BlockTitle>
                    <p >
                      Countries that this SIM send's to, choose to:{" "}
                      <strong className="text-primary"> add/remove countries </strong> .
                      Your SIM country is added by default once you set up the TextLink app.
                    </p>
                  </BlockHeadContent>
                </BlockHead>
                <Button className="btn-dim" outline color="success" style={{ marginBottom: 10, marginTop: 10, width: '100%' }} onClick={() => { setUpdateCountryModal(true) }}>Add/Update a country</Button>
                <input type="text" className="form-control" placeholder="Search for country" style={{ marginBottom: 10, }} onChange={(e) => { setCountrySearch(e.target.value) }} />

                <CountriesTable data={simData} open={setCurrentCountry} countrySearch={countrySearch} deleteItem={(iso2) => { setSimData(simData.filter(e => e.destinationCountry != iso2)) }} />
              </Block>
            </Card>
          </Col>
        </Row>
        <Modal size="lg" isOpen={updateCountryModal} toggle={() => setUpdateCountryModal(null)}>
          <ModalHeader
            toggle={() => setUpdateCountryModal(null)}
            close={
              <button className="close" onClick={() => setUpdateCountryModal(null)}>
                <Icon name="cross" />
              </button>
            }
          >
            Add/Update a country
          </ModalHeader>
          <ModalBody>
            <p style={{ marginTop: 5, marginBottom: 5 }}>Choose the recipient country that you want to add or edit</p>
            <Select
              showSearch
              filterOption={(input, option) =>
                option?.label?.toLowerCase().trim().includes(input.toLowerCase().trim())
              }
              value={selectedCountry}
              defaultValue={null}
              placeholder="Select a country"
              style={{ flexGrow: 1, minWidth: '200px', margin: 'auto' }}
              onChange={setSelectedCountry}
            >
              <Option value={null} label="Select a country">
                Select a country
              </Option>
              {countries.map(country => (
                <Option
                  key={country.iso2}
                  value={country.iso2}
                  label={`${country.name} (${country.calling_code.startsWith('+') ? country.calling_code : ('+' + country.calling_code)})`}
                >
                  {country.name} ({country.calling_code.startsWith('+') ? country.calling_code : ('+' + country.calling_code)})
                </Option>
              ))}
            </Select>

            {selectedCountry && <>
              <h5 style={{ marginTop: 10 }}>Details</h5>
              <p>
                You are adding {selectedCountry} into the list of country codes this SIM card/device will send messages to.
                Note that <span style={{ fontWeight: 'bold' }}>carrier rates apply</span>.
              </p>

            </>}
          </ModalBody>
          <ModalFooter className="bg-light">
            <div className="form-control-wrap" style={{ width: '100%' }}>
              <Button className="btn-dim" color="primary" style={{ width: '100%', marginTop: 15 }} onClick={updateSenderDestination}>{updating ? "Update" : "Add country"}</Button>
            </div>
          </ModalFooter>
        </Modal>
      </Content >
    </>
  )
}
export default SimPage;