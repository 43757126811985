import React, { useEffect, useState } from "react";
import HeaderOne from "../../header/HeaderOne";
import { Link } from "react-router-dom";
import "./BlogPages.css";

export default function BlogPages() {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await fetch(`${global.SERVIP}/blog-controller`);
        if (!response.ok) {
          throw new Error("Failed to fetch blogs");
        }
        const data = await response.json();
        setBlogs(Array.isArray(data) ? data : data.blogs || []);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  if (loading) {
    return (
      <>
        <HeaderOne />
        <section style={{ marginTop: "100px" }}>
          <div className="container py-5">
            <h2>Loading blogs...</h2>
          </div>
        </section>
      </>
    );
  }

  if (error) {
    return (
      <>
        <HeaderOne />
        <section style={{ marginTop: "100px" }}>
          <div className="container py-5">
            <h2 className="text-danger">Error: {error}</h2>
          </div>
        </section>
      </>
    );
  }

  return (
    <>
      <HeaderOne />
      <section
        className="news-feed-area pt-120 pb-75 pt-md-60 pb-md-15 pt-xs-50 pb-xs-10"
        style={{ marginTop: "100px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h2 style={{ marginBottom: "40px" }}>Our Recent Blog Posts</h2>
            </div>
          </div>

          <div className="row">
            {/* Blog Grid */}
            <div className="col-lg-9">
              <div className="row g-4">
                {blogs.map((blog) => {
                  const { id, slug, title, coverImage, category, subtitle, createdAt } = blog;
                  return (
                    <div key={id} className="col-lg-6 col-md-6">
                      <div className="blog-card">
                        {coverImage && (
                          <div className="blog-image-wrapper">
                            <Link to={`/${slug}`} state={{ allBlogs: blogs }}>
                              <img className="img-fluid" src={coverImage} alt={title} />
                            </Link>
                            {category && <div className="blog-category">{category}</div>}
                          </div>
                        )}

                        <div className="blog-content">
                          <h3>
                            <Link to={`/${slug}`} state={{ allBlogs: blogs }}>{title}</Link>
                          </h3>
                          {subtitle && <h4 className="blog-subtitle">{subtitle}</h4>}
                          {createdAt && <p className="blog-date">{new Date(createdAt).toLocaleDateString()}</p>}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            {/* Sidebar */}
            <div className="col-lg-3">
              <div className="sidebar">
                <div className="trial-banner text-center p-4 mb-4">
                  <h4 className="trial-heading">500 Free Messages!</h4>
                  <p className="trial-description">
                    Start your free trial with TextLink and get 500 messages to test out seamless communication.
                  </p>
                  <Link
                    to="/signup"
                    style={{
                      display: "inline-block",
                      padding: "10px 20px",
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "#007bff",
                      textTransform: "uppercase",
                      border: "1px solid #007bff",
                      borderRadius: "6px",
                      textDecoration: "none",
                      backgroundColor: "transparent",
                      cursor: "pointer",
                      transition: "all 0.3s ease",
                    }}
                    onMouseEnter={(e) => (e.target.style.backgroundColor = "#007bff", e.target.style.color = "#fff")}
                    onMouseLeave={(e) => (e.target.style.backgroundColor = "transparent", e.target.style.color = "#007bff")}
                  >
                    Try for Free
                  </Link>
                </div>

                {/* Latest Posts */}
                <div className="latest-posts" style={{ padding: 10 }}>
                  <h5 className="latest-posts-heading">Latest Posts</h5>
                  <ul className="latest-posts-list">
                    {blogs.slice(0, 5).map((blog) => (
                      <li key={blog.id} className="latest-post-item">
                        <Link to={`/${blog.slug}`} state={{ allBlogs: blogs }} className="latest-post-link">
                          {blog.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
