import React, { useEffect, useState, useContext, useMemo, useRef } from "react";
import Head from "../../../layout/head/Head";
import ContentAlt from "../../../layout/content/ContentAlt";
import AppContact from "./contact/Contact";
import ChatBody from "./ChatBody";
import User from "../../../images/avatar/b-sm.jpg";
import { Button, Icon, UserAvatar } from "../../../components/Component";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown, DropdownItem, Modal, ModalHeader, ModalBody, Alert, FormGroup, Label, Input, ModalFooter, Form } from "reactstrap";
import { ChatContext } from "./ChatContext";
import { ChannelAsideBody, ChatAsideBody } from "./ChatAsideBody";
import Cookies from "js-cookie";
import { useNavigate } from "react-router"
import { parsePhoneNumber } from "libphonenumber-js";
import { io } from 'socket.io-client';
import {
  SyncLoader
} from 'react-spinners';
import { appendMessage, parseMessages } from "./util/ChatParser"
import { Select } from "antd";
import Swal from "sweetalert2";
const { Option } = Select;

export const MAX_PAGE_OVERRIDE = { current: null };
export const PAGE_SIZE = 50;
export function calculateChatData(allChatData, allContacts, isContactsOrFavoritePage, pageNumber, filterText, contacts, replyFilterText) {

  console.log("ZOVEM CALCULATE CHAT DATA");
  if (filterText || replyFilterText) {
    console.log("ZOVEM CALCULATE CHAT DATA 1");
    const fltrd1 = replyFilterText ? allChatData.filter(e => (new RegExp(`\\b${replyFilterText}\\b`, "i").test(e?.reply || ""))) : allChatData;
    const filtrd = fltrd1.filter((item) => {
      return item.phone_number.toLowerCase().includes(filterText.toLowerCase()) ||
        (contacts[item.phone_number] ? (contacts[item.phone_number].name.toLowerCase().includes(filterText.toLowerCase())) : false);
    });
    MAX_PAGE_OVERRIDE.current = Math.ceil(filtrd.length / PAGE_SIZE);
    return filtrd.slice((pageNumber - 1) * PAGE_SIZE, pageNumber * PAGE_SIZE).map(((e, index) => ({
      id: index + 10000000,
      name: e.phone_number,
      nickname: "",
      theme: "blue",
      chatTheme: "purple",
      date: formatDate(new Date(e.date)),
      active: "60m",
      unread: true,
      archive: false,
      fav: false,
      delivered: true,
      image: getImage(e.phone_number),
      convo: [{
        "id": "chat_2",
        "me": true,
        "chat": [
          e.text
        ],
        "date": "4:12 PM"
      }]
    })))
  }
  MAX_PAGE_OVERRIDE.current = null;
  if (isContactsOrFavoritePage)
    return allContacts;
  return allChatData.slice((pageNumber - 1) * PAGE_SIZE, pageNumber * PAGE_SIZE).map(((e, index) => ({
    id: index + 10000000,
    name: e.phone_number,
    nickname: "",
    theme: "blue",
    chatTheme: "purple",
    date: formatDate(new Date(e.date)),
    active: "60m",
    unread: true,
    archive: false,
    fav: false,
    delivered: true,
    image: getImage(e.phone_number),
    convo: [{
      "id": "chat_2",
      "me": true,
      "chat": [
        e.text
      ],
      "date": "4:12 PM"
    }]
  })))
}

const getImage = (phone_number) => {
  let p = null;
  try {
    p = parsePhoneNumber(phone_number)?.country
  }
  catch (_) { }
  if (!p)
    return "https://textlinksms.com/assets/img/logo/logo2.png"

  return `http://purecatamphetamine.github.io/country-flag-icons/1x1/${p}.svg`
}

function formatDate(date) {
  const now = new Date();
  const oneDay = 24 * 60 * 60 * 1000; // milliseconds in one day
  const oneYear = 365 * oneDay;
  const difference = now - date;

  // Function to format date without leading zero for hours
  const formatTime = (date) => {
    let timeString = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    return timeString.startsWith('0') ? timeString.slice(1) : timeString;
  };

  // More than 365 days ago
  if (difference > oneYear) {
    return date.toLocaleString('default', { month: 'short', year: 'numeric' });
  }
  // More than 24 hours ago but less than 365 days
  else if (difference > oneDay) {
    return date.toLocaleString('default', { day: '2-digit', month: 'short' });
  }
  // Less than 24 hours ago
  else {
    return formatTime(date);
  }
}

const BulkSMSChat = () => {
  const isSubUser = Cookies.get("subuser") == "true"
  const [addName, setAddName] = useState('');
  const [addMessage, setAddMessage] = useState(null);
  const [addIsError, setAddIsError] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [filteredChatList, setFilteredChatList] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [replyFilterText, setReplyFilterText] = useState("");
  const [mobileView, setMobileView] = useState(false);
  const [chatData, setChatData] = useState([]);
  const [Uchat, setUchat] = useState({})
  const { chatState, contactsState, filterState, contactForAddingState, contactForRenamingState, favContactsState, activeTabState, selectedStatuses, bulkJob, groupModalMode, setGroupModalMode, phoneNumber, groups } = useContext(ChatContext);
  const [activeTab, setActiveTab] = activeTabState;
  const [contactForAdding, setContactForAdding] = contactForAddingState
  const [contactForRenaming, setContactForRenaming] = contactForRenamingState;
  const [createContact, setCreateContact] = useState(false);
  const [allChatData, setAllChatData] = useState([]);
  const [allChatDataContacts, setAllChatDataContacts] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const loadedChatsData = useRef(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [fields, setFields] = useState({});

  useEffect(() => {
    if (groupModalMode !== null)
      return;
    setFields({})
    setSelectedGroup(null)
    setAddMessage(null);
    setAddIsError(false);
  }, [groupModalMode])

  const navigate = useNavigate()
  const toggleAdd = () => {
    if (contactForAdding !== null && contactForRenaming === null) {
      setAddName('');
    }
    setContactForAdding(null);
    setContactForRenaming(null);
    setAddMessage(null);
    setCreateContact(false)
    setAddIsError(false);
  };

  const token = Cookies.get('token');

  const handleUpdateGroupSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!selectedGroup) {
        setAddMessage('You need to select a group');
        setAddIsError(true);
        return;
      }


      const url = `${global.SERVIP}/outreach/groups/${groupModalMode ? "add-contact" : "remove-contact"}`;
      const body = { group_id: selectedGroup, phone_number: phoneNumber, fields: groupModalMode ? fields : null };
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      };


      const response = await fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(body)
      });
      const result = await response.json();

      if (!result.ok) {
        setAddMessage(result.message || 'Unknown error happened. Please try again');
        setAddIsError(true);
      }
      else {
        Swal.fire("Success", groupModalMode ? "Successfully added contact to group" : "Successfully removed contact from group", "success");
        setGroupModalMode(null);
      }
    }
    catch (e) {
      setAddMessage('Unknown error happened. Please try again');
      setAddIsError(true);
    }
  }
  const handleAddSubmit = async (e) => {
    e.preventDefault();
    if (!addName) {
      setAddMessage('Name cannot be empty.');
      setAddIsError(true);
      return;
    }

    const url = `${global.SERVIP}/user/create-contact`;
    const body = { phone_number: contactForAdding || contactForRenaming, name: addName };
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(body)
      });
      const result = await response.json();

      if (result.ok) {
        const urlFavourite = `${global.SERVIP}/user/add-favorite-contact`;
        const bodyFavourite = { id: result.contact.id };
        const headersFavourite = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        };

        try {
          const responseFavourite = await fetch(urlFavourite, {
            method: 'POST',
            headers: headersFavourite,
            body: JSON.stringify(bodyFavourite)
          });
          const resultFavourite = await responseFavourite.json();

          if (resultFavourite.ok) {
            favContacts[Uchat.name] = true;
            setFavContacts({ ...favContacts })
          }
        } catch (error) {
          console.log(error);
        }
        setAddMessage('Contact has been added successfully.');
        setAddIsError(false);
        const newContacts = { ...contacts };
        newContacts[contactForAdding || contactForRenaming] = { id: result.contact.id, name: addName };
        setContacts(newContacts);
        setTimeout(() => {
          toggleAdd();
          window.location.reload(); // Reload the page after the toggle
        }, 1500);
      } else {
        setAddMessage(result.message || 'Server error');
        setAddIsError(true);
        setCreateContact(false)

      }
    } catch (error) {
      setAddMessage('Network error');
      setAddIsError(true);
    }
  };


  useEffect(() => {
    if (contactForRenaming)
      setAddName(contacts[contactForRenaming].name);
  }, [contactForRenaming])
  const [contacts, setContacts] = contactsState
  const [favContacts, setFavContacts] = favContactsState
  const [filter, setFilter] = filterState
  const [chat, setChat] = chatState;
  const [apiKeys, setApiKeys] = useState([{ api_key: "kita", master: true, id: 1 }, { api_key: "drugi", master: false, id: 3 }])
  const [selectedApiKey, setSelectedApiKey] = useState(isSubUser ? "abc" : null);
  const [scrollDepth, setScrollDepth] = useState(0);
  const [lastMsg, setLastMsg] = useState(-1);
  const [loadingChats, setLoadingChats] = useState(true);
  const [canLoadMore, setCanLoadMore] = useState(false);
  const [simCards, setSimCards] = useState({});
  const [selectedSimCard, setSelectedSimCard] = useState(null);
  const newMessageRef = useRef(false);
  useEffect(() => {
    if (!token) {
      navigate("/signup")
    }
    const fetchSimCards = async () => {
      try {
        const response = await fetch(`${global.SERVIP}/user/get-available-sims`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({})
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        if (data.ok) {

          let obj = {};
          for (const sc of data.sim_cards) {
            obj[sc.id.toString()] = sc.name;
          }
          setSimCards(obj);
        }
      } catch (err) {
        console.log(err);
      } finally {
      }
    };

    fetchSimCards();

    let fetchKeys = async () => {
      try {
        const response = await fetch(`${global.SERVIP}/user/get-api-keys`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const { ok, api_keys } = await response.json();
        console.log(api_keys);
        if (!ok) {
          console.log("greska")
          return;
        }
        setApiKeys(api_keys)
        for (const key of api_keys) {
          if (key.master) {
            setSelectedApiKey(key.id)
          }
        }
      } catch (error) {
        console.error('Error fetching messages by month:', error);
        throw error;
      }
    }
    fetchKeys();
  }, [])
  const UchatRef = useRef(Uchat)


  useEffect(() => {
    UchatRef.current = Uchat;
    if (Uchat?.convo?.length)
      for (let i = Uchat.convo.length - 1; i >= 0; i--) {
        if (Uchat.convo[i].me && Uchat.convo[i].sim_card_id) {
          setSelectedSimCard(Uchat.convo[i].sim_card_id.toString());
          break;
        }
      }
  }, [Uchat]);

  useEffect(() => {
    if (!selectedApiKey || !bulkJob?.id || !selectedStatuses)
      return;

    const fetchChats = async () => {
      setLoadingChats(true);
      setUchat({});
      setSelectedId(null);
      setReplyFilterText("");
      setFilterText("");
      const response = await fetch(`${global.SERVIP}/crm/get-recipients-bulk-job`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ api_key_id: selectedApiKey, selected_statuses: selectedStatuses, bulk_job_id: bulkJob.id })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var { ok, recipients, end } = await response.json();
      if (!ok) {
        console.log("greska")
        return;
      }
      setCanLoadMore(!end);
      loadedChatsData.current = true;

      const responseContacts = await fetch(`${global.SERVIP}/user/get-contacts`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: "{}"
      });

      if (!responseContacts.ok) {
        console.log(`HTTP error! Status: ${responseContacts.status}`)
        return;
      }

      var { ok, contacts } = await responseContacts.json();
      if (!ok)
        return;

      const contactMap = {}
      for (const contact of contacts)
        contactMap[contact.phone_number] = {
          name: contact.name,
          id: contact.id
        };
      console.log(recipients);
      setAllChatData(recipients);
      const allContacts = recipients.filter(e => contactMap[e.phone_number]).map((e, index) => ({
        id: index + 10000000,
        name: e.phone_number,
        nickname: "",
        theme: "blue",
        chatTheme: "purple",
        date: formatDate(new Date(e.date)),
        active: "60m",
        unread: true,
        archive: false,
        fav: false,
        delivered: true,
        image: getImage(e.phone_number),
        convo: [{
          "id": "chat_2",
          "me": true,
          "chat": [
            e.text
          ],
          "date": "4:12 PM"
        }]
      }));
      setAllChatDataContacts(allContacts);
      console.log(allContacts);
      setChatData(calculateChatData(recipients, allContacts, Object.keys(contactMap).length > 0, pageNumber, "", contactMap, "")
        .filter(activeTab?.value || (() => true)))
      setContacts(contactMap)

      if (!isSubUser)
        return;

      const responseFavContacts = await fetch(`${global.SERVIP}/user/get-favorite-contacts`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: "{}"
      });

      if (!responseFavContacts.ok) {
        console.log(`HTTP error! Status: ${responseFavContacts.status}`)
        return;
      }

      var { ok, favorite_contacts } = await responseFavContacts.json();
      if (!ok)
        return;

      const favContactMap = {}
      for (const favorite_contact of favorite_contacts)
        favContactMap[favorite_contact.phone_number] = true

      setFavContacts(favContactMap)
      setLoadingChats(false)

    }
    fetchChats()

    const socket = io(global.SERVIP);

    socket.on("connect", () => {
      socket.emit("connectUser", { jwt: token, api_key_id: selectedApiKey })
    })


    socket.on("receivedMessage", async ({ phone_number, message }) => {
      setAllChatData((prevChatData) => {
        // Samo ako je chat u trenutnoj grupi
        const newChatData = structuredClone(prevChatData);
        let found = false;
        const pm = JSON.parse(message);
        for (let i = 0; i < newChatData.length; i++) {
          if (newChatData[i].phone_number == phone_number) {
            newChatData[i].text = pm.chat[0];
            newChatData[i].date = pm.timestamp;
            const movedItem = newChatData.splice(i, 1)[0];
            newChatData.unshift(movedItem);
            found = true;
          }
        }
        /*if (!found)
          newChatData.unshift(
            {
              "phone_number": phone_number,
              "date": pm.timestamp,
              "text": pm.chat[0]
            })*/
        return newChatData;
      })
      setAllChatDataContacts((prevChatData) => {
        // TODO update other chat datas accordingly
        const newChatData = structuredClone(prevChatData);
        let found = false;
        for (let i = 0; i < newChatData.length; i++) {
          if (newChatData[i].name == phone_number) {
            newChatData[i].convo = appendMessage(newChatData[i].convo, message);
            newChatData[i].date = formatDate(new Date());
            const movedItem = newChatData.splice(i, 1)[0];
            newChatData.unshift(movedItem);
            found = true;
          }
        }
        if (!found)
          newChatData.unshift({
            "id": 1,
            "name": phone_number,
            "nickname": "",
            "theme": "blue",
            "chatTheme": "purple",
            "date": formatDate(new Date()),
            "active": "60m",
            "unread": true,
            "archive": false,
            "fav": false,
            "delivered": true,
            "image": getImage(phone_number),
            "convo": appendMessage([{
              "id": "1",
              "me": true,
              "chat": [
                ""
              ],
              "date": formatDate(new Date()),
            }], message)
          })
        //newChatData() // Dodati na kraj ili pocetak
        return newChatData;
      })

      if (UchatRef.current.name == phone_number) {
        setUchat((oldUchat) => {
          const newUchat = structuredClone(oldUchat);
          const parsedMessage = JSON.parse(message);
          if (!newUchat.convo.length || parseInt(newUchat.convo[newUchat.convo.length - 1].id) < parseInt(parsedMessage.id)) {
            newUchat.convo = appendMessage(newUchat.convo, message);
            newUchat.date = formatDate(new Date());
          }
          else {
            for (let j = newUchat.convo.length - 1; j >= 0; j--) {
              if ((parseInt(newUchat.convo[j].id) || newUchat.convo[j].id === '0') && parseInt(newUchat.convo[j].id) < parseInt(parsedMessage.id)) {
                break;
              }

              if ((parseInt(newUchat.convo[j].id) || newUchat.convo[j].id === '0') && parseInt(newUchat.convo[j].id) == parseInt(parsedMessage.id)) {
                newUchat.convo[j].status = parsedMessage.status;
                break;
              }
            }
          }
          return newUchat;
        })
        newMessageRef.current = true;
      }
    })

    return (() => {
      socket.disconnect();
    })
  }, [selectedApiKey, selectedStatuses])

  useEffect(() => {
    if (!selectedApiKey)
      return;
    // Potencijalno da se koristi
  }, [selectedApiKey, Uchat, activeTab, chatData])

  // Filtering users by search
  useEffect(() => {
    if (filterText !== "") {
      const filteredObject = chatData.filter(activeTab?.value || (() => true)).filter((item) => {
        return item.name.toLowerCase().includes(filterText.toLowerCase()) ||
          (contacts[item.name] ? (contacts[item.name].name.toLowerCase().includes(filterText.toLowerCase())) : false);
      });
      const filteredData = [...filteredObject];
      setFilteredChatList(filteredData);
    } else {
      const filteredData = [...chatData].filter(activeTab?.value || (() => true));
      setFilteredChatList(filteredData);
    }

  }, [filterText, chatData, setFilteredChatList, filter, contacts, favContacts, activeTab]);
  useEffect(() => {
    if (loadedChatsData.current)
      setLoadingChats(false)

  }, [chatData]);
  const onInputChange = (e) => {
    setFilterText(e.target.value);
    setPageNumber(1);
  };


  const onInputChangeReply = (e) => {
    setReplyFilterText(e.target.value);
    setPageNumber(1);
  };

  const chatItemClick = async (id) => {
    if (id != selectedId) {

      let data = chat;
      const index = data.findIndex((item) => item.id === id);
      const dataSet = chatData.find((item) => item.id === id);
      /*if (dataSet.unread === true) {
        data[index].unread = false;
        console.log(data)
        setChat([...data]);
      }*/
      setSelectedId(id);
      try {
        const response = await fetch(`${global.SERVIP}/crm/get-chat`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ api_key_id: selectedApiKey, phone_number: dataSet.name })
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const { ok, messages } = await response.json();
        if (!ok) {
          return;
        }

        const convo = parseMessages(messages)

        let lastTime = convo[convo.length - 1]?.timestamp || "";

        setLastMsg(convo[1]?.id || -1)
        setUchat({
          ...dataSet, convo, active: "Last message received: " + (lastTime ? (formatDate(new Date(lastTime))) : 'Never')
        });
      } catch (error) {
        console.error('Error fetching messages by month:', error);
        throw error;
      }
    }
    if (window.innerWidth < 860) {
      setMobileView(true);
    }
  };

  const trySendSMS = async (text, isSubUser) => {
    let try_sim_card_id = selectedSimCard;
    if (!try_sim_card_id)
      for (let i = Uchat.convo.length - 1; i >= 0; i--) {
        if (Uchat.convo[i].me && Uchat.convo[i].sim_card_id) {
          try_sim_card_id = Uchat.convo[i].sim_card_id; // Za twilio ce reci 0, tako da radi i za njega front, samo treba jos bek
          break;
        }
      }

    const response = await fetch(`${global.SERVIP}/crm/send-sms-as-api-key`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ api_key_id: selectedApiKey, phone_number: Uchat.name, text, try_sim_card_id })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const json = await response.json();

    setApiKeys(apiKeys.map(e => {
      if (e.id != selectedApiKey) {
        return e;
      }
      return { ...e, credits: e.credits - json.price }
    }))

    /*setChatData(chatData.map((e) => {
      if (Uchat.id != e.id)
        return e;
      return {
        ...e,
        delivered: !response.queued,
        unread: response.queued,
        date: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }).startsWith('0') ? new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }).slice(1) : new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
        convo: [{
          "id": "chat_2",
          "me": true,
          "chat": [
            text
          ],
          "date": "4:12 PM"
        }]
      }
    }))*/

    return json;
  }
  const createNewContact = () => {

  }
  return (
    <>
      <Modal zIndex={4000} isOpen={(contactForAdding !== null) || (contactForRenaming !== null) || createContact} toggle={toggleAdd}>
        <ModalHeader>{contactForRenaming ? "Rename " : (createContact ? "Create new" : "Add")} Contact</ModalHeader>
        <ModalBody>
          {addMessage && (
            <Alert color={addIsError ? 'danger' : 'success'}>
              {addMessage}
            </Alert>
          )}
          <Form>
            <FormGroup>
              <Label for="addName">{(contactForRenaming || contactForAdding) ? "New " : null} Name</Label>
              <Input
                type="text"
                name="name"
                id="addName"
                value={addName}
                onChange={(e) => setAddName(e.target.value)}
                placeholder="Enter name"
              />
              {
                createContact && (<div><Label for="addNumber">{(contactForRenaming || contactForAdding) ? "New " : null} Phone Number</Label>
                  <Input
                    type="text"
                    name="number"
                    id="addNumber"
                    value={contactForAdding}
                    onChange={(e) => setContactForAdding(e.target.value)}
                    placeholder="Enter phone number (add + with country prefix)"
                  /></div>)
              }
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleAddSubmit}>Save</Button>{' '}
          <Button color="secondary" onClick={toggleAdd}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <Modal zIndex={4000} isOpen={groupModalMode !== null} toggle={_ => setGroupModalMode(null)}>
        <ModalHeader>{groupModalMode ? "Add Contact to Group" : "Remove Contact from Group"}</ModalHeader>
        <ModalBody>
          {addMessage && (
            <Alert color={addIsError ? 'danger' : 'success'}>
              {addMessage}
            </Alert>
          )}
          <p style={{ marginBottom: 10 }}>{groupModalMode ? "When you add contact to a group, you can you can send it bulk messages using our no-code tool." : "Select a group to remove this contact from."} </p>
          <Form>
            <FormGroup>
              <Label for="addName">{groupModalMode ? "Add" : "Remove"} {phoneNumber} {groupModalMode ? "to" : "from"}:</Label>
              <Select
                showSearch
                filterOption={(input, option) =>
                  option?.label?.toLowerCase().trim().includes(input.toLowerCase().trim())
                }
                value={selectedGroup}
                defaultValue={null}
                placeholder="Choose a group"
                dropdownStyle={{ zIndex: 5000 }}
                style={{ flexGrow: 1, minWidth: '200px', margin: 'auto', marginLeft: 10 }}
                onChange={e => {
                  setSelectedGroup(e);
                  if (groupModalMode) {

                    const fArr = groups.find(e2 => e2.id == e).fields;
                    const f = {};
                    for (const fld of fArr) {
                      f[fld] = "";
                    }
                    setFields(f);
                  }
                }}
              >
                <Option value={null} label="Choose a group">
                  Select a group
                </Option>
                {(groupModalMode ? groups : groups.filter(e => e.contacts_length)).map(group => (
                  <Option
                    key={group.id.toString()}
                    value={group.id.toString()}
                    label={group.name || "Unnamed"}
                  >
                    {group.name || "Unnamed"} ({group.contacts_length})
                  </Option>
                ))}
              </Select>

              {groupModalMode && Object.keys(fields).map((fieldKey) => (
                <FormGroup key={fieldKey} style={{ marginTop: "10px" }}>
                  <Label for={fieldKey}>{fieldKey}</Label>
                  <Input
                    type="text"
                    id={fieldKey}
                    placeholder={`Enter ${fieldKey}`}
                    value={fields[fieldKey]}
                    onChange={(e) => setFields({ ...fields, [fieldKey]: e.target.value })}
                  />
                </FormGroup>
              ))}

            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleUpdateGroupSubmit}>Save</Button>{' '}
          <Button color="secondary" onClick={_ => setGroupModalMode(null)}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <ContentAlt >
        <div className="nk-chat" >
          <div className={`nk-chat-aside ${mobileView ? "has-aside" : ""}`}>
            <div className="nk-chat-aside-search" style={{ marginTop: 12, marginBottom: 18 }}>
              <div className="form-group">
                <div className="form-control-wrap">
                  <div className="form-icon form-icon-left">
                    <Icon name="search"></Icon>
                  </div>
                  <Input
                    type="text"
                    className="form-round"
                    id="default-03"
                    placeholder="Search by name or phone"
                    onChange={(e) => onInputChange(e)}
                  />
                </div>
              </div>
            </div>
            {loadingChats ?
              <div style={{
                width: '100%',
                height: '100vh', // Ensures the loader is centered vertically
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
              }}>
                <a style={{ fontFamily: "Nunito", fontSize: "1.2em", marginBottom: "10px", color: 'gray' }}>Loading chats</a>

                <SyncLoader
                  loading={true} size={15} speedMultiplier={0.8} color="gray" />
              </div>

              : <ChatAsideBody
                selectedSimCard={selectedSimCard}
                setSelectedSimCard={setSelectedSimCard}
                simCards={simCards}
                replyFilterText={replyFilterText}
                onInputChangeReply={onInputChangeReply}
                filterText={filterText}
                allChatData={allChatData}
                allChatDataContacts={allChatDataContacts}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                setChatData={setChatData}
                setSelectedApiKey={setSelectedApiKey}
                apiKeys={apiKeys}
                selectedApiKey={selectedApiKey}
                onInputChange={onInputChange}
                filteredChatList={filteredChatList}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                chatItemClick={chatItemClick}
                canLoadMore={canLoadMore}
              />}

          </div>
          {selectedId !== null ? (
            <ChatBody
              setPageNumber={setPageNumber}
              newMessageRef={newMessageRef}
              selectedApiKey={selectedApiKey}
              UchatRef={UchatRef}
              lastMsg={lastMsg}
              setLastMsg={setLastMsg}
              trySendSMS={trySendSMS}
              Uchat={Uchat}
              id={selectedId}
              setSelectedId={setSelectedId}
              setMobileView={setMobileView}
              mobileView={mobileView}
              setUchat={setUchat}
            />
          ) : (
            <div className="nk-chat-body">
              <div className="nk-chat-blank">
                <div className="nk-chat-blank-icon">
                  <Icon name="chat" className="icon-circle icon-circle-xxl bg-white"></Icon>
                </div>
                <div className="nk-chat-blank-btn">
                  <p>Select a chat</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </ContentAlt>
    </>
  );
};

export default BulkSMSChat;
