
import {BrowserRouter as  Routes, Route, Link} from 'react-router-dom'
import HeaderOne from "../../header/HeaderOne"
import * as React from 'react'
import FooterOne from '../../footer/FooterOne'
import Head from '../../components/Head'

function Marketing() {
  return (
<> 
<Head title="TextLink Marketing Overview" />

    <HeaderOne />
 

    <section className="about-us-area pt-145 pb-90 pt-md-60 pb-md-30 pt-xs-60 pb-xs-30">
        
			<div className="container" style={{marginTop:'5%'}}>
				<div className="row align-items-center">
                    
					<div className="col-lg-6">
                        
						<div className="about-text-wrapper2 mb-30 pr-75">
							<h6 className="semi-title semi-title2">Marketing Tool</h6>
							<h2 className="sect-title text-heding2 mb-35">Target A Couple Of Users<br/> Or a Bunch Of Them.</h2>
                            <p className="paragraph-title">
							For you non-coders, our Bulk SMS tools is perfect. You can send personalised messages to thousands of people. Craft each message based on every users attribures and make a perfect sales text. 
                            <br/>
<br/>
                            You can also send messages in bulk from our API, or use our web app to communicate personaly with each client.</p>
							<ul className="text-list text-list2 list-none border-bottom" style={{marginTop:20}}>
								
                                        <br/>
                                        <br/>

							</ul>
							
						</div>
					</div>
					<div className="col-lg-6">
						<div className="about-img-wrapper mb-30">
							<img className="img-one img-fluid  d-xxl-inline-block rounded-3" src="assets/img/about/bulknocodeImg1.png" alt="About Img" />
							
						</div>
					</div>
				</div>
			</div>
		</section>    
<section>
<div className="col-12" style={{display: 'flex',justifyContent: 'center',alignItems:'center',flexDirection:'column'}}>
    <ul className="btn-list2 list-none d-sm-flex align-items-center justify-content-center">
        <li><Link to="/faq" className="theme_btn mr-20">FAQ</Link></li>
        <li><Link to="/contact" className="theme_btn border-btn">Get In Touch <i className="fal fa-long-arrow-right"></i></Link></li>
    </ul>
</div>
    </section> 
	<br/>

    <FooterOne/>      
</>
  )
}
export default Marketing
