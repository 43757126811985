import React from 'react';
import { useNavigate } from 'react-router-dom'; // Assuming you're using React Router for navigation
import Head from '../../components/Head';
import './GhlExplenation.css';

export default function GHLIntegrationPage() {
  const navigate = useNavigate(); // Hook for navigation

  const handleBackClick = () => {
    navigate('/'); // Navigate to the home page
  };

  const handleClickCreateAccount = () => {
    window.open('/signup', '_blank'); // Opens in a new tab
  };

 

  const handleConnect = () => {
    const scope =
      "conversations/message.readonly conversations/message.write locations.readonly contacts.readonly contacts.write conversations.readonly conversations.write";
    const baseUrl =
      "https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=https://textlinksms.com/setup-1&client_id=677d610573a75c4f9811461e-m7m5izoo&scope=";
    const fullUrl = baseUrl + encodeURIComponent(scope);

    // Create a temporary anchor element to reliably open the URL in a new tab
    const link = document.createElement('a');
    link.href = fullUrl;
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  return (
    <>
    <Head title="TextLink HighLevel Integration" />

      {/* Sticky Back Button */}
      <button className="back-button" onClick={handleBackClick}>
        &larr; Back Home
      </button>

      <div className="ghl-integration-container">
        <h1 className="page-title">Connect TextLink to GoHighLevel</h1>
        <p className="explanation-text">
          When you connect your TextLink accounts to your GHL locations, you can start using your own numbers and devices for sending and receiving messages via TextLink SMS conversation provider. This means you can send and receive messages from chats or automations and get responses from your TextLink Bulk SMS campaigns, all from your GoHighLevel Dashboard. Keep in mind that you will need to have a TextLink account for each Location that you want to connect and that you can only install TextLink to GHL locations from your Agency account.
        </p>

        <div className="reminder-box">
          <p className="reminder-text">
            *You need your own numbers and devices set up in order to use TextLink.
          </p>
        </div>

        <div className="step-by-step-guide">
          <h2 className="guide-title">Step-by-Step Guide</h2>
          <div className="steps-list">
            <div className="step-item">
              <div className="step-number">1</div>
              <div className="step-content">
                <p>Create a TextLink account and a sub-account for each location and set up your devices.</p>
                <button className="connect-button" onClick={handleClickCreateAccount} >Create a TextLink account</button>
              </div>
            </div>
            <div className="step-item">
              <div className="step-number">2</div>
              <div className="step-content">
                <p>Click the button below and install TextLink to one or more Locations.</p>
                <div className="ghl-login-reminder">
                  <p>Make sure you're logged into your GHL Agency account on this browser.</p>
                </div>
                <button className="connect-button" onClick={handleConnect}>Connect to GHL</button>
              </div>
            </div>
            <div className="step-item">
              <div className="step-number">3</div>
              <div className="step-content">
                <p>Go to your admin dashboard and copy the API key of the sub-account and connect them to their respective locations.</p>
              </div>
            </div>
            {/* New Step */}
            <div className="step-item">
              <div className="step-number">4</div>
              <div className="step-content">
                <p>Go to each of your GHL Location accounts that you have installed TextLink for and set TextLink SMS as the main conversation provider.</p>
                <p className="instruction-detail">
                  <strong>How to do this: </strong> Go to <strong>Settings → Phone Numbers → Advanced Settings</strong> and select TextLink SMS as the conversation provider.
                </p>
              </div>
            </div>
            <div className="step-item">
              <div className="step-number">5</div>
              <div className="step-content">
                <p>That's it! You can see which sub-accounts are connected in your admin dashboard and modify which numbers and SIM cards they have access to.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="reminder-box" style={{ marginTop: '20px' }}>
          <p className="reminder-text">
            *Make sure to double-check what each sub-account has access to from your Admin dashboard.
          </p>
        </div>
      </div>
    </>
  );
}