import React, { useState, createContext, useEffect } from "react";
import { chatData } from "./ChatData";

export const ChatContext = createContext();
console.log("Kreirao chat context za bulksms");

export const CONTACT_STATUSES = {
  'replied': "Replies",
  'sent': "No replies",
  'replied+sent': "Messages successfuly sent",
  'failed': "Failed messages",
  'sending': "Stuck messages",

}


export const ChatContextProvider = (props) => {
  const [chat, setChat] = useState(chatData);
  const [favData, setFavData] = useState(chatData);
  const [contacts, setContacts] = useState([]);
  const [favContacts, setFavContacts] = useState([]);
  const [filter, setFilter] = useState(() => true);
  const [activeTab, setActiveTab] = useState(null);
  const [chatStatuses, setChatStatuses] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState(null)

  const [contactForAdding, setContactForAdding] = useState(null) // null or phone_number
  const [contactForRenaming, setContactForRenaming] = useState(null) // null or id

  const { token, campaignId, campaign, setCampaign, bulkJob, setBulkJob, setNextMessage } = props;

  const [selectedIndex, setSelectedIndex] = useState(null);
  const [groupModalMode, setGroupModalMode] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    if (groupModalMode === null || groups.length)
      return;
    const fetchGroups = async () => {
      try {
        const url = `${global.SERVIP}/outreach/groups/get-all`;
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Set the authorization header
          }
        });
        if (!response.ok)
          return;
        const data = await response.json();
        if (!data.ok)
          return;
        setGroups(data.groups);
        console.log(data.groups);
      }
      catch (e) {
        console.log(e);
      }
    }
    fetchGroups()
  }, [groupModalMode]);

  useEffect(() => {
    if (chatStatuses && selectedIndex)
      setSelectedStatuses(chatStatuses.find(e => (e.text == selectedIndex)).statuses);
  }, [selectedIndex])

  console.log(campaign);
  console.log(bulkJob);
  console.log(chatStatuses);
  useEffect(() => {
    if (!token || !campaignId || campaign || bulkJob)
      return;

    (async () => {
      try {
        const url = `${global.SERVIP}/outreach/campaigns/get-one`;
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Set the authorization header
          },
          body: JSON.stringify({ campaign_id: campaignId })
        });
        if (!response.ok)
          return;
        const data = await response.json();
        if (!data.ok)
          return;

        setBulkJob(data.bulk_job);
        setCampaign(data.campaign);

        const available_statuses = [{
          text: `Leads that replied (${data.bulk_job.responses})`,
          statuses: ['replied']
        },
        {
          text: `Leads that did not reply (${Number(data.bulk_job.sent_messages) - Number(data.bulk_job.responses)})`,
          statuses: ['sent']
        },
        {
          text: `All reached leads (${Number(data.bulk_job.sent_messages)})`,
          statuses: ['replied', 'sent']
        }];

        if (data.bulk_job.failed_messages) {
          available_statuses.push({
            text: `Failed messages (${data.bulk_job.failed_messages})`,
            statuses: ['failed']
          });
        }
        if (data.bulk_job.sending_messages)
          available_statuses.push({
            text: `Messages sending right now (${data.bulk_job.sending_messages})`,
            statuses: ['sending']
          })
        setSelectedIndex(available_statuses[0].text);

        available_statuses.push({
          text: `All messages (${Number(data.bulk_job.sending_messages) + Number(data.bulk_job.failed_messages) + Number(data.bulk_job.sent_messages)})`,
          statuses: []
        })

        setChatStatuses(available_statuses);
        setSelectedStatuses(available_statuses[0].statuses);

        console.log("SACY2")
        const url2 = `${global.SERVIP}/outreach/campaigns/get-next-message`;
        console.log("SACYo2")
        const response2 = await fetch(url2, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Set the authorization header
          },
          body: JSON.stringify({ campaign_id: campaignId })
        });
        console.log("SACYO2")
        if (!response2.ok)
          return;
        console.log("SACY22")
        const data2 = await response2.json();
        console.log("EDER2", data2)
        if (!data2.ok)
          return;
        setNextMessage(data2.next_message);
      }
      catch (e) {
        console.log(e);
      }
    })();

  }, [campaignId, token])

  // Function for deleting a conversation
  const deleteConvo = (id) => {
    let data = chat;
    data = data.filter((item) => item.id !== id);
    setChat([...data]);
  };

  // Universal function for any props
  const propAction = (id, prop) => {
    let data = chat;
    const index = data.findIndex((item) => item.id === id);
    data[index][prop] = true;
    setChat([...data]);
  };

  // Function to add favourite to favourite list
  const favAction = (id) => {
    let data = favData;
    const index = data.findIndex((item) => item.id === id);
    data[index].fav = true;
    setFavData([...data]);
    setChat([...data]);
  };

  // Function to change nickame
  const changeNickname = (id, name) => {
    const data = chat;
    const index = data.findIndex((item) => item.id === id);
    data[index].nickname = name;
    setChat([...data]);
  };

  // Function to change theme
  const changeTheme = (id, name) => {
    const data = chat;
    const index = data.findIndex((item) => item.id === id);
    data[index].chatTheme = name;
    setChat([...data]);
  };

  // Removes user from group
  const deleteUser = (id, userId) => {
    let data = chat;
    const grouped = data.findIndex((item) => item.id === id);
    const group = data.find((item) => item.id === id);
    const user = group.user.filter((user) => user.id !== userId);
    group.user = user;
    data[grouped] = group;
    setChat([...data]);
  };

  // Makes a user admin
  const makeAdmin = (id, userId) => {
    let data = chat;
    const grouped = data.findIndex((item) => item.id === id);
    const group = data.find((item) => item.id === id);
    group.user.forEach((element) => {
      element.role = "User";
    });
    const userIndex = group.user.findIndex((item) => item.id === userId);
    group.user[userIndex].role = "Admin";
    data[grouped] = group;
    setChat([...data]);
  };


  return (
    <ChatContext.Provider
      value={{
        groupModalMode,
        setGroupModalMode,
        phoneNumber,
        setPhoneNumber,
        groups,
        campaignId: campaignId,
        selectedIndexState: [selectedIndex, setSelectedIndex],
        bulkJob: bulkJob,
        activeTabState: [activeTab, setActiveTab],
        contactForAddingState: [contactForAdding, setContactForAdding],
        contactForRenamingState: [contactForRenaming, setContactForRenaming],
        contactsState: [contacts, setContacts],
        favContactsState: [favContacts, setFavContacts],
        filterState: [filter, setFilter],
        chatState: [chat, setChat],
        chatData: chat,
        fav: [favData],
        deleteConvo: deleteConvo,
        propAction: propAction,
        favAction: favAction,
        changeNickname: changeNickname,
        changeTheme: changeTheme,
        deleteUser: deleteUser,
        makeAdmin: makeAdmin,
        chatStatuses: chatStatuses,
        selectedStatuses: selectedStatuses,
        setSelectedStatuses: setSelectedStatuses
      }}
    >
      {props.children}
    </ChatContext.Provider>
  );
};
