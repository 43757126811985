import React, { useState } from 'react';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap';

const BulkSmsFaq = ({ variation, className }) => {
  const [open, setOpen] = useState('');

  const toggle = (id) => {
    setOpen(open === id ? '' : id);
  };

  return (
    <div className={`accordion${variation ? ` accordion-s${variation}` : ''}${className ? ` ${className}` : ''}`} style={{ marginTop: 10 }}>
      <Accordion open={open} toggle={toggle}>
        <AccordionItem>
          <AccordionHeader targetId="1">
            <span style={{ paddingRight: 12 }}>How many contacts can I send to in one campaign?</span>
          </AccordionHeader>
          <AccordionBody accordionId="1">
            We have successfully tested our tool on a group with 30k members. It worked perfectly, we just had to wait ~2 minutes for the price estimate to load while starting the campaign.
          </AccordionBody>
        </AccordionItem>
        <AccordionItem>
          <AccordionHeader targetId="2">
            <span style={{ paddingRight: 12 }}>Can I choose SIM cards that send my messages for the campaign?</span>
          </AccordionHeader>
          <AccordionBody accordionId="2">
            Yes, you can do that in the "Setup" step.
          </AccordionBody>
        </AccordionItem>
        <AccordionItem>
          <AccordionHeader targetId="3">
            <span style={{ paddingRight: 12 }}>How do I upload contacts?</span>
          </AccordionHeader>
          <AccordionBody accordionId="3">
            You can upload a CSV/Excel/Google Sheets spreadsheet with a column named <strong>phone_number</strong>, and other columns you want, but <strong>phone_number</strong> is mandatory. You can also add contacts/columns manually.
          </AccordionBody>
        </AccordionItem>
        <AccordionItem>
          <AccordionHeader targetId="4">
            <span style={{ paddingRight: 12 }}>Will I see the messages in GoHighLevel chat?</span>
          </AccordionHeader>
          <AccordionBody accordionId="4">
            Once your account is connected, you will see all new campaign messages and responses in your GHL Conversation, as well as in our Chat App.
          </AccordionBody>
        </AccordionItem>
        <AccordionItem>
          <AccordionHeader targetId="5">
            <span style={{ paddingRight: 12 }}>How do I customize messages?</span>
          </AccordionHeader>
          <AccordionBody accordionId="5">
            We have easy to use tool which let's you make each message feel unique, by building them in our intuitive tool. You will se how once you create a user group.
          </AccordionBody>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default BulkSmsFaq;
