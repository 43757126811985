import React, { useState, useEffect } from 'react';
import { Input, Card, Col, Row, CardTitle, Button } from 'reactstrap';
import Head from '../../layout/head/Head';
import Content from '../../layout/content/Content';
import SubscriptionsPricing from './SubscriptionsPricing';
import PricingExplenationCard from './components/PricingExplenationCard';
import { BlockBetween, BlockContent, BlockDes, BlockHead, BlockTitle, PreviewCard } from '../../components/Component';
import { Select } from 'antd';
import CurrencySelect from './components/CurrencySelect';
import { Switch } from 'antd';
import { Icon } from '../../components/Component';
import Cookies from "js-cookie"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import CryptoPaymentModal from './components/CryptoPaymentModal';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router';
import './styles/Payment.css'
import { useRef } from 'react';
const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
      });
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};
const FeatureRequest = () => {
  const token = Cookies.get('token');
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [limit, setLimit] = useState("");
  const [description, setDescription] = useState("");
  const [query, setQuery] = useState("");
  const navigate = useNavigate()
  const [countries, setCountries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [amount, setAmount] = useState('');
  const [lookupKey, setLookupKey] = useState('sumo1');
  const [isCrypto, setIsCrypto] = useState(false); // State to track payment type
  const [selectedCurrency, setSelectedCurrency] = useState("")
  const { width } = useWindowSize();
  const [cryptoPaymentModalShown, setCryptoPaymentModalShown] = useState(false);
  const [amountInChosenCrypto, setAmountInChosenCrypto] = useState(0)
  const [walletAddress, setWalletAddress] = useState("")
  const [isBuyModalVisible, setIsBuyModalVisible] = useState(false);
  const [isPricingModalVisible, setIsPricingModalVisible] = useState(false);
  const myTargetRef = useRef(null);
  const [userBalance, setUserBalance] = useState({})

  const isMobile = width < 768; // Set your breakpoint for mobile layout
  useEffect(() => {
    const fetchSubscriptionData = async () => {
      try {
        const response = await fetch(`${global.SERVIP}/payment/get-subscription-info`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          }
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const { ok, lookup_key } = await response.json();
        console.log({ lookup_key });
        if (!ok) {
          return;
        }

        setLookupKey(lookup_key);
      } catch (error) {
        console.error('Error fetching subscriptions:', error);
      }
    };

    fetchSubscriptionData();
  }, []);

  return (
    <>
      <Head title="Dashboard Pricing Page" />

      <Content>
        <Row>
          <Col lg={12}>
            <PreviewCard bordered={false}>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '70vh' }}>
                <div>
                  <BlockHead size="sm">
                    <BlockBetween className="g-3">
                      <BlockContent>
                        <BlockTitle>Suggest a Feature</BlockTitle>
                      </BlockContent>
                    </BlockBetween>
                  </BlockHead>
                  <p style={{ maxWidth: 720, width: '100%' }}>Our users' opinions matter to us. If you have anything that you think would help make TextLink better, feel free to share it with us. </p>
                  <br></br>
                  <h5>Your name</h5>
                  <div style={{ display: 'flex', width: '100%', justifyContent: 'start', alignItems: 'center' }}>
                    <input className="form-control form-control-md" placeholder="John Smith" style={{ maxWidth: '720px', marginBottom: '20px' }} value={name} onChange={(e) => setName(e.target.value)} />
                  </div>

                  <h5>Your company (optional)</h5>
                  <div style={{ display: 'flex', width: '100%', justifyContent: 'start', alignItems: 'center' }}>
                    <input className="form-control form-control-md" placeholder="GoHighLevel" style={{ maxWidth: '720px', marginBottom: '20px' }} value={company} onChange={(e) => setCompany(e.target.value)} />
                  </div>
                  {(lookupKey == 'free') && <>
                    <h5>SMS needed / month</h5>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'start', alignItems: 'center' }}>
                      <input className="form-control form-control-md" placeholder="How many SMS do you need per month?" style={{ maxWidth: '720px', marginBottom: '20px' }} value={limit} onChange={(e) => setLimit(e.target.value)} />
                    </div></>}


                  <h5>Your idea</h5>
                  <div style={{ display: 'flex', width: '100%', justifyContent: 'start', alignItems: 'center' }}>
                    <textarea className="form-control form-control-md" placeholder="What can we build to improve TextLink?" style={{ maxWidth: '720px', marginBottom: '20px' }} value={description} onChange={(e) => setDescription(e.target.value)} />
                  </div>

                  <Button color="primary" style={{ maxWidth: 720, width: '100%' }} onClick={async () => {
                    try {
                      if (!description)
                        Swal.fire("Submission incomplete", "Please describe your idea.", "info");
                      setDescription("");
                      setName("");
                      setCompany("");
                      setLimit("");
                      const response = await fetch(`${global.SERVIP}/user/suggest-feature`, {
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/json',
                          'Authorization': 'Bearer ' + token
                        },
                        body: JSON.stringify({ name, company, limit, description })
                      });

                      if (!response.ok) {
                        throw new Error('Network response was not ok');
                      }

                      const { ok, message } = await response.json();
                      Swal.fire(ok ? "Thank you!" : "Error", ok ? "Your idea has been successfuly submitted" : (message || "Could not upload data. If the problem persists, contact us directly."), ok ? "success" : "error")
                    }
                    catch (e) {
                      console.log(e);
                      Swal.fire("Error", "Could not upload data. If the problem persists, contact us directly.", "error");
                    }

                  }}>Submit</Button>
                </div>
              </div>

            </PreviewCard>
          </Col>
        </Row>
      </Content>

    </>

  );
};

export default FeatureRequest;
