import React, { useContext, useEffect, useState } from "react";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  Button,
  RSelect
} from "../../../../components/Component";

import UserProfileAside from "./UserProfileAside";
import { countryOptions, userData } from "../../../pre-built/user-manage/UserData";

import { Card } from "antd";
const SetUpYourDevice = () => {
  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);

  const [modalTab, setModalTab] = useState("1");
  const [userInfo, setUserInfo] = useState(userData[0]);
  const [formData, setFormData] = useState({
    name: "Abu Bin Ishtiak",
    displayName: "Ishtiak",
    phone: "818474958",
    dob: "1980-08-10",
    address: "2337 Kildeer Drive",
    address2: "",
    state: "Kentucky",
    country: "Canada",
  });
  const [modal, setModal] = useState(false);

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const submitForm = () => {
    let submitData = {
      ...formData,
    };
    setUserInfo(submitData);
    setModal(false);
  };

  // function to change the design view under 990 px
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document.getElementsByClassName("nk-header")[0].addEventListener("click", function () {
      updateSm(false);
    });
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, []);

  return (
    <React.Fragment>
      <Head title="Self setup explenation"></Head>
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${sm ? "content-active" : ""
                }`}
            >
              <UserProfileAside updateSm={updateSm} sm={sm} />
            </div>
            <div className="card-inner card-inner-lg">
              {sm && mobileView && <div className="toggle-overlay" onClick={() => updateSm(!sm)}></div>}
              <BlockHead size="lg">
                <BlockBetween>
                  <BlockHeadContent>
                    <BlockTitle tag="h4">Why set up your own service and how?</BlockTitle>
                    <BlockDes>
                      <p>Here we will breafly explain how and why to set up your own SMS service , without any special equipment, just and android phone with a SIM card.</p>
                    </BlockDes>
                  </BlockHeadContent>
                  <BlockHeadContent className="align-self-start d-lg-none">
                    <Button
                      className={`toggle btn btn-icon btn-trigger mt-n1 ${sm ? "active" : ""}`}
                      onClick={() => updateSm(!sm)}
                    >
                      <Icon name="menu-alt-r"></Icon>
                    </Button>
                  </BlockHeadContent>
                </BlockBetween>
              </BlockHead>

              <Block>
                <div className="bq-note">

                  <div className="bq-note-item" >
                    <h4 style={{ margin: 5 }}>Price</h4>

                    <div className="bq-note-text">
                      <p>It's much cheaper than using services like EZText or Twillio (if you have an android device with a sim in the wanted country/region).<br /> You only pay for a monthly subscription ranging from 0-195 USD (based on how many messages you want to send),<br /> which lets you send SMS for as low as <a style={{ fontWeight: 'bold' }}> 0.0005$</a> per message.</p>
                    </div>

                  </div>
                  <div className="bq-note-item" >
                    <h4 style={{ margin: 5 }}>Ease of setup</h4>

                    <div className="bq-note-text">
                      <p>You can set up your own service <a style={{ fontWeight: 'bold' }}> in minutes</a>. We have easy <a href="/dashboard/your-devices/step-by-step" style={{ color: "blue" }}>step by step guide</a> / <a style={{ color: 'blue' }} href="/dashboard/your-devices/self-service-video">video tutorial</a>.</p>
                    </div>

                  </div>
                  <div className="bq-note-item" >
                    <h4 style={{ margin: 5 }}>Uses</h4>

                    <div className="bq-note-text">
                      <p>After you set everything up, you will be able to use all of our services (API and No-code tools) with your device for all countries.</p>
                    </div>

                  </div>
                  <div className="bq-note-item" >
                    <div className="bq-note-text">
                      {mobileView ? <p>Click the button below to set everything up, or contact us at info@textlinksms.com to find out more.</p>
                        : <p>Contact us at info@textlinksms.com if you have any other questions.</p>}

                    </div>

                  </div>
                  {mobileView && <Button color="primary" style={{ marginTop: 15 }} onClick={() => updateSm(true)}>
                    <Icon name="reports"></Icon>
                    <span>Get started</span>
                  </Button>}
                </div>
              </Block>

              {/* New Section for Recommended SIM Plans */}
              <Block>
                <div className="bq-note">
                  <div className="bq-note-item">
                    <h4 style={{ margin: 5 }}>Recommended SIM Plans</h4>
                    <div className="bq-note-text">
                      <p>Check out our recommended SIM plans for various countries to get the best rates and coverage for your SMS needs.</p>
                      <a
                        href="https://docs.google.com/spreadsheets/d/1ts7dP3Cn2cAGCDGnIZ97nYsZSRCl5YKCcHvWB_HxQE8/edit?usp=sharing"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "blue", fontWeight: "bold" }}
                      >
                        View Recommended SIM Plans
                      </a>
                    </div>
                  </div>
                </div>
              </Block>

            </div>
          </div>
        </Card>
      </Content>
    </React.Fragment>
  );
};

export default SetUpYourDevice;