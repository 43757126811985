import React, { useEffect, useState, useContext, useRef } from "react";
import classNames from "classnames";
import ChatSideBar from "./ChatSideBar";
import SimpleBar from "simplebar-react";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { UserAvatar, Icon, Button } from "../../../components/Component";
import { currentTime, findUpper, truncate } from "../../../utils/Utils";
import { ChatContext } from "./ChatContext";
import Swal from "sweetalert2";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CreateIcon from '@mui/icons-material/Create';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import StarIcon from '@mui/icons-material/Star';
import Cookies from "js-cookie";

import { MeChat, YouChat, MetaChat } from "./ChatPartials";
import { prependMessage } from "./util/ChatParser";

const ChatBody = ({ id, Uchat, mobileView, setMobileView, setPageNumber, setSelectedId, trySendSMS, setLastMsg, lastMsg, UchatRef, setUchat, selectedApiKey, newMessageRef }) => {
  const { deleteConvo, propAction, chatState, contactsState, favContactsState, contactForAddingState, contactForRenamingState } = useContext(ChatContext);
  const [contactForAdding, setContactForAdding] = contactForAddingState
  const [contactForRenaming, setContactForRenaming] = contactForRenamingState
  const [chat, setChat] = chatState;
  const [contacts, setContacts] = contactsState
  const [favContacts, setFavContacts] = favContactsState
  //const [Uchat, setUchat] = useState({});
  const [sidebar, setsidebar] = useState(false);
  const [inputText, setInputText] = useState("");
  const [chatOptions, setChatOptions] = useState(false)
  const [scrollDepth, setScrollDepth] = useState(null);
  const [height, setHeight] = useState(0)
  const lastMsgRef = useRef(null);
  const token = Cookies.get('token');
  const isSubUser = Cookies.get("subuser") == "true"

  const messagesEndRef = useRef(null);
  const heightRef = useRef(height);

  useEffect(() => {
    heightRef.current = height;
  }, [height])

  const scrollToBottom = (forceZero, forceStb) => {
    if (messagesEndRef.current) {
      if (forceStb) {
        const scrollHeight = messagesEndRef.current.scrollHeight;
        const height = messagesEndRef.current.clientHeight;
        const maxScrollTop = scrollHeight - height;
        messagesEndRef.current.scrollTop = maxScrollTop > 0 ? (maxScrollTop) : 0;
        return;
      }
      if (forceZero)
        heightRef.current = 0

      const scrollHeight = messagesEndRef.current.scrollHeight;
      const height = messagesEndRef.current.clientHeight;
      const maxScrollTop = scrollHeight - height;
      messagesEndRef.current.scrollTop = maxScrollTop > 0 ? (maxScrollTop - heightRef.current) : 0;
      setHeight(maxScrollTop);
    }
  };

  useEffect(() => {
    if (lastMsg == "0")
      return;
    if (scrollDepth == 0 && lastMsgRef.current !== lastMsg) {
      // Uradim get-chat pa updatujem lastMsg
      lastMsgRef.current = lastMsg;
      let fetchNewMessages = async () => {
        const response = await fetch(`${global.SERVIP}/crm/get-chat`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ api_key_id: selectedApiKey, phone_number: UchatRef.current.name, first_message_id: (lastMsgRef.current == -1) ? Uchat.convo[1].id : lastMsgRef.current })
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const { ok, messages } = await response.json();
        if (!ok) {
          return;
        }

        let convo = UchatRef.current.convo;
        for (let i = messages.length - 1; i >= 0; i--) {
          convo = prependMessage(convo, messages[i]);
        }

        setLastMsg(convo[1].id)
        setUchat({
          ...UchatRef.current, convo
        });
      }
      fetchNewMessages();
    }
    //console.log("Dubina scrolla " + scrollDepth);

  }, [scrollDepth])

  useEffect(() => {
    if (UchatRef.current.name != Uchat.name) {
      lastMsgRef.current = -1;
    }
    if (newMessageRef.current) {
      newMessageRef.current = false;
      scrollToBottom(false, true);
    }
    else {
      scrollToBottom(UchatRef.current.name != Uchat.name);
    }
  }, [Uchat]);

  const resizeFunc = () => {
    if (window.innerWidth > 1550) {
      setsidebar(true);
    } else {
      setsidebar(false);
    }
  };

  useEffect(() => {
    chat.forEach((item) => {
      if (item.id === id) {
        //setUchat(item);
      }
    });
  }, [id, chat]);

  useEffect(() => {
    window.addEventListener("resize", resizeFunc);
    resizeFunc();
    return () => {
      window.removeEventListener("resize", resizeFunc);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      //console.log("Handle scroll")
      if (messagesEndRef.current) {
        setScrollDepth(messagesEndRef.current.scrollTop);
      }
    };

    const currentRef = messagesEndRef.current;

    if (currentRef) {
      currentRef.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [messagesEndRef.current, Uchat]);

  const onChatOptions = () => {
    setChatOptions(!chatOptions);
  };

  const onInputChange = (e) => {
    setInputText(e.target.value);
  };

  const toggleMenu = () => {
    setsidebar(!sidebar);
  };

  const onTextSubmit = async (e) => {
    e.preventDefault();
    if (inputText.length <= 0)
      return;
    const result = await trySendSMS(inputText, isSubUser);
    if (!result.ok) {
      Swal.fire({ title: "Error", text: result.message || "Message could not be sent", icon: "error" });
      return;
    }
    /*if (result.queued) {
      Swal.fire({ title: "Info", text: "Message is queued for sending. It should arive within a few minutes. ", icon: "info" });
    }*/
    let allChat = chat;
    let index = allChat.find((item) => item.id === id);
    let defaultChat = Uchat;
    allChat[index] = defaultChat;
    setChat([...allChat]);
    //setUchat({ ...defaultChat });
    setPageNumber(1);
    setInputText("");
  };

  const onRemoveMessage = (idx, id) => {
    let allChat = chat;
    let cindex = allChat.find((item) => item.id === id);
    let defaultChat = Uchat;
    let newConvo = defaultChat.convo;
    let index = newConvo.findIndex((item) => item.id === id);
    newConvo[index].chat[idx] = "deleted";
    allChat[cindex] = defaultChat;
    setChat([...allChat]);
  };

  const chatBodyClass = classNames({
    "nk-chat-body": true,
    "show-chat": mobileView,
    "profile-shown": sidebar && window.innerWidth > 1550,
  });

  return (
    <React.Fragment>
      {Object.keys(Uchat).length > 0 && (
        <div className={chatBodyClass}>
          <div className="nk-chat-head">
            <ul className="nk-chat-head-info" style={{ width: "100%", paddingLeft: 0 }}>
              <li className="nk-chat-body-close" onClick={() => setMobileView(false)}>
                <a
                  href="#hide-chat"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                  className="btn btn-icon btn-trigger nk-chat-hide ms-n1"
                >
                  <Icon name="arrow-left"></Icon>
                </a>
              </li>
              <li className="nk-chat-head-user" style={{ width: "100%" }}>
                <div className="user-card" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                  <div style={{ display: "flex" }}>
                    {Uchat.group ? (
                      <div className="chat-media user-avatar user-avatar-multiple">
                        {Uchat.user.map((user, index) => {
                          return (
                            <UserAvatar
                              theme={user.theme}
                              text={contacts[user.name] ? (contacts[user.name].name + " (" + user.name + ")") : user.name}
                              image={user.image}
                              className="chat-media"
                              key={index}
                            ></UserAvatar>
                          );
                        })}
                        <span className={"status dot dot-lg dot-success"}></span>
                      </div>
                    ) : (
                      <UserAvatar image={Uchat.image} theme={Uchat.theme} text={contacts[Uchat.name] ? (contacts[Uchat.name].name + " (" + Uchat.name + ")") : Uchat.name}>
                        {Uchat.active === true ? (
                          <span className="status dot dot-lg dot-success"></span>
                        ) : (
                          <span className="status dot dot-lg dot-gray"></span>
                        )}
                      </UserAvatar>
                    )}
                    <div className="user-info">
                      <div className="lead-text" style={{ whiteSpace: "break-spaces" }}>{contacts[Uchat.name] ? (contacts[Uchat.name].name + " (" + Uchat.name + ")") : Uchat.name}</div>
                      <div className="sub-text">
                        <span className="d-none d-sm-inline me-1">{`${Uchat.active}`}</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    {!contacts[Uchat.name] && <Button color="primary" onClick={() => {
                      setContactForAdding(Uchat.name)
                    }} style={{ backgroundColor: 'transparent', border: 'none', padding: 0, marginLeft: "20px" }}>
                      <PersonAddIcon style={{ color: "#7F8DFF" }} />
                    </Button>}

                    {contacts[Uchat.name] && <Button color="primary" onClick={() => {
                      setContactForRenaming(Uchat.name)
                    }} style={{ backgroundColor: 'transparent', border: 'none', padding: 0, marginLeft: "20px" }}>
                      <CreateIcon style={{ color: "#7F8DFF" }} />
                    </Button>}

                    {(isSubUser && contacts[Uchat.name] && favContacts[Uchat.name]) && <Button color="primary" onClick={async () => {
                      const id = contacts[Uchat.name].id;

                      if (!id)
                        return;
                      const url = `${global.SERVIP}/user/remove-favorite-contact`;
                      const body = { id };
                      const headers = {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                      };

                      try {
                        const response = await fetch(url, {
                          method: 'POST',
                          headers,
                          body: JSON.stringify(body)
                        });
                        const result = await response.json();

                        if (result.ok) {
                          delete favContacts[Uchat.name];
                          setFavContacts({ ...favContacts })
                        }
                      } catch (error) {
                        console.log(error);
                      }
                    }} style={{ backgroundColor: 'transparent', border: 'none', padding: 0, marginLeft: "20px" }}>
                      <StarIcon style={{ color: "#7F8DFF" }} />
                    </Button>}

                    {(isSubUser && contacts[Uchat.name] && !favContacts[Uchat.name]) && <Button color="primary" onClick={async () => {

                      const id = contacts[Uchat.name].id;

                      if (!id)
                        return;
                      const url = `${global.SERVIP}/user/add-favorite-contact`;
                      const body = { id };
                      const headers = {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                      };

                      try {
                        const response = await fetch(url, {
                          method: 'POST',
                          headers,
                          body: JSON.stringify(body)
                        });
                        const result = await response.json();

                        if (result.ok) {
                          favContacts[Uchat.name] = true;
                          setFavContacts({ ...favContacts })
                        }
                      } catch (error) {
                        console.log(error);
                      }
                    }} style={{ backgroundColor: 'transparent', border: 'none', padding: 0, marginLeft: "20px" }}>
                      <StarOutlineIcon style={{ color: "#7F8DFF" }} />
                    </Button>}
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <SimpleBar className="nk-chat-panel" scrollableNodeProps={{ ref: messagesEndRef }}>
            {Uchat.convo.map((item, idx) => {
              if (item.me) {
                return <MeChat key={idx} item={item} chat={Uchat} onRemoveMessage={onRemoveMessage}></MeChat>;
              } else if (item.meta) {
                return <MetaChat key={idx} item={item}></MetaChat>;
              } else {
                return <YouChat key={idx} item={item} chat={Uchat}></YouChat>;
              }
            })}
          </SimpleBar>
          <div className="nk-chat-editor">
            <div className="nk-chat-editor-form">
              <div className="form-control-wrap">
                <textarea
                  className="form-control form-control-simple"
                  rows="1"
                  id="default-textarea"
                  onChange={(e) => {
                    onInputChange(e);
                    const textarea = e.target;
                    textarea.style.height = "auto"; // Reset height to calculate upward growth
                    textarea.style.height = `${textarea.scrollHeight}px`; // Set height to fit content
                  }}
                  value={inputText}
                  placeholder="Type your message..."
                  onKeyDown={(e) => {
                    if (e.code === "Enter" && !e.shiftKey) {
                      onTextSubmit(e);
                    }
                  }}
                  style={{
                    overflow: "hidden",
                    resize: "none", // Prevent native resize handle
                  }}
                ></textarea>

              </div>
            </div>
            <ul className="nk-chat-editor-tools g-2">
              <li>
                <Button color="primary" style={{ borderRadius: 200 }} onClick={(e) => onTextSubmit(e)} className="btn-round btn-icon-break">
                  <Icon name="send-alt"></Icon>
                </Button>
              </li>
            </ul>
          </div>

          {window.innerWidth < 1550 && sidebar && (
            <div onClick={() => toggleMenu()} className="nk-chat-profile-overlay"></div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default ChatBody;
